import React from "react";

import "./estilos.css";

function DadosCadastrais({
  dadosPaciente1,
  dadosPaciente2,
  dataNascimento,
  abrirDados,
  setAbrirDados,
}) {
  const data1 = dataNascimento === null ? "" : dataNascimento.substring(0, 10);
  function dataBR(date) {
    return date.split("-").reverse().join("/");
  }

  if (abrirDados === true) {
    return (
      <div className="SobreposicaoDadosCadastrais">
        <section className="ModalDadosCadastrais">
          <header>
            <h2>Dados Cadastrais</h2>
          </header>
          <main>
            <div className="Conteudo">
              <section className="Informacoes">
                <div className="Nomes">
                  <span>Prontuário:</span>
                  <span>CPF:</span>
                  <span>RG:</span>
                  <span>Nome:</span>
                  <span>Nascimento:</span>
                  <span>Gênero:</span>
                  <span>Etnia:</span>
                  <span>Estado Civil:</span>
                  <span>Nome da Mãe:</span>
                  <span>Nome do Pai:</span>
                  <span>Nome do Responsável:</span>
                </div>

                <div className="Dados">
                  <span>{dadosPaciente1.medical_record}</span>
                  <span>{dadosPaciente2.cpf}</span>
                  <span>{dadosPaciente1.rg}</span>
                  <span>
                    {dadosPaciente2.name_first} {dadosPaciente2.name_last}
                  </span>
                  <span>
                    {dataBR(data1)} ({dadosPaciente2.age} anos)
                  </span>
                  <span>
                    {dadosPaciente2.gender === 1
                      ? "Masculino"
                      : dadosPaciente2.gender === 2
                      ? "Feminino"
                      : "Outro"}
                  </span>
                  <span>
                    {dadosPaciente2.race === 1
                      ? "Preto"
                      : dadosPaciente2.gender === 2
                      ? "Pardo"
                      : "Branco"}
                  </span>
                  <span>
                    {dadosPaciente1.marital_status === 1
                      ? "Solteiro"
                      : dadosPaciente1.marital_status === 2
                      ? "Casado"
                      : "Viúvo"}
                  </span>
                  <span>{dadosPaciente1.mothers_name}</span>
                  <span>{dadosPaciente1.fathers_name}</span>
                  <span>
                    {dadosPaciente1.responsible_name === ""
                      ? "--"
                      : dadosPaciente1.responsible_name}
                  </span>
                </div>
              </section>

              <section className="Informacoes">
                <div className="Nomes">
                  <span>Escolaridade:</span>
                  <span>Profissão:</span>
                  <span>Ocupação:</span>
                  <span>Nacionalidade:</span>
                  <span>Cidade Natal:</span>
                  <span>Celular:</span>
                  <span>Endereço:</span>
                </div>

                <div className="Dados">
                  <span>{dadosPaciente2.user.escolaridade}</span>
                  <span>{dadosPaciente1.profession}</span>
                  <span>{dadosPaciente1.occupation}</span>
                  <span>
                    {dadosPaciente1.nationality === 1
                      ? "Brasileiro"
                      : dadosPaciente1.nationality === 2
                      ? "Estrangeiro"
                      : "Naturalizado"}
                  </span>
                  <span>
                    {dadosPaciente1.birth_town === ""
                      ? "Não definida"
                      : dadosPaciente1.birth_town}
                  </span>
                  <span>{dadosPaciente2.phone}</span>
                  <span>
                    {dadosPaciente2.address.street} <br />
                    {dadosPaciente2.address.city} {dadosPaciente2.address.state}{" "}
                    <br />
                    {dadosPaciente2.address.cep}
                  </span>
                </div>
              </section>
            </div>
          </main>
          <footer>
            <button onClick={() => setAbrirDados(false)}>Fechar</button>
          </footer>
        </section>
      </div>
    );
  }
  return null;
}

export default DadosCadastrais;
