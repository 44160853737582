import React, { useState } from "react";
import "./estilos.css";

import logo from "../../Arquivos/higia.svg";
import logoClinica from "../../Arquivos/logoClinica.svg";
import logoMedico from "../../Arquivos/logoMedico.svg";
import { useHistory } from "react-router-dom";
import { FiArrowRight, FiSettings, FiUser } from "react-icons/fi";
import api from "../../Servicos/api";
import swal from "sweetalert";
import { MdAssignmentInd } from "react-icons/md";

export default function SelecionarPerfil({ dados }) {
  const history = useHistory();

  const [privilege, setPrivilege] = useState(null);
  const [user_id, setUser_id] = useState(null);
  const [key, setKey] = useState(null);

  async function Entrar(e) {
    e.preventDefault();

    try {
      const response = await api.post("choiceRelation", {
        user_id,
        privilege,
        key,
      });
      switch (privilege) {
        case "MHME":
          localStorage.setItem("username", response.data.user.username);
          localStorage.setItem(
            "name_first",
            response.data.user.person.name_first
          );
          localStorage.setItem(
            "name_last",
            response.data.user.person.name_last
          );
          localStorage.setItem("phone", response.data.user.person.phone);
          localStorage.setItem("email", response.data.user.email);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "especializacao",
            response.data.user.person.doctor.area_specialization
          );
          localStorage.setItem("cpf", response.data.user.person.cpf);
          localStorage.setItem("crm", response.data.user.person.doctor.crm);
          localStorage.setItem("uf", response.data.user.person.doctor.uf);
          localStorage.setItem("rqe", response.data.user.person.doctor.rqe);
          localStorage.setItem("photo", response.data.user.photo);
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("idMedico", response.data.user.person.doctor.id);
          localStorage.setItem("questions", Array(response.data.user.person.doctor.questions));
          localStorage.setItem(
            "state",
            response.data.user.person.address.state
          );
          localStorage.setItem("city", response.data.user.person.address.city);
          localStorage.setItem("cep", response.data.user.person.address.cep);
          localStorage.setItem(
            "number",
            response.data.user.person.address.number
          );
          localStorage.setItem(
            "street",
            response.data.user.person.address.street
          );
          localStorage.setItem(
            "housign_type",
            response.data.user.person.address.housign_type
          );

          history.push("/medico");
          break;

        case "MHMA":
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem("cnes", response.data.user.manager.cnes);
          localStorage.setItem("cnpj", response.data.user.manager.cnpj);
          localStorage.setItem(
            "fantasy_name",
            response.data.user.manager.fantasy_name
          );
          localStorage.setItem(
            "social_reason",
            response.data.user.manager.social_reason
          );
          localStorage.setItem("phone", response.data.user.manager.phone);
          localStorage.setItem("id_clinica", response.data.user.manager.id);

          localStorage.setItem(
            "state",
            response.data.user.manager.address.state
          );
          localStorage.setItem("city", response.data.user.manager.address.city);
          localStorage.setItem("cep", response.data.user.manager.address.cep);
          localStorage.setItem(
            "number",
            response.data.user.manager.address.number
          );
          localStorage.setItem(
            "street",
            response.data.user.manager.address.street
          );
          localStorage.setItem(
            "housign_type",
            response.data.user.manager.address.housign_type
          );
          localStorage.setItem("token", response.data.token);
          history.push("/clinica");
          break;
        case "MHPA":
          localStorage.setItem("username", response.data.user.username);
          localStorage.setItem(
            "name_first",
            response.data.user.person.name_first
          );
          localStorage.setItem(
            "name_last",
            response.data.user.person.name_last
          );
          localStorage.setItem("race", response.data.user.person.race);
          localStorage.setItem(
            "data_birth",
            response.data.user.person.data_birth
          );
          localStorage.setItem("phone", response.data.user.person.phone);
          localStorage.setItem("email", response.data.user.email);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("escolaridade", response.data.user.escolaridade);
          localStorage.setItem("cpf", response.data.user.person.cpf);
          localStorage.setItem("photo", response.data.user.photo);
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem(
            "IdPaciente",
            response.data.user.person.patient.id
          );
          localStorage.setItem("idade", response.data.user.person.age);

          localStorage.setItem(
            "state",
            response.data.user.person.address.state
          );
          localStorage.setItem("city", response.data.user.person.address.city);
          localStorage.setItem("cep", response.data.user.person.address.cep);
          localStorage.setItem(
            "number",
            response.data.user.person.address.number
          );
          localStorage.setItem(
            "street",
            response.data.user.person.address.street
          );
          localStorage.setItem(
            "housign_type",
            response.data.user.person.address.housign_type
          );
          localStorage.setItem(
            "nationality",
            response.data.user.person.patient.nationality
          );
          localStorage.setItem(
            "fathers_name",
            response.data.user.person.patient.fathers_name
          );
          localStorage.setItem(
            "mothers_name",
            response.data.user.person.patient.mothers_name
          );
          localStorage.setItem(
            "responsible_name",
            response.data.user.person.patient.responsible_name
          );
          localStorage.setItem(
            "religion",
            response.data.user.person.patient.religion
          );
          localStorage.setItem(
            "marital_status",
            response.data.user.person.patient.marital_status
          );
          localStorage.setItem(
            "profession",
            response.data.user.person.patient.profession
          );
          localStorage.setItem(
            "occupation",
            response.data.user.person.patient.occupation
          );
          localStorage.setItem("rg", response.data.user.person.patient.rg);
          localStorage.setItem(
            "birth_town",
            response.data.user.person.patient.birth_town
          );
          history.push("/paciente");
          break;
        case "MHDG":
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem("privilege", response.data.user.privilege);
          localStorage.setItem("username", response.data.user.username);
          localStorage.setItem("age", response.data.user.person.age);
          localStorage.setItem(
            "name_first",
            response.data.user.person.name_first
          );
          localStorage.setItem(
            "name_last",
            response.data.user.person.name_last
          );
          localStorage.setItem("cpf", response.data.user.person.cpf);
          localStorage.setItem("phone", response.data.user.person.phone);
          localStorage.setItem("race", response.data.user.person.race);
          localStorage.setItem("gender", response.data.user.person.gender);
          localStorage.setItem(
            "data_birth",
            response.data.user.person.data_birth
          );
          localStorage.setItem(
            "occupation",
            response.data.user.person.employee[0].occupation
          );
          localStorage.setItem(
            "workload",
            response.data.user.person.employee[0].workload
          );

          localStorage.setItem(
            "state",
            response.data.user.person.address.state
          );
          localStorage.setItem("city", response.data.user.person.address.city);
          localStorage.setItem("cep", response.data.user.person.address.cep);
          localStorage.setItem(
            "number",
            response.data.user.person.address.number
          );
          localStorage.setItem(
            "street",
            response.data.user.person.address.street
          );
          localStorage.setItem(
            "housign_type",
            response.data.user.person.address.housign_type
          );
          localStorage.setItem("token", response.data.token);
          history.push("/diretor");
          break;
        case "MHAT":
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem("privilege", response.data.privilege);
          localStorage.setItem("username", response.data.user.username);
          localStorage.setItem("age", response.data.user.person.age);
          localStorage.setItem(
            "name_first",
            response.data.user.person.name_first
          );
          localStorage.setItem(
            "name_last",
            response.data.user.person.name_last
          );
          localStorage.setItem("cpf", response.data.user.person.cpf);
          localStorage.setItem("phone", response.data.user.person.phone);
          localStorage.setItem("race", response.data.user.person.race);
          localStorage.setItem("gender", response.data.user.person.gender);
          localStorage.setItem(
            "data_birth",
            response.data.user.person.data_birth
          );
          localStorage.setItem(
            "occupation",
            response.data.user.person.employee.occupation
          );
          localStorage.setItem(
            "workload",
            response.data.user.person.employee.workload
          );
          localStorage.setItem(
            "state",
            response.data.user.person.address.state
          );
          localStorage.setItem("city", response.data.user.person.address.city);
          localStorage.setItem("cep", response.data.user.person.address.cep);
          localStorage.setItem(
            "number",
            response.data.user.person.address.number
          );
          localStorage.setItem(
            "street",
            response.data.user.person.address.street
          );
          localStorage.setItem(
            "housign_type",
            response.data.user.person.address.housign_type
          );
          localStorage.setItem("token", response.data.token);
          history.push("/atendente");
          break;
        default:
          swal("Privilégio inválido.", "error");
      }
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  function Icone(privilegio) {
    switch (privilegio) {
      case "MHMA":
        return <FiSettings size="45" className="logoPaciente" />;

      case "MHDG":
        return <img src={logoClinica} alt="Logo Clínica" className="icone" />;

      case "MHAT":
        return <MdAssignmentInd size="45" className="logoPaciente" />;

      case "MHME":
        return <img src={logoMedico} alt="" className="icone" />;

      case "MHPA":
        return <FiUser size="45" className="logoPaciente" />;
      default:
        return "";
    }
  }

  function Nome(privilegio) {
    switch (privilegio) {
      case "MHMA":
        return "Administrador";

      case "MHDG":
        return "Diretor Geral";

      case "MHAT":
        return "Atendente";

      case "MHME":
        return "Médico";

      case "MHPA":
        return "Paciente";
      default:
        return "";
    }
  }

  return (
    <div id="selecionar-perfil-fundo">
      <div className="selecionar-perfil-modal">
        <img src={logo} alt="Logo MobileHealth" />
        <p>
          <strong>Caro Usuário,</strong> <br />
          O sistema detectou que você possui mais de um vínculo com a Clínica.
          <br />
          Por favor, selecione o vínculo com o qual deseja trabalhar nesta
          sessão.
        </p>
        <form onSubmit={Entrar}>
          {dados === undefined || dados === null
            ? ""
            : dados.users.map((vinculo) => (
                <button
                  id="perfil-container"
                  key={vinculo.user_id}
                  type="submit"
                  onClick={() => {
                    setPrivilege(vinculo.privilege);
                    setUser_id(vinculo.user_id);
                    setKey(dados.key);
                  }}
                >
                  <span>
                    {Icone(vinculo.privilege)}
                    {Nome(vinculo.privilege)}
                  </span>

                  <span className="perfil-link">
                    <FiArrowRight />
                  </span>
                </button>
              ))}
        </form>
      </div>
    </div>
  );
}
