import React from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import api from "../../../Servicos/api";
import { FiAlertTriangle } from "react-icons/fi";

import "./estilos.css";

function ModalExcluirPlano({
    abrirModal,
    setAbrirModal,
    idPlano,
}) {
    const token = localStorage.getItem("token");
    const history = useHistory();

    async function excluirPlano() {
        try {
            await api.delete(`/healthInsurance/${idPlano}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            swal("Removido com Sucesso", "", "success");
            setAbrirModal(false);
            history.push("/clinica/inicio");
            history.push("/clinica/planos");

        } catch (err) {
            swal(err.response.data.error, "Tente novamente", "error");
            setAbrirModal(false);
        }
    }

    if (abrirModal === true) {
        return (
            <div className="SobreposicaoExcluirPlano">
                <section className="ModalExcluirPlano">
                    <header>
                        <h2>Deseja realmente remover o plano?</h2>
                    </header>
                    <FiAlertTriangle
                        style={{ marginTop: "30px" }}
                        size="100"
                        color="#ec1b21"
                    />
                    <main>
                        <button
                            className="botao-grande"
                            style={{ backgroundColor: "#ec1b21" }}
                            onClick={() => excluirPlano()}
                        >
                            Sim
            </button>
                        <button
                            className="botao-grande"
                            style={{ backgroundColor: "var(--cor-cinza)" }}
                            onClick={() => setAbrirModal(false)}
                        >
                            Não
            </button>
                    </main>
                </section>
            </div>
        );
    }
    return null;
}

export default ModalExcluirPlano;
