/*
Data de criação: 02/09/2020;
Autor: Jeciane Amorim;
Descrição: Listagem de Pacientes;
*/
import React from "react";
import { Link } from "react-router-dom";
import { FiBell } from "react-icons/fi";

import Menu from "../../Componentes/GlobalMenu/indice";

import DiarioDeBordo from "../../Componentes/Geral/ComponentsDiarioDeBordo/Messenger/index";

import foto from "../../Arquivos/avatarPadrao.svg";
import "./estilos.css";

export default function PacientesClinica() {
  //Dados Clínica
  const nomeFantasia = localStorage.getItem("fantasy_name");

  return (
    <div className="ContainerClinicaPaciente">
      <Menu />
      <div className="Container-conteudo">
        <header>
          <h1>Bem-vindo, {nomeFantasia}.</h1>
          <div className="Perfil">
            <Link to="">
              <FiBell className="Notificacao" />
            </Link>
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de Perfil" />
            </div>
          </div>
        </header>
        <nav>
          <div className="navegacao">
            <h2>{"Paciente  "}</h2>
            <h5>
              {">>"} {"Diario de Bordo *"}
            </h5>
          </div>
        </nav>
        <DiarioDeBordo />
      </div>
    </div>
  );
}
