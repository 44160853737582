import React from "react";
import moment from "moment";

import "./estilos.css";

import {
    FiEdit,
    FiTrash2,
} from 'react-icons/fi';

export default function Tabela({
  notes,
  setSelectedAnnotation,
  setShowTable,
  setShowPagination,
  setOpenFormAnnotation,
  setAbrirModalExcluir,
  setIdAnotacao
}) {

  const limiteDeCaracteresTexto = 50;
  const limiteDeCaracteresAssunto = 30;

  return (
    <>
      <div className="TabelaAnotacao">
        <table>
          <thead>
            <tr>
              <th>Assunto</th>
              <th style={{width: "40%"}}>Anotação</th>
              <th>Data da Criação</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            {!notes.length ? (
              <tr>
                <td colSpan={4}>Nenhuma anotação encontrada.</td>
              </tr>
            ) : (
              notes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((anotacao) => (
                <tr key={anotacao.id}>
                  <td>
                    {anotacao.subject?.length > limiteDeCaracteresAssunto 
                        ? anotacao.subject.slice(0, limiteDeCaracteresAssunto) + '...'
                        : anotacao.subject
                    }
                  </td>
                  <td style={{width: "40%"}}>
                    {anotacao.text?.length > limiteDeCaracteresTexto 
                        ? anotacao.text.slice(0, limiteDeCaracteresTexto) + '...'
                        : anotacao.text
                    }
                  </td>
                  <td>{moment(anotacao.createdAt).format('DD/MM/YYYY')}</td>
                  <td>
                    <FiEdit
                      onClick={() => {
                        setSelectedAnnotation(anotacao.id);
                        setShowTable(false);
                        setShowPagination(false);
                        setOpenFormAnnotation(true);
                      }}
                      cursor="pointer"
                      color="#1070a1" size="20"/>&nbsp;&nbsp;
                    <FiTrash2
                      onClick={() => {
                        setAbrirModalExcluir(true);
                        setIdAnotacao(anotacao.id);
                      }}
                      cursor="pointer" 
                      color="#1070a1" size="20"
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
