import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import {
  FiHome,
  FiBell,
  FiClipboard,
  FiLogOut,
  FiCalendar,
  FiUser,
  FiUsers,
  FiSettings,
  FiBookOpen,
  FiDollarSign,
  FiClock,
  FiInfo,
} from "react-icons/fi";

import { BiMessageRounded } from "react-icons/bi";

import Logo from "../../Arquivos/higiaWhite.svg";

import IconeMedico from "../../Arquivos/iconeMedico.svg";
import { MdAssignmentInd } from "react-icons/md";
import styles from "./Menu.module.css";

import Sobre from '../../Componentes/Geral/ModalSobre/indice'

export default function Menu() {
  const [ativo, setAtivo] = useState(false);
  const history = useHistory();
  const profile = localStorage.getItem("profile");
  let menuProps = [];
  const [abrirSobre, setAbrirSobre] = useState(false);

  switch (profile) {
    case "MHME":
      menuProps = [
        {
          route: "/medico/inicio",
          icon: <FiHome className={styles.Icone} />,
          title: "Inicio",
        },
        {
          route: "/medico/perfil",
          icon: <FiUser className={styles.Icone} />,
          title: "Perfil",
        },
        {
          route: "/medico/agenda",
          icon: <FiCalendar className={styles.Icone} />,
          title: "Agenda",
        },
        {
          route: "/medico/listaconsultas",
          icon: <FiClipboard className={styles.Icone} />,
          title: "Consultas",
        },
        {
          route: "/medico/listaacompanhamento",
          icon: <BiMessageRounded className={styles.Icone} />,
          title: "Assistência",
        },
        {
          route: "/medico/notificacoes",
          icon: <FiBell className={styles.Icone} />,
          title: "Notificações",
        },
      ];
      break;
    case "MHMA":
      menuProps = [
        {
          route: "/clinica/inicio",
          icon: <FiHome className={styles.Icone} />,
          title: "Inicio",
        },
        {
          route: "/clinica/agenda",
          icon: <FiCalendar className={styles.Icone} />,
          title: "Agenda",
        },
        {
          route: "/clinica/medicos",
          icon: (
            <img
              src={IconeMedico}
              alt="Ícone do médico"
              className={styles.Icone}
            />
          ),
          title: "Médicos",
        },
        {
          route: "/clinica/pacientes",
          icon: <FiUser className={styles.Icone} />,
          title: "Pacientes",
        },
        {
          route: "/clinica/atendentes",
          icon: <MdAssignmentInd className={styles.Icone} />,
          title: "Atendentes",
        },
        {
          route: "/clinica/empregados",
          icon: <FiUsers className={styles.Icone} />,
          title: "Funcionários",
        },
        {
          route: "/clinica/usuarios",
          icon: <FiUsers className={styles.Icone} />,
          title: "Usuários",
        },
        {
          route: "/clinica/financeiro",
          icon: <FiDollarSign className={styles.Icone} />,
          title: "Financeiro",
        },
        {
          route: "/clinica/planos",
          icon: <FiBookOpen className={styles.Icone} />,
          title: "Planos",
        },
        {
          route: "/clinica/configuracoes",
          icon: <FiSettings className={styles.Icone} />,
          title: "Configurações",
        },
      ];
      break;
    case "MHPA":
      menuProps = [
        {
          route: "/paciente/inicio",
          icon: <FiHome className={styles.Icone} />,
          title: "Início",
        },
        {
          route: "/paciente/perfil",
          icon: <FiUser className={styles.Icone} />,
          title: "Perfil",
        },
        {
          route: "/paciente/notificacoes",
          icon: <FiBell className={styles.Icone} />,
          title: "Notificação",
        },
        {
          route: "/paciente/consultas",
          icon: <FiClipboard className={styles.Icone} />,
          title: "Consultas",
        },
        {
          route: "/paciente/listaacompanhamento",
          icon: <BiMessageRounded className={styles.Icone} />,
          title: "Assistência",
        },
      ];
      break;
    case "MHDG":
      menuProps = [
        {
          route: "/diretor/inicio",
          icon: <FiHome className={styles.Icone} />,
          title: "Início",
        },
        {
          route: "/diretor/perfil",
          icon: <FiUser className={styles.Icone} />,
          title: "Perfil",
        },
        {
          route: "/diretor/empregados",
          icon: <FiUsers className={styles.Icone} />,
          title: "Empregados",
        },
        {
          route: "/diretor/atendentes",
          icon: <MdAssignmentInd className={styles.Icone} />,
          title: "Atendentes",
        },
        {
          route: "/diretor/pacientes",
          icon: <FiUsers className={styles.Icone} />,
          title: "Pacientes",
        },
        {
          route: "/diretor/medicos",
          icon: (
            <img
              src={IconeMedico}
              alt="Ícone do médico"
              className={styles.Icone}
            />
          ),
          title: "Médicos",
        },
        {
          route: "/diretor/usuarios",
          icon: <FiUsers className={styles.Icone} />,
          title: "Usuários",
        },
      ];
      break;
    case "MHAT":
      menuProps = [
        {
          route: "/atendente/inicio",
          icon: <FiHome className={styles.Icone} />,
          title: "Início",
        },
        {
          route: "/atendente/perfil",
          icon: <FiUser className={styles.Icone} />,
          title: "Perfil",
        },
        {
          route: "/atendente/agenda",
          icon: <FiCalendar className={styles.Icone} />,
          title: "Agenda",
        },
        {
          route: "/atendente/consultas",
          icon: <FiClipboard className={styles.Icone} />,
          title: "Consulta",
        },
        {
          route: "/atendente/saladeespera",
          icon: <FiClock className={styles.Icone} />,
          title: "Sala de Espera",
        },
        {
          route: "/atendente/medicos",
          icon: (
            <img
              src={IconeMedico}
              alt="Ícone do médico"
              className={styles.Icone}
            />
          ),
          title: "Médicos",
        },
        {
          route: "/atendente/pacientes",
          icon: <FiUsers className={styles.Icone} />,
          title: "Pacientes",
        },
      ];
      break;
    default:
      break;
  }

  function Sair() {
    localStorage.clear();
    history.push("/");
  }
  return (
    <>
      <div className={styles.Menu} onClick={() => setAtivo(true)}>
        <div className={styles.BarraMenu}></div>
        <div className={styles.BarraMenu}></div>
        <div className={styles.BarraMenu}></div>
      </div>
      <div
        className={ativo ? styles.menuBlur : ""}
        onClick={() => setAtivo(false)}
      ></div>
      <div className={styles.Fundo}>
        <img src={Logo} alt="" />
      </div>
      <div
        className={`${styles.ContainerMenu} ${ativo ? styles.Ativo : ""}`}
        id="Menu"
      >
        <div className={styles.ContainerMenuConteudo}>
          <img src={Logo} alt="Logo Higia.com" />
          <div className={styles.ContainerMenuLinks}>
            {menuProps.map((menuItem) => {
              return (
                <NavLink className="botaoMenu" activeClassName={styles.ativo} key={menuItem.title} to={`${menuItem.route}`}>
                  <div className={styles.ContainerConteudoLink}>
                    {menuItem.icon}
                    <span>{menuItem.title}</span>
                  </div>
                </NavLink>
              );
            })}
            <NavLink className="botaoMenu" to="#" onClick={() => setAbrirSobre(true)}>
              <div className={styles.ContainerConteudoLink}>
                <FiInfo className={styles.Icone}/>
                <span>Sobre</span>
              </div>
            </NavLink>
            <NavLink className="botaoMenu" to="/" onClick={Sair}>
              <div className={styles.ContainerConteudoLink}>
                <FiLogOut className={styles.Icone} />
                <span>Sair</span>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <Sobre abrirSobre={abrirSobre} setAbrirSobre={setAbrirSobre} />
    </>
  );
}
