/*
Data de criação: 17/08/2020;
Autor: Paulo Lopes;
Descrição: Painel controle do paciente, responsável por disponibilizar ao médico as
informações principais relacionadas a consultas;
*/

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FiBell, FiArrowRight } from "react-icons/fi";

import "./estilos.css";
import Menu from "../../../Componentes/GlobalMenu/indice";
import CartaoConsulta from "../../../Componentes/Paciente/ConsultasMarcadas/indice";
import ConsultasRecentes from "../../../Componentes/Paciente/ConsultasRecentes/indice";
import ConsultasDetalhes from "../../../Componentes/Paciente/ConsultasDetalhes/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";
import api from "../../../Servicos/api";
import ChatBot from "../../../Componentes/Paciente/ChatBot/index";

export default function PainelInicialPaciente() {
  const nome = localStorage.getItem("name_first");
  const token = localStorage.getItem("token");
  const idPaciente = localStorage.getItem("IdPaciente");
  const [listaConsultas, setListaConsultas] = useState([]);

  const foto = localStorage.getItem("photo");

  useEffect(() => {
    api
      .get(`/patient/consultation/${idPaciente}/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListaConsultas(response.data);
      });
  }, [idPaciente, token]);

  const filtraAgendadas = listaConsultas.filter(
    ({ situation }) => situation === 3
  );

  const detalheConsulta = {
    nome: "Maria Julia Costa",
    especializacao: "Cardiologista",
    recomendacoes: "Restrições de dieta (Frituras e Álcool)",
    exames: "Hemograma, audiometria",
  };

  return (
    <div className="ContainerPainelPaciente">
      <Menu />

      <div className="ContainerConteudo">
        <header>
          <div id="div-ajuda">
            <h1>Bem-vindo, {nome}.</h1>
            <Ajuda
              mensagem={
                "Nesta tela é possível acompanhar suas consultas marcadas e as mais recentes."
              }
              width={"30rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>
          <div className="Perfil">
            <Link to="/paciente/notificacoes">
              <FiBell className="Notificacao" />
            </Link>

            <div className="FotoPerfil">
              <img src={foto} alt="Foto de Perfil" />
            </div>
          </div>
        </header>
        <main>
          <ChatBot
            nome = {nome}
            foto = {foto}
          />
          <div className="ConsultasMarcadas">
            <div className="Titulo">
              <h2>Consultas Marcadas</h2>
              <Link to="/paciente/consultas" className="VerMaisConsultas">
                <FiArrowRight className="Icone" />
              </Link>
            </div>
            <div className="ConsultasCartao">
              {filtraAgendadas.length === 0
                ? "Não há consultas marcadas."
                : filtraAgendadas.map((consulta) => (
                  <CartaoConsulta
                    key={consulta.id}
                    nome={consulta.doctor.person.name_first}
                    sobrenome={consulta.doctor.person.name_last}
                    especializacao={consulta.doctor.area_specialization}
                    data={consulta.start_time}
                    doctorId={consulta.doctor.id}
                  />
                ))}
            </div>
          </div>
          <div className="Consultas">
            <div className="ConsultasRecentes">
              <h2>Consultas Recentes</h2>
              {filtraAgendadas.length === 0 ? (
                "Não há consultas recentes."
              ) : (
                <ConsultasRecentes consultas={filtraAgendadas} />
              )}
            </div>
            <div className="ConsultaDetalhe">
              {filtraAgendadas.length === 0 ? (
                " "
              ) : (
                <>
                  <h2>Detalhes da Consulta</h2>
                  <ConsultasDetalhes detalhes={detalheConsulta} />
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
