import React, { useState } from "react";
import { MdHelp } from "react-icons/md";

import "./Ajuda.css";

function Ajuda(props) {
  const [aux, setAux] = useState(false);
  return (
    <div
      id="help"
      onMouseLeave={() => setAux(false)}
      onMouseOver={() => setAux(true)}
    >
      <MdHelp size={props.size} style={{ color: "var(--cor-azul)" }} />
      <p
        style={{
          display: aux ? "block" : "none",
          width: props.width,
          backgroundColor: props.backColor,
          color: props.fontColor,
          margin: props.margin,
          borderRadius: props.border, 
        }}
      >
        {props.mensagem}
        {/**/}
      </p>
    </div>
  );
}

export default Ajuda;
