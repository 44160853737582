/*
Data de criação: 11/08/2020;
Autor: Jeciane Amorim;
Descrição: Página de visualização do perfil, responsável por disponibilizar
ao usuário os dados do perfil e o gerenciamento dos dados do perfil e exclusão da sua conta.
*/

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import "./estilos.css";
import { FiEdit3, FiCamera, FiXCircle } from "react-icons/fi";
import Menu from "../../../Componentes/GlobalMenu/indice";
import ModalFoto from "../../../Componentes/Geral/ModalFoto/indice";
import defaultPhoto from "../../../Arquivos/avatarPadrao.svg";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";
import axios from "axios";
import api from "../../../Servicos/api";
import swal from "sweetalert";

export default function PaginaInicial() {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  const [modalFoto, setModalFoto] = useState(false);
  const [edit, setEdit] = useState(false);

  const area_specialization = localStorage.getItem("especializacao");
  const [crm, setCRM] = useState(localStorage.getItem("crm"));
  const [uf, setUF] = useState(localStorage.getItem("uf"));
  const [rqe, setRQE] = useState(localStorage.getItem("rqe"));
  const [name_first, setNameFirst] = useState(localStorage.getItem("name_first"));
  const [name_last, setNameLast] = useState(localStorage.getItem("name_last"));
  const cpf = localStorage.getItem("cpf");
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [phone,setPhone] = useState(localStorage.getItem("phone"));
  const [photo, setPhoto] = useState(localStorage.getItem("photo"));
  const [state, setState] = useState(localStorage.getItem("state"));
  const [city, setCity] = useState(localStorage.getItem("city"));
  const [number, setNumber] = useState(localStorage.getItem("number"));
  const [street, setStreet] = useState(localStorage.getItem("street"));
  const [housign_type, setHousingType] = useState(localStorage.getItem("housign_type"));
  const [cep, setCEP] = useState(localStorage.getItem("cep"));
  const gender = localStorage.getItem("gender");
  const data_birth =localStorage.getItem("data_birth");
  const specialization_id = localStorage.getItem("specialization_id");
  //const username = localStorage.getItem("username");


  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);


  //const CPF = cpf.valueOf().replace(/[^\d]+/g,'');

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!state || state === "0") {
      return setListaCidades([]);
    }

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
      )
      .then((response) => {
        const cidadeNome = response.data.map((cidade) => cidade.nome);

        setListaCidades(cidadeNome);
      })
      .catch((error) => console.log(error));
  }, [state]);

  /*async function AlterarEmail(){
    try {
      const data = {
        email,
      }
      await api.put(`changeEmail/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.setItem("email", email);
    } catch (err) {
      swal(err.response.data.error, "Erro ao editar email", "error");
    }
  }*/

  async function AlterarPerfil(e){
    e.preventDefault();

    try {
      const data = {
        gender,
        name_first,
        name_last,
        phone,
        cpf,
        area_specialization,
        uf,
        rqe,
        crm,
        specialization_id,
      };
      await api.put(`doctor/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //AlterarEmail();
      swal("Dados alterados com Sucesso", "", "success");
      setEdit(false);

      localStorage.setItem("name_first", name_first);
      localStorage.setItem("name_last", name_last);
      localStorage.setItem("data_birth", data_birth);
      localStorage.setItem("uf", uf);
      localStorage.setItem("gender", gender);
      localStorage.setItem("crm", crm);
      localStorage.setItem("rqe", rqe);
      localStorage.setItem("phone", phone);
      localStorage.setItem("area_specialization", area_specialization);
      localStorage.setItem("cpf", cpf);
      localStorage.setItem("specialization_id", specialization_id);
      localStorage.setItem("state", state);
      localStorage.setItem("city", city);
      localStorage.setItem("cep", cep);
      localStorage.setItem("number", number);
      localStorage.setItem("street", street);
      localStorage.setItem("housign_type", housign_type);

      history.push("/medico/perfil");
    } catch (err) {
      swal(err.response.data.error, "Erro ao editar dados", "error");
    }
  }

  return (
    <div className="perfil-container">
      <ModalFoto
        modalFoto={modalFoto}
        setModalFoto={setModalFoto}
        photo={photo}
        setPhoto={setPhoto}
      />
      <Menu />
      <section className="conteudo-medico">
        <div className="container-formulario">
          <div className="container-perfil">
            <img src={photo ? photo : defaultPhoto} alt="Foto de Perfil" />

            <div className="informacao">
              <button
                className="botao-nova-foto"
                onClick={() => {
                  setModalFoto(true);
                }}
              >
                <FiCamera style={{ color: "#ffffff", width: 20, height: 20 }} />
              </button>

              <div id="div-ajuda">
                <h1 className="informacao-nome">Dr(a). {name_first}</h1>
                <Ajuda
                  mensagem={
                    "Nesta tela é possivel visualizar sua informações como também sua imagem."
                  }
                  width={"20rem"}
                  backColor={"var(--cor-branco)"}
                  fontColor={"var(--cor-azul)"}
                  margin={"2.0rem 0 0 -2.0rem"}
                  border={"0.5rem"}
                  size={"2.5rem"}
                />
              </div>

              <h3 className="informacao-especializacao">
                {area_specialization}
              </h3>
              <h4 className="informacao-crm">CRM: {crm}</h4>
              <div className="informacao-acao">
                  <Link
                    to="#"
                    className={`informacao-acao-editar ${edit ? "editar" : ""}`}
                    onClick={() => setEdit(true)}
                  >
                    <FiEdit3 className="icon" />
                    Alterar Perfil
                  </Link>
              </div>
            </div>
          </div>
          <form onSubmit={AlterarPerfil}>
            <h2 className="titulo-formulario">Meus dados</h2>

            <div className="container-input">
              <div>
                <label htmlFor="especializacao">Especialização</label>
                <input
                  id="especializacao"
                  type="text"
                  value={area_specialization}
                  disabled={true}
                />
              </div>
              <div>
                <label htmlFor="escolaridade">RQE:</label>
                <input type="text" id="rqe" value={rqe} 
                  disabled={edit ? false : true}
                  onChange={(e) => setRQE(e.target.value)}
                />
              </div>
            </div>

            <div className="container-input" id="input-escolaridade">
              <div>
                <label htmlFor="crm">CRM</label>
                <input type="text" id="crm" value={crm} 
                  disabled={edit ? false : true}
                  onChange={(e) => setCRM(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="uf">Estado</label>
                <input placeholder="UF" value={uf} 
                  disabled={edit ? false : true}
                  onChange={(e) => setUF(e.target.value)}
                />
              </div>
            </div>

            <div className="container-input">
              <div>
                <label htmlFor="nome">Nome</label>
                <input
                  type="text"
                  id="nome"
                  value={name_first}
                  disabled={edit ? false : true}
                  onChange={(e) => setNameFirst(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="sobrenome">Sobrenome</label>
                <input
                  type="text"
                  id="sobrenome"
                  value={name_last}
                  disabled={edit ? false : true}
                  onChange={(e) => setNameLast(e.target.value)}
                />
              </div>
            </div>

            <div className="container-input" id="input-cpf">
              <div>
                <label htmlFor="cpf">CPF</label>
                <input
                  type="text"
                  disabled={true}
                  id="cpf"
                  value={`${cpf[0] + cpf[1] + cpf[2]}.***.***-**`}
                />
              </div>
            </div>

            <div className="container-input">
              <div>
                <label htmlFor="telefone">Telefone</label>
                <input
                  type="text"
                  id="telefone"
                  value={phone}
                  disabled={edit ? false : true}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="container-input" id="input-email">
              <div>
                <label htmlFor="email">Email</label>
                <input type="email" 
                  id="email" 
                  value={email} 
                  disabled={edit ? false : true}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <h2 className="titulo-formulario">Endereço</h2>

            <div id="input-estado" className="container-input">
              <div>
              <label htmlFor="state">Estado</label>
                <select
                  id="state"
                  value={state || ""}
                  onChange={(e) => setState(e.target.value)}
                  disabled={edit ? false : true}
                >
                  <option value={state || ""} style={{ textColor: "#6e757d" }}>
                    {state}
                  </option>
                  {listaEstados.map((uf) => (
                    <option key={uf} value={uf}>
                      {uf}
                    </option>
                  ))}
                </select>
              </div>

              <div>
              <label htmlFor="city">Cidade</label>
                <select
                  id="city"
                  value={city || ""}
                  onChange={(e) => setCity(e.target.value)}
                  disabled={edit ? false : true}
                >
                  <option value={city} style={{ textColor: "#6e757d" }}>
                    {city}
                  </option>
                  {listaCidades.map((listaCidade) => (
                    <option key={listaCidade} value={listaCidade}>
                      {listaCidade}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div id="input-numero" className="container-input">
              <div>
                <label htmlFor="street">Rua</label>
                <input type="text" id="street" value={street} disabled={edit ? false : true} 
                  onChange={(e) => setStreet(e.target.value)}
                  />
              </div>

              <div>
                <label htmlFor="number">Numero</label>
                <input type="text" id="number" value={number}
                  disabled={edit ? false : true}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
            </div>

            <div className="container-input">
              <div>
                <label htmlFor="housign_type">Tipo da Residência</label>
                <input id={housign_type} value={housign_type} 
                  disabled={edit ? false : true}
                  onChange={(e) => setHousingType(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="cep">CEP</label>
                <input type="inteiro" id="cep" value={cep} 
                  disabled={edit ? false : true}
                  onChange={(e) => setCEP(e.target.value)}
                />
              </div>
            </div>

            <div className={`container-botao ${edit ? "editar" : ""}`}>
              <button
                className="botao-grande"
                onClick={() => setEdit(false)}
                type="reset"
                formNoValidate
              >
                Cancelar
              </button>
              <button className="botao-grande" type="submit">
                Salvar
              </button>
            </div>
          </form>
          <button
            className={`informacao-acao-deletar  ${edit ? "editar" : ""}`}
            //onClick={() => DeletePaciente()}
          >
            <FiXCircle className="icon" />
            Excluir conta
          </button>
        </div>
      </section>
    </div>
  );
}
