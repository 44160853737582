/*
Data de criação: 08/08/2020;
Autor: Paulo Lopes;
Descrição: Painel controle do médico, responsável por disponibilizar ao médico as
informações principais relacionadas a consultas;
*/

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FiBell, FiUser, FiUsers, FiCalendar } from "react-icons/fi";

import "./estilos.css";

import Menu from "../../../Componentes/GlobalMenu/indice";
import CartaoConsulta from "../../../Componentes/Medico/CartaoConsulta/indice";
import ConsultasDiarias from "../../../Componentes/Medico/ConsultasDiarias/indice";
import DetalhesConsulta from "../../../Componentes/Medico/DetalhesConsulta/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

import api from "../../../Servicos/api";

export default function PaginaInicial() {
  const nome = localStorage.getItem("name_first");
  const idMedico = localStorage.getItem("idMedico");
  const token = localStorage.getItem("token");
  const [atendidos, setAtendidos] = useState(null);
  const [agendados, setAgendados] = useState(null);
  const [diarios, setDiarios] = useState(null);
  const [consultasDiarias, setConsultasDiarias] = useState(null);
  const [carregando, setCarregando] = useState(null);

  const [notificacoes, setStatusNotific] = useState();

  const id = localStorage.getItem("id");

  const foto = localStorage.getItem("photo");

  useEffect(() => {
    api
      .get(`/unreadNotifications/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (parseInt(response.data.count) > 0) {
          setStatusNotific(true);
        }
      });
  }, [id, token]);

  //Pegar data
  let dataObj = new Date();
  let dia =
    dataObj.getDate() < 10 ? `0${dataObj.getDate()}` : dataObj.getDate();
  let mes =
    dataObj.getMonth() + 1 < 10
      ? `0${dataObj.getMonth() + 1}`
      : dataObj.getMonth() + 1;
  let ano = dataObj.getFullYear();
  let dataAtual = `${ano}-${mes}-${dia}`;

  useEffect(() => {
    api
      .get(`/doctorDashboard/${idMedico}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          consult_date: dataAtual,
        },
      })
      .then((response) => {
        setAgendados(response.data.agendados);
        setAtendidos(response.data.atendidos);
        setDiarios(response.data.diarios);
      });

    api
      .get(`/doctor/consultation/${idMedico}/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
          consult_date: dataAtual,
        },
      })
      .then((response) => {
        setConsultasDiarias(response.data);
        setCarregando(true);
      });
  }, [dataAtual, idMedico, token]);

  const listaExames = [
    { nomeExame: "Hemograma" },
    { nomeExame: "audiometria" },
  ];

  return (
    <div className="ContainerPainel">
      <Menu />
      <div className="ContainerConteudo">
        <header id="header-medico">
          <div id="div-ajuda">
            <h1>Bem vindo, {nome}.</h1>
            <Ajuda
              mensagem={
                "Nesta tela é possiel visualizar informações sobre consultas marcadas e seus pacientes."
              }
              width={"35rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>

          <div className="Perfil">
            <Link to="/medico/notificacoes">
              {notificacoes ? <h1 id="sino">.</h1> : ""}
              <FiBell className="Notificacao"></FiBell>
            </Link>
            <div className="FotoPerfil">
              <img src={foto} alt="" />
            </div>
          </div>
        </header>

        <main>
          <h2>Consultas semanais</h2>
          <div className="ConsultasSemanais">
            <div className="ConsultasCartao">
              <CartaoConsulta
                icone={<FiUsers className="Icone" />}
                titulo="Total de pacientes atendidos"
                numero={
                  atendidos === null || atendidos === undefined
                    ? "0"
                    : atendidos
                }
                corFundo="var(--cor-azul-escuro)"
              />

              <CartaoConsulta
                icone={<FiUser className="Icone" />}
                titulo="Total diário"
                numero={
                  diarios === null || diarios === undefined ? "0" : diarios
                }
                corFundo="var(--cor-azul-claro)"
              />

              <CartaoConsulta
                icone={<FiCalendar className="Icone" />}
                titulo="Total agendados"
                numero={
                  agendados === null || agendados === undefined
                    ? "0"
                    : agendados
                }
                corFundo="var(--cor-azul)"
              />
            </div>
          </div>

          <div className="Consultas">
            <div className="ConsultasDiarias">
              <h2>Consultas diárias</h2>
              {carregando ? (
                <ConsultasDiarias
                  tituloColuna1="Horário"
                  tituloColuna2="Nome"
                  consultas={consultasDiarias}
                />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <td>Não existem médicos cadastrados.</td>
                    </tr>
                  </thead>
                </table>
              )}
            </div>

            <div className="DetalheConsulta">
              <h2>Detalhes da consulta</h2>
              <DetalhesConsulta
                nome={"Maria Aparecida dos Santos"}
                idade={"28 anos"}
                sintomas={
                  "Reclama de dores no ouvido do lado esquerdo, informa que as vezes há secreção e que as secreções ocorrem geralmente à noite."
                }
                listaExames={listaExames}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
