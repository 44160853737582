/*
Data de criação: 01/09/2020;
Autor: Paulo Lopes;
Descrição: Painel Principal da Clínica, reune informações acerca do paciente e consultas;
*/
import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import api from "../../../Servicos/api";

//Componentes
import Menu from "../../../Componentes/GlobalMenu/indice";
import Cartao from "../../../Componentes/Clinica/Cartao/indice";
import InformacoesPacientes from "../../../Componentes/Clinica/InformacoesPacientes/indice";
import ConsultasDiarias from "../../../Componentes/Clinica/ConsultasDiarias/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

import { FiCalendar, FiChevronsRight, FiBarChart } from "react-icons/fi";

import "./estilos.css";

export default function PainelPrincipalClinica() {
  const id = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const fantasy_name = localStorage.getItem("name_first");
  const [totalPacientes, setTotalPacientes] = useState(0);
  const [idadesPacientes, setIdadesPacientes] = useState([]);

  const [consultas, setTotalConsultas] = useState();
  const [consultAverage, setConsultAverage] = useState();
  const [consultReturn, setConsultReturn] = useState();

  const [exibeEstatisticas, setExibeEstatisticas] = useState(false);

  const foto = localStorage.getItem("photo");
  //Buscar dados dos pacientes

  useEffect(() => {
    api
      .get(`/manager/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const pacientesClinica = response.data.countAllPatient;
        const idadesPacientesClinica = response.data.ageRange;
        setTotalPacientes(pacientesClinica);
        setIdadesPacientes(idadesPacientesClinica);

        setTotalConsultas(response.data.consult[0].count || "Nenhuma");
        setConsultAverage(response.data.consultAverage[0].duration || "0");
        setConsultReturn(
          response.data.consultReturn[0].count ||
          "Nenhuma consulta para retorno"
        );

        setTotalPacientes(
          pacientesClinica === undefined ? "0" : pacientesClinica
        );

        setExibeEstatisticas(true);
      })
      .catch((error) => console.log(error));
  }, [id, token]);

  return (
    <div className="PainelPrincipalClinica">
      <Menu />

      <div className="ConteudoClinica">
        <header>
          <h1>Bem-vindo, {fantasy_name}.</h1>
          <div id="Perfil">
            {/* <Link to="/clinica">
              <FiBell id="Notificacao" />
            </Link> */}
            <div id="FotoPerfil">
              <img src={foto} alt="Foto da Clínica" />
            </div>
          </div>
        </header>

        <main>
          <div id="Titulo">
            <span>Painel</span>
            <FiChevronsRight id="Icone" />
            <span>Visão geral &amp; Estatísticas*</span>
            <Ajuda
              mensagem={
                "Nesta tela é possível vizualizar informações referentes a faixa etária, total de pacientes e consultas(agendadas,retornos,média e quantidade diária). "
              }
              width={"39rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"-0.5rem 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>

          <section id="Cartoes">
            <Cartao
              icone={<FiCalendar className="Icone" />}
              cor="var(--cor-azul-escuro)"
              titulo="Consultas Agendadas"
              valor={consultas}
            />

            <Cartao
              icone={<FiBarChart className="Icone" />}
              cor="var(--cor-azul-claro)"
              titulo="Média de Duração das Consultas"
              valor={consultAverage + " min."}
            />

            <Cartao
              icone={<FiCalendar className="Icone" />}
              cor="var(--cor-azul)"
              titulo="Retornos Programados"
              valor={consultReturn}
            />
          </section>

          <section id="Estatisticas">
            {exibeEstatisticas ? (
              <>
                <InformacoesPacientes
                  totalPacientes={totalPacientes}
                  idadesPacientes={idadesPacientes}
                />

                <ConsultasDiarias />
              </>
            ) : (
              <div />
            )}
          </section>
        </main>
      </div>
    </div>
  );
}
