import React from "react";

import Logo from "../../../Arquivos/higia.svg";

import "./estilos.css";

class Pdf extends React.Component {
  render() {
    return (
      <div className="impressao">
        <header className="cabecalho">
          <img src={Logo} alt="Logo" />

          <h1>Minha Clinica</h1>
        </header>

        <div className="conteudo">
          {this.props.children}

          <div className="medico">
            <p>
              Nome do(a) Médico(a): {this.props.nomeMedico} {this.props.nome2}
            </p>
            <p>; CRM:{this.props.crm}; </p>
            <p>; UF: {this.props.uf} </p>
          </div>

          <div className="assinatura">
            <samp>_________________________</samp>
            <p> Assinatura do médico</p>
          </div>
        </div>
        <footer>
          <div>
            <p>Contato: (84)9.9999-9999</p>
            <p>Rua: Programadores React, n°3000</p>
            <p>Bairro: localhost </p>
            <p>Email: minhaclinica@gmail.com</p>
          </div>
        </footer>
      </div>
    );
  }
}

export default Pdf;
