import React, { useEffect, useState } from 'react';
import { FiX } from "react-icons/fi";

import AnimacaoCarregando from '../../../Componentes/AnimacaoCarregando/indice';
import FormAnnotation from '../container-form-anotacao/indice';
import ModalExcluirAnotacao from "../ExcluirAnotacao/indice";
import Paginacao from '../PaginacaoModal/indice';
import Tabela from '../TabelaListaAnotacoes/indice';

import api from "../../../Servicos/api";
import "./estilos.css";

function ModalAnotacao({abrirModal, setAbrirModal}) {

    const employee_id = localStorage.getItem("employee_id");
    const token = localStorage.getItem("token");
    const [loading] = useState(false);
    const [anotacoes, setAnotacoes] = useState([]);
    const [selectedAnnotation, setSelectedAnnotation] = useState();
    const [showTable, setShowTable] = useState(true);
    const [showPagination, setShowPagination] = useState(true);
    const [openFormAnnotation, setOpenFormAnnotation] = useState(false);
    const [abrirModalExcluir, setAbrirModalExcluir] = useState(false);
    const [idAnotacao, setIdAnotacao] = useState();

    const limitPage = 5;
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState();

    useEffect(() => {
        api
          .get(`/employee/annotation/${employee_id}?page=${page}&limit=${limitPage}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setAnotacoes(response.data.data);
            setTotal(response.data.total);
          })
          .catch((error) => console.log(error));
    }, [employee_id, token, page]);

    if(abrirModal === true){
        return (
            <div className='Sobreposicao'>
                <section className='ModalAnotacao'>
                    <button onClick={() => setAbrirModal(false)}>
                        <FiX className="Icone"/>
                    </button>
                    {loading ? (
                        <AnimacaoCarregando color="#1070a1" />
                    ) : (
                        <>
                            {showTable === true ? (
                                <Tabela
                                    notes={anotacoes}
                                    setSelectedAnnotation={setSelectedAnnotation}
                                    setShowTable={setShowTable}
                                    setShowPagination={setShowPagination}
                                    setOpenFormAnnotation={setOpenFormAnnotation}
                                    setAbrirModalExcluir={setAbrirModalExcluir}
                                    setIdAnotacao={setIdAnotacao}
                                />
                            ) : null}
                            {anotacoes.length !== 0 && showPagination === true ? (
                                <Paginacao
                                    total={total}
                                    limit={limitPage}
                                    onPageChange={(page) => setPage(page)}
                                />
                            ) : null}
                            {openFormAnnotation === true ? (
                                <FormAnnotation
                                    selectedAnnotation={selectedAnnotation}
                                    setShowTable={setShowTable}
                                    setShowPagination={setShowPagination}
                                    setOpenFormAnnotation={setOpenFormAnnotation}
                                />
                            ) : null}
                            {abrirModalExcluir === true ? (
                                <ModalExcluirAnotacao
                                    abrirModalExcluir={abrirModalExcluir}
                                    setAbrirModalExcluir={setAbrirModalExcluir}
                                    idAnotacao={idAnotacao}
                                />
                            ): null}
                        </>
                    )}
                </section>
            </div>
        );
    }
    return null;
}

export default ModalAnotacao;