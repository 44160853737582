import React, { useEffect, useState } from 'react';
import { FiChevronsRight } from 'react-icons/fi';
import 'react-calendar/dist/Calendar.css';

//Componentes
import Menu from '../../../Componentes/GlobalMenu/indice';
import Calendario from '../../../Componentes/Clinica/Calendario/indice';
import FotoPadrao from '../../../Arquivos/avatarPadrao.svg';
import AnimacaoCarregando from '../../../Componentes/AnimacaoCarregando/indice';
import Ajuda from '../../../Componentes/Geral/Ajuda/Ajuda';

import api from '../../../Servicos/api';

import imagem from '../../../Arquivos/img-error.svg';
import './estilos.css';

function AgendaClinica() {
  const fantasy_name = localStorage.getItem('fantasy_name');
  const token = localStorage.getItem('token');

  const [listaMedico, setListaMedico] = useState([]);
  const [doctorSelect, setDoctorSelect] = useState('');
  const [listaConsulta, setListaConsulta] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [doctorSelectId, setDoctorSelectId] = useState(0);
  const [doctorSpecialization, setDoctorSpecialization] = useState('');

  useEffect(() => {
    setCarregando(true);
    api
      .get(`searchDoctors/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListaMedico(response.data);
        setCarregando(false);
      });
  }, [token]);

  useEffect(() => {
    api
      .get(`listConsultations/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListaConsulta(response.data);
      });
  }, [token]);

  function setValueMedicoInput() {
    var inputValue = document.getElementById('medicoId4').value;
    var options = document.getElementById('dataListMedico4').childNodes;

    for (var i = 0; i < listaMedico.length; i++) {
      if (options[i].value === inputValue) {
        setDoctorSelect(inputValue);
        setDoctorSelectId(listaMedico[i].person.doctor.id);
        setDoctorSpecialization(
          listaMedico[i].person.doctor.area_specialization
        );
        break;
      }
    }
  }

  return (
    <div className="PainelAgendaClinica">
      <Menu />
      {carregando ? (
        <div className="ConteudoAgenda teste">
          <AnimacaoCarregando color="#1070a1" />
        </div>
      ) : listaMedico.length === 0 ? (
        <div className="ConteudoAgenda container-err">
          <div className="error-container">
            <h1>Ops! Nenhum Médico Cadastrado</h1>
            <p>
              Nenhum cadastrado de Médico Localizado.
              <br />
              Verifique cadastro de Médico ou realize o cadastro do médico em{' '}
              <strong>
                Menu{' '}
                <FiChevronsRight
                  style={{ marginTop: 1, marginBottom: -4 }}
                  size="18"
                />{' '}
                Médicos{' '}
                <FiChevronsRight
                  style={{ marginTop: 1, marginBottom: -4 }}
                  size="18"
                />{' '}
                Cadastrar Médico.
              </strong>
            </p>
          </div>
          <img src={imagem} alt="imagem em construção" />
        </div>
      ) : (
        <div className="ConteudoAgenda">
          <header>
            <h1 id="tituloClinica">Bem-vindo, {fantasy_name}.</h1>
            <div id="Perfil">
              <div id="FotoPerfil">
                <img src={FotoPadrao} alt="Foto da Clínica" />
              </div>
            </div>
          </header>
          <main>
            <div id="TituloAgenda">
              <span>Agenda</span>
              <FiChevronsRight id="Icone" />

              <span>Agenda Médica</span>
              <div id="div-ajuda">
                <Ajuda
                  mensagem={
                    'Nesta tela é possível visualizar a agenda diaria, semanal e mensal dos médicos da clínica.'
                  }
                  width={'40rem'}
                  backColor={'var(--cor-branco)'}
                  fontColor={'var(--cor-azul)'}
                  margin={'0 0 0 1.5rem'}
                  border={'0.5rem'}
                  size={'2.5rem'}
                />
              </div>
            </div>

            <div>
              <input
                id="medicoId4"
                required
                value={doctorSelect}
                className="selectConsulta"
                list="dataListMedico4"
                autoComplete="off"
                placeholder={'--Selecione um Médico --'}
                onClick={(e) => {
                  setDoctorSelect('');
                  setDoctorSelectId(0);
                  setDoctorSpecialization('');
                  e.target.value = '';
                }}
                onChange={(e) => {
                  setValueMedicoInput();
                }}
              />

              <datalist id="dataListMedico4">
                {listaMedico.map((medico) => (
                  <option
                    key={medico.person.doctor.id}
                    data-value={medico.person.doctor.id}
                    value={medico.person.cpf + ' - ' + medico.person.name_first}
                  >
                    {medico.person.cpf + ' - ' + medico.person.name_first}
                  </option>
                ))}
              </datalist>
              <label className="modalLabel">Nome do Médico</label>
            </div>

            <section className="CalendarContainer">
              <Calendario
                listaConsulta={listaConsulta}
                setListaConsulta={setListaConsulta}
                medico={doctorSelect}
                medicoId={doctorSelectId}
                medicoSpecialization={doctorSpecialization}
              />
            </section>
          </main>
        </div>
      )}
    </div>
  );
}

export default AgendaClinica;
