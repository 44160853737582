/*-------------- External --------------*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

/*-------------- Services --------------*/
import api from '../../../Servicos/api';
import useDebounce from '../../../Servicos/useDebounce';

/*-------------- Components --------------*/
import Menu from '../../../Componentes/GlobalMenu/indice';
import Ajuda from '../../../Componentes/Geral/Ajuda/Ajuda';
import Paginacao from '../../../Componentes/Paginacao/indice';
import Table from '../../../Componentes/Tables/TabelaPaciente';
import Form from '../../../Componentes/Paciente/Modal-Cadastro-Paciente/indice';
import AnimacaoCarregando from '../../../Componentes/AnimacaoCarregando/indice';

/*-------------- Assets --------------*/
import foto from '../../../Arquivos/avatarPadrao.svg';

/*-------------- Styles --------------*/
import './estilos.css';

export default function PacientesClinica() {
  /*-------------- States --------------*/
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [items, setItems] = useState([]);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const [tableItems, setTableItems] = useState([]);

  /*-------------- Storage --------------*/
  const token = localStorage.getItem('token');
  const fantasyName = localStorage.getItem('fantasy_name');
  const userName = localStorage.getItem('name_first');

  /*-------------- Constants --------------*/
  const titles = [
    { nome: 'Prontuário' },
    { nome: 'Nome' },
    { nome: 'CPF' },
    { nome: 'Telefone' },
    { nome: 'Idade' },
    { nome: 'Opções' },
  ];

  /*-------------- Effects --------------*/
  useEffect(() => {
    api
      .get(`/searchPatients/?page=${page}&limit=7`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTotal(response.data.total);
        setItems(response.data.data);
        setTableItems(response.data.data);
      });
  }, [token, modal, page]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      api
        .get(`/searchAndFilterPatients?search=${debouncedSearchTerm}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setTableItems(response.data);
          setLoading(false);
        });
    }
  }, [debouncedSearchTerm, token]);

  useEffect(() => {
    if (searchValue === '') {
      setTableItems(items);
    }
  }, [searchValue, items]);

  return (
    <div className="ContainerClinicaPaciente">
      <Menu />
      <div className="Container-conteudo">
        <header>
          <h1>Bem-vindo, {fantasyName ? fantasyName : userName}.</h1>
          <div className="Perfil">
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de Perfil" />
            </div>
          </div>
        </header>

        <nav>
          <div className="navegacao">
            <h2>{'Paciente  '}</h2>
            <h5>
              {'>>'} {'Busca & Visão geral *'}
            </h5>
            <div id="div-ajuda">
              <Ajuda
                mensagem={
                  'Nesta tela é possivel gerenciar os pacientes da sua clínica, possibilitando cadastrar, atualizar, visualizar e remover.'
                }
                width={'40rem'}
                backColor={'var(--cor-branco)'}
                fontColor={'var(--cor-azul)'}
                margin={'0 0 0 1.5rem'}
                border={'0.5rem'}
                size={'2.5rem'}
              />
            </div>
          </div>
        </nav>

        <section id="section-clinica-pacientes">
          <div className="pesquisa">
            <div className="box-pesquisa">
              <input
                value={searchValue}
                type="text"
                className="nome-input"
                placeholder="Busca por nome do paciente"
                onChange={(e) =>
                  setSearchValue(e.target.value.replace(/[^a-zA-Z ]/g, ''))
                }
                onKeyPressCapture={(e) =>
                  setSearchValue(e.target.value.replace(/[^a-zA-Z ]/g, ''))
                }
              />
              <label className="label-input">Pesquisa</label>
            </div>
          </div>
          <Link
            to="#"
            className="botao-grande cadastro"
            onClick={() => setModal(true)}
          >
            Cadastrar Paciente
          </Link>
          <Form abrirModal={modal} setAbrirModal={setModal} />
          {loading ? (
            <AnimacaoCarregando color="#1070a1" />
          ) : (
            <>
              <Table
                titles={titles}
                tableItems={tableItems}
                route="/clinica/paciente/perfil"
              />
              <Paginacao
                consultasPorPagina={7}
                totalConsulta={total}
                setPage={setPage}
              />
            </>
          )}
        </section>
      </div>
    </div>
  );
}
