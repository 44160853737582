/*
Data de criação: 08/09/2020;
Autor: Paulo Lopes;
Descrição: Página de visualização do perfil, responsável por disponibilizar
ao médico os dados do perfil e o gerenciamento deles.
*/

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiEdit3 } from "react-icons/fi";

import "./estilos.css";
import Menu from "../../../Componentes/GlobalMenu/indice";
import VisualizarMedico from "../../../Componentes/Atendente/VisualizarMedico/indice";
import photo from "../../../Arquivos/avatarPadrao.svg";
export default function PerfilMedicoClinica() {
  //Edição
  const [modoEdicao, setModoEdicao] = useState("");

  //Dados do médico
  const [area, setArea] = useState();
  const [registro, setRegistro] = useState();
  const [nome, setNome] = useState();

  //const photo = "";

  return (
    <div className="perfil-container">
      <Menu />
      <section className="conteudo-medico">
        <div className="container-formulario">
          <div className="container-perfil">
            <img src={photo} alt="Foto de Perfil" />

            <div className="informacao">
              <h1 className="informacao-nome">Dr(a). {nome}</h1>
              <h3 className="informacao-especializacao">{area}</h3>
              <h4 className="informacao-crm">CRM: {registro}</h4>
              <div className="informacao-acao">
                <Link
                  className={`informacao-acao-editar ${modoEdicao}`}
                  onClick={() => setModoEdicao("editar")}
                >
                  <FiEdit3 className="icon" />
                  Alterar Perfil
                </Link>
              </div>
            </div>
          </div>
          <VisualizarMedico
            modoEdicao={modoEdicao}
            setModoEdicao={setModoEdicao}
            setNome={setNome}
            setArea={setArea}
            setRegistro={setRegistro}
          />
        </div>
      </section>
    </div>
  );
}
