import React from "react";
import Form from "../container-form/indice";
import { FiX } from "react-icons/fi";
import "./estilos.css";

function DadosCadastrais({ abrirModal, setAbrirModal }) {
  if (abrirModal === true) {
    return (
      <div className="SobreposicaoCadastrarPaciente">
        <section className="ModalDadosCadastrarPaciente">
          <footer>
            <button onClick={() => setAbrirModal(false)}>
              <FiX className="Icone"/>
            </button>
          </footer>
          <Form setAbrirModal={setAbrirModal} />
        </section>
      </div>
    );
  }
  return null;
}

export default DadosCadastrais;
