/*
Data de criação: 08/09/2020;
Autor: Paulo Lopes;
Descrição: Página de visualização do perfil, responsável por disponibilizar
ao médico os dados do perfil e o gerenciamento deles.
*/

import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import api from "../../../Servicos/api";
import swal from "sweetalert";

import "./estilos.css";
import Axios from "axios";

export default function VisualizarMedico({
  modoEdicao,
  setModoEdicao,
  setNome,
  setArea,
  setRegistro,
}) {
  const history = useHistory();

  //Dados do médico
  const [area_specialization, setArea_specialization] = useState("");
  const [specialization_id, setSpecialization_id] = useState(0);
  const [specialization_name, setSpecialization_name] = useState("");
  const [crm, setCrm] = useState();
  const [rqe, setRqe] = useState("");
  const [uf, setUf] = useState("");
  const [name_first, setName_first] = useState("");
  const [name_last, setName_last] = useState("");
  const [cpf, setCpf] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  setNome(name_first);
  setArea(area_specialization);
  setRegistro(crm);

  const idUserGet = localStorage.getItem("idUserGet");
  const idUserPut = localStorage.getItem("idUserPut");
  const token = localStorage.getItem("token");

  //API IBGE
  const [listaEstados, setListaEstados] = useState([]);

  // coletando dados da memed

  const [listaEspecialidade, setListaEspecialidade] = useState([]);
  const [listaEspecialidadeFilter, setListaEspecialidadeFilter] = useState([]);
  var especializacaoSelect = "";
  var especializacaoSelectId = 0;
  var GrupoEspecialidade = [];

  useEffect(() => {
    Axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    api
      .get(`getDoctor/${idUserGet}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const dados = response.data;
        setArea_specialization(dados.area_specialization);
        setSpecialization_id(dados.specialization_id);
        setCrm(dados.crm);
        setRqe(dados.rqe);
        setUf(dados.uf);
        setName_first(dados.person.name_first);
        setName_last(dados.person.name_last);
        setCpf(dados.person.cpf);
        setPhone(dados.person.phone);
        setEmail(dados.person.user[0].email);
        setUsername(dados.person.user[0].username);
      });
  }, [idUserGet, token]);

  useEffect(() => {
    listaEspecialidade.map((especialidade) => {
      if (
        especialidade.attributes.nome.toLowerCase() ===
        area_specialization.toLowerCase()
      ) {
        setSpecialization_name(especialidade.attributes.grupo);
        return null;
      } else {
        return null;
      }
    });
  }, [area_specialization, listaEspecialidade]);

  //Função de cancelar alterações
  async function cancelar() {
    await api
      .get(`getDoctor/${idUserGet}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const dados = response.data;
        setArea_specialization(dados.area_specialization);
        setSpecialization_id(dados.specialization_id);
        setCrm(dados.crm);
        setRqe(dados.rqe);
        setUf(dados.uf);
        setName_first(dados.person.name_first);
        setName_last(dados.person.name_last);
        setCpf(dados.person.cpf);
        setPhone(dados.person.phone);
        setEmail(dados.person.user.email);
        setUsername(dados.person.user.username);
      });
  }

  //Função de Alterar Perfil

  async function AlterarPerfil(e) {
    e.preventDefault();
    setArea_specialization(especializacaoSelect);
    setSpecialization_id(especializacaoSelectId);

    try {
      const gender = 2;
      const n_advice = crm;
      const photo = "";
      const data = {
        username,
        email,
        photo,
        gender,
        name_first,
        name_last,
        phone,
        cpf,
        n_advice,
        uf,
        area_specialization,
        specialization_id,
      };
      await api.put(`doctor/${idUserPut}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Dados alterados com Sucesso", "", "success");
      history.push("/atendente/medicos");
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  useEffect(() => {
    Axios.get("https://api.memed.com.br/v1/especialidades/").then(
      (response) => {
        const dados = response.data.data.map((especialidade) => especialidade);

        function compare(a, b) {
          if (a.attributes.grupo < b.attributes.grupo) {
            return -1;
          }
          if (a.attributes.grupo > b.attributes.grupo) {
            return 1;
          }
          return 0;
        }

        setListaEspecialidade(dados.sort(compare));
        setListaEspecialidadeFilter(dados.sort(compare));

        //setSpecialization_name(dados.filter((especialidade) => especialidade.attributes.nome.toLowerCase() === area_specialization.toLowerCase())[0].attributes.grupo);
      }
    );
  }, []);

  function setValueGrupoInput() {
    var inputValue = document.getElementById("grupoEspecializacaoId").value;
    var options = document.getElementById("dataListGrupo").childNodes;

    for (let i = 0; i < GrupoEspecialidade.length; i++) {
      if (options[i].value === inputValue) {
        const listaFiltrada = [];
        listaEspecialidade.map((especialidade) =>
          especialidade.attributes.grupo.toLowerCase() ===
            GrupoEspecialidade[i].toLowerCase()
            ? listaFiltrada.push(especialidade)
            : GrupoEspecialidade[i]
        );

        setSpecialization_name(inputValue);
        setListaEspecialidadeFilter(listaFiltrada);
        break;
      }
    }
  }
  function setValueEspecializacaoInput() {
    var inputValue = document.getElementById("especializacaoId").value;
    var options = document.getElementById("dataListEspecializacao").childNodes;

    for (var i = 0; i < listaEspecialidadeFilter.length; i++) {
      if (options[i].value === inputValue) {
        especializacaoSelectId = parseInt(
          options[i].getAttribute("data-value")
        );
        especializacaoSelect = inputValue;
        setArea_specialization(especializacaoSelect);
        setSpecialization_name(
          listaEspecialidade.filter(
            (especialidade) =>
              especialidade.attributes.nome.toLowerCase() ===
              inputValue.toLowerCase()
          )[0].attributes.grupo
        );
        break;
      }
    }
  }

  return (
    <form onSubmit={AlterarPerfil}>
      <h2 className="titulo-formulario">Meus dados</h2>

      <div className="container-input">
        <div>
          <label htmlFor="especializacao">Grupo de Especialização</label>
          <input
            id="grupoEspecializacaoId"
            list="dataListGrupo"
            value={specialization_name}
            disabled={modoEdicao === "editar" ? false : true}
            placeholder="-- Selecione um Grupo de Especialização --"
            autoComplete="off"
            onClick={(e) => {
              e.target.value = "";
              document.getElementById("especializacaoId").value = "";
              setListaEspecialidadeFilter(listaEspecialidade);
              setArea_specialization("");
            }}
            onChange={(e) => {
              setValueGrupoInput();
            }}
          />
          <datalist id="dataListGrupo">
            {listaEspecialidade
              .filter((especialidade) => {
                if (
                  GrupoEspecialidade.indexOf(
                    especialidade.attributes.grupo.toLowerCase()
                  ) === -1
                ) {
                  GrupoEspecialidade.push(
                    especialidade.attributes.grupo.toLowerCase()
                  );
                  return especialidade.attributes.grupo;
                } else {
                  return null;
                }
              })
              .map((grupo) => (
                <option
                  key={grupo.attributes.grupo}
                  data-value={grupo.id}
                  value={grupo.attributes.grupo}
                >
                  {grupo.attributes.grupo === "Null"
                    ? "Outros"
                    : grupo.attributes.grupo}
                </option>
              ))}
          </datalist>
        </div>
        <div>
          <label htmlFor="especializacao">Especialização</label>
          <input
            id="especializacaoId"
            value={area_specialization}
            list="dataListEspecializacao"
            disabled={modoEdicao === "editar" ? false : true}
            placeholder="-- Selecione uma Especialização --"
            autoComplete="off"
            onClick={(e) => {
              e.target.value = "";
            }}
            onChange={(e) => {
              setValueEspecializacaoInput();
            }}
          />

          <datalist id="dataListEspecializacao">
            {listaEspecialidadeFilter.map((especialidade) => (
              <option
                key={especialidade.id}
                data-value={especialidade.id}
                value={especialidade.attributes.nome}
              >
                {especialidade.attributes.nome}
              </option>
            ))}
          </datalist>
        </div>

        <div>
          <label htmlFor="escolaridade">RQE:</label>
          <input
            type="text"
            id="rqe"
            value={rqe}
            disabled={modoEdicao === "editar" ? false : true}
            onChange={(e) => setRqe(e.target.value)}
          />
        </div>
      </div>

      <div className="container-input" id="input-escolaridade">
        <div>
          <label htmlFor="crm">CRM</label>
          <input
            type="text"
            id="crm"
            value={crm}
            disabled={modoEdicao === "editar" ? false : true}
            onChange={(e) => setCrm(parseInt(e.target.value))}
          />
        </div>
        <div>
          <label htmlFor="uf">Estado</label>
          <select
            id="uf"
            value={uf}
            disabled={modoEdicao === "editar" ? false : true}
            onChange={(e) => setUf(e.target.value)}
          >
            <option value={uf} style={{ textColor: "#6e757d" }}>
              {uf}
            </option>
            {listaEstados.map((uf) => (
              <option key={uf} value={uf}>
                {uf}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="container-input">
        <div>
          <label htmlFor="nome">Nome</label>
          <input
            type="text"
            id="nome"
            value={name_first}
            disabled={modoEdicao === "editar" ? false : true}
            onChange={(e) => setName_first(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="sobrenome">Sobrenome</label>
          <input
            type="text"
            id="sobrenome"
            value={name_last}
            disabled={modoEdicao === "editar" ? false : true}
            onChange={(e) => setName_last(e.target.value)}
          />
        </div>
      </div>

      <div className="container-input" id="input-cpf">
        <div>
          <label htmlFor="cpf">CPF</label>
          <input
            type="text"
            disabled={modoEdicao === "editar" ? false : true}
            id="cpf"
            value={
              modoEdicao === "editar"
                ? cpf
                : `${cpf[0] + cpf[1] + cpf[2]}.***.***-**`
            }
            onChange={(e) => setCpf(e.target.value)}
          />
        </div>
      </div>

      <div className="container-input">
        <div>
          <label htmlFor="telefone">Telefone</label>
          <input
            type="text"
            disabled={modoEdicao === "editar" ? false : true}
            id="telefone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>

      <div className="container-input" id="input-email">
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            disabled={modoEdicao === "editar" ? false : true}
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="username">Nome do Usuário</label>
          <input
            type="text"
            disabled={modoEdicao === "editar" ? false : true}
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
      </div>

      <div className={`container-botao ${modoEdicao}`}>
        <button className="botao-grande" type="submit">
          Salvar
        </button>
        <Link
          className="botao-grande"
          to="#"
          onClick={() => {
            setModoEdicao("");
            cancelar();
          }}
        >
          Cancelar
        </Link>
      </div>
    </form>
  );
}
