/*
Data de criação: 02/09/2020;
Autor: Elizieb Luiz;
Descrição: Página de cadastrar uma clinica, responsável por permitir o admin cadastrar uma clinica
*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import api from "../../../Servicos/api";
import swal from "sweetalert";

import "./estilos.css";
import Menu from "../../../Componentes/GlobalMenu/indice";
export default function CadastrarClinica() {
  const history = useHistory();

  const [fantasy_name, setName_fantasia] = useState();
  const [social_reason, setRazao_social] = useState();
  const [cnpj, setCnpj] = useState();
  const [cnes, setCnes] = useState();
  const [cep, setCep] = useState();
  const [rua, setRua] = useState();
  const [numero, setNumero] = useState();
  const [password, setPassword] = useState();
  const [confirmationPassword, setConfirmationPassword] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [uf, setUF] = useState("");
  const [cidade, setCidade] = useState("");
  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);

  const photo = localStorage.getItem("photo");
  //const id = localStorage.getItem("id");

  async function AlterarPerfil(e) {
    e.preventDefault();
    try {
      const state = uf;
      const city = cidade;
      const number = numero;
      const street = rua;
      const housign_type = "";
      const data = {
        email,
        password,
        confirmationPassword,
        cnes,
        cnpj,
        fantasy_name,
        social_reason,
        phone,
        address: {
          state,
          city,
          cep,
          number,
          street,
          housign_type,
        },
      };

      await api.post("manager", data);
      swal("Clinica cadastrada com Sucesso", "", "success");
      history.push("/clinica/login");
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!uf || uf === "0") {
      return setListaCidades([]);
    }

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`
      )
      .then((response) => {
        const cidadeNome = response.data.map((cidade) => cidade.nome);

        setListaCidades(cidadeNome);
      })
      .catch((error) => console.log(error));
  }, [uf]);

  return (
    <div className="clinica-container">
      <Menu />
      <section className="conteudo-medico">
        <header>
          <h1>Bem-vindo, Admin</h1>
          <div className="Perfil">
            <div className="FotoPerfil">
              <img src={photo} alt="Foto de Perfil" />
            </div>
          </div>
        </header>
        <div className="container-formulario">
          <div className="container-perfil">
            <div className="informacao">
              <h1 className="informacao-nome">Cadastro Clínica</h1>
              <h3 className="informacao-especializacao">
                Preencha os dados corretamente!
              </h3>
            </div>
          </div>
          <form className="form-registro" onSubmit={AlterarPerfil}>
            <div className="grupo-button-form">
              <div className="grupo-nome">
                <div className="conteudo">
                  <div className="meu-box">
                    <input
                      type="text"
                      className="input-nome"
                      placeholder="Ex. Clinica Somos"
                      value={fantasy_name}
                      onChange={(e) => setName_fantasia(e.target.value)}
                      required
                    />
                    <label className="label-nome">Nome fantasia</label>
                  </div>
                </div>
                <div className="conteudo">
                  <div className="meu-box">
                    <input
                      type="text"
                      className="input-nome"
                      placeholder="Ex. João"
                      value={social_reason}
                      onChange={(e) => setRazao_social(e.target.value)}
                      required
                    />
                    <label className="label-nome">Razão social</label>
                  </div>
                </div>
              </div>
              <div className="conteudo">
                <div className="meu-box">
                  <input
                    type="text"
                    className="input-nome"
                    placeholder="77.292.758/0001-75"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                    required
                  />
                  <label className="label-nome">CNPJ</label>
                </div>
              </div>
              <div className="grupo-nome">
                <div className="conteudo">
                  <div className="meu-box">
                    <input
                      type="text"
                      className="input-nome"
                      placeholder="0010456"
                      value={cnes}
                      onChange={(e) => setCnes(e.target.value)}
                      required
                    />
                    <label className="label-nome">CNES</label>
                  </div>
                </div>
                <div className="conteudo">
                  <div className="meu-box">
                    <input
                      type="text"
                      className="input-nome"
                      placeholder="(84) 9 9999-9999"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    <label className="label-nome">Telefone</label>
                  </div>
                </div>
              </div>
              <div className="grupo-escolaridade-genero">
                <select
                  value={uf}
                  onChange={(e) => setUF(e.target.value)}
                  required
                  id="uf-number"
                >
                  <option value="">-- UF --</option>
                  {listaEstados.map((listaEstado) => (
                    <option key={listaEstado} value={listaEstado}>
                      {listaEstado}
                    </option>
                  ))}
                </select>
                <select
                  placeholder="Escolaridade"
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                  required
                >
                  <option value="" style={{ textColor: "#6e757d" }}>
                    -- Cidade --
                  </option>
                  {listaCidades.map((listaCidade) => (
                    <option key={listaCidade} value={listaCidade}>
                      {listaCidade}
                    </option>
                  ))}
                </select>
              </div>

              <div className="conteudo">
                <div className="meu-box">
                  <input
                    type="text"
                    className="input-nome"
                    placeholder="Rua João da escocia"
                    value={rua}
                    onChange={(e) => setRua(e.target.value)}
                    required
                  />
                  <label className="label-nome">Rua</label>
                </div>
              </div>

              <div className="grupo-cpf-data">
                <div className="conteudo">
                  <div className="meu-box">
                    <input
                      type="text"
                      className="input-nome"
                      placeholder="59.633-430"
                      value={cep}
                      onChange={(e) => setCep(e.target.value)}
                      required
                    />
                    <label className="label-nome">CEP</label>
                  </div>
                </div>
                <div className="conteudo" id="uf-number">
                  <div className="meu-box">
                    <input
                      className="input-nome"
                      placeholder="Ex. 258 B"
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                      required
                    />
                    <label className="label-nome">Número</label>
                  </div>
                </div>
              </div>
              <div className="conteudo">
                <div className="meu-box">
                  <input
                    className="input-nome"
                    placeholder="Ex. nome@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label className="label-nome">Email</label>
                </div>
              </div>
              <div className="grupo-senha">
                <div className="conteudo">
                  <div className="meu-box">
                    <input
                      className="input-nome"
                      placeholder="******"
                      value={password}
                      type={"password"}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <label className="label-nome">Senha</label>
                  </div>
                </div>
                <div className="conteudo">
                  <div className="meu-box">
                    <input
                      className="input-nome"
                      placeholder="******"
                      value={confirmationPassword}
                      type={"password"}
                      onChange={(e) => setConfirmationPassword(e.target.value)}
                      required
                    />
                    <label className="label-nome">Confirmar Senha</label>
                  </div>
                </div>
              </div>

              <button className="botao-grande" type="submit">
                Cadastrar
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
