import React, { useState, useEffect } from "react";
import api from "../../../Servicos/api";
import Menu from "../../../Componentes/GlobalMenu/indice";

import "./estilos.css";
import {
  FiCalendar,
  FiChevronsRight,
  FiXCircle,
  FiClock,
  FiFilePlus,
  FiEdit,
} from "react-icons/fi";
import { FaRegCalendarTimes } from "react-icons/fa";
import Paginacao from "../../../Componentes/Paginacao/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

export default function NotificacoesMedico() {
  const [notificacoes, setNotificacaoLista] = useState([]);
  const id = localStorage.getItem("id");
  const token = localStorage.getItem("token");
  const nome = localStorage.getItem("name_first");

  const limitPage = 7;
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();

  const foto = localStorage.getItem("photo");

  useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api
      .get(`/listNotifications/${id}/?page=${page}&limit=${limitPage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setNotificacaoLista(response.data.data);
        setTotal(response.data.total);
      });
  }, [id, token, page]);

  return (
    <div id="notificacoes-pac">
      <div className="background"></div>
      <Menu />
      <div id="container-notif-pac">
        <header>
          <h1>Bem-vindo, {nome}</h1>
          <div id="container-avatar">
            <img src={foto} alt="Foto de perfil padrão" />
          </div>
        </header>
        <div id="titulo">
          <h2>Notificações</h2>
          <FiChevronsRight size={20} />
          <div id="div-ajuda">
            <span>Visão geral</span>

            <Ajuda
              mensagem={
                "Nesta tela é possível visualizar as notificações do sistema."
              }
              width={"30rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>
        </div>
        {notificacoes.length === 0 ? (
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#FFF",
              color: "var(--cor-azul)",
              borderRadius: "8px",
              padding: "30px 0px",
            }}
          >
            Nenhuma notificação não lida
          </h1>
        ) : (
          <div id="conteudo-notif-med">
            {notificacoes.map((info) => (
              <CardNotificacoes
                key={info.id}
                info={info}
              />
            ))}
            <Paginacao
              consultasPorPagina={limitPage}
              totalConsulta={total}
              setPage={setPage}
              id="paginacao-notificacao"
            />
          </div>
        )}
      </div>
    </div>
  );
}

// 1 - Consulta a ser Realizada,
// 2 - Consulta Agendada,
// 3 - Consulta Atualizada,
// 4 - Consulta Cancelada,
// 5 - Consulta Concluída

function timeConverter(UNIX_timestamp) {
  var str = UNIX_timestamp;
  var dt = new Date(str);
  //test
  var months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  var year = dt.getFullYear();
  var month = months[dt.getMonth()];
  var date = dt.getDate();
  var hour = dt.getHours();

  var am_pm;

  if (hour > 12) am_pm = "PM";
  else am_pm = "AM";

  var time = date + "/" + month + "/" + year + ", às " + hour + " h " + am_pm;

  return time;
}

function dateConverter(st) {
  var months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  var dt = new Date(st);
  var year = dt.getFullYear();
  var month = months[dt.getMonth()];
  var date = dt.getDate();
  return date + "/" + month + "/" + year;
}

function CardNotificacoes({ info }) {
  function render() {
    switch (info.type) {
      case 1:
        return (
          <>
            <div className="fundo-icone" style={{ background: "#002952" }}>
              <FiCalendar size={"100%"} color="#fff" />
            </div>
            <section>
              <h3>
                Olá, lembre-se que você possui uma consulta hoje às{" "}
                {info.start_time} horas com Paciente {info.name}.
              </h3>
            </section>
          </>
        );
      case 2:
        return (
          <>
            <div className="fundo-icone" style={{ background: "#002952" }}>
              <FiCalendar size={"100%"} color="#fff" />
            </div>
            <section>
              <h3>
                Uma nova consulta foi agendada com o(a) paciente {info.name} no
                dia {dateConverter(info.date)} às {info.start_time} horas.
              </h3>
            </section>
          </>
        );
      case 3:
        return (
          <>
            <div className="fundo-icone" style={{ background: "" }}>
              <FiEdit size={"100%"} color="#fff" />
            </div>
            <section>
              <h3>
                A consulta com o paciente {info.name} foi alterada! Confira no
                sistema os novos detalhes.
              </h3>
            </section>
          </>
        );
      case 4:
        return (
          <>
            <div className="fundo-icone" style={{ background: "#F95B55" }}>
              <FaRegCalendarTimes size={"100%"} color="#fff" />
            </div>
            <section>
              <h3>
                A consulta com o paciente {info.name} às {info.start_time} horas
                do dia {dateConverter(info.date)} foi cancelada.
              </h3>
            </section>
          </>
        );
      case 5:
        return (
          <>
            <div className="fundo-icone" style={{ background: "#26CCCC" }}>
              <FiFilePlus size={"100%"} color="#fff" />
            </div>
            <section>
              <h3>
                A consulta com o paciente {info.name} no dia{" "}
                {dateConverter(info.date)} às {info.start_time} horas foi
                finalizada. Consulte o sistema para verificar os documentos
                envolvidos na consulta.
              </h3>
            </section>
          </>
        );
      default:
        break;
    }
  }

  const [opacity, setOpacity] = useState(info.is_check);

  async function PutNotificacao(id) {
    try {
      await api.put(`/changeCheckStatus/${id}`);
      setOpacity(true);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div
      id="container-card-notif"
      style={opacity === true ? { opacity: "0.5" } : {}}
      onMouseLeave={(e) => PutNotificacao(info.id)}
    >
      {render()}
      <button>
        <FiXCircle size={24} color={"#1070a1"} />
      </button>
      <span className="card-footer">
        <FiClock size={22} style={{ marginRight: 10 }} />{" "}
        {timeConverter(info.createdAt)}
      </span>
    </div>
  );
}
