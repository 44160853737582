import axios from "axios";

const api = axios.create({
  //baseURL: "http://195.110.59.116:5055",
  baseURL: "https://thehigia.com/api/clinica/",
  //baseURL: "https://api.thehigia.com",
  //baseURL: "http://localhost:3333",
});

export default api;

// baseURL: "http://higia-api.mobilehealth.com.br/",
// https://mobilehealth2.herokuapp.com/
// http://api.mobilehealth.com.br/
