import React, { useState } from "react";
import "./estilos.css";

import {
    FiChevronLeft,
    FiChevronRight
} from 'react-icons/fi';

export default function Paginacao({
    total,
    limit,
    onPageChange,
}) {

    const [, setAtivo] = useState(false);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [paginaAnterior, setPaginaAnterior] = useState(-1);
    const [paginaSeguinte, setPaginaSeguinte] = useState(0);

    const range = (start, end) => {
        const array = [];
        for (let i = start; i < end; i++) {
            array.push(i);
        }
        return array;
    };
    
    const pagesCount = Math.ceil(total / limit);
    const pages = range(0, pagesCount);

    return (
        <ul id="paginacaoModal" onClick={() => setAtivo(true)}>
            <span className="btn-arrow"
                key={paginaAnterior}
                onClick={() => 
                    paginaAtual > 0 ? (
                        onPageChange(paginaAtual - 1),
                        setPaginaAtual(paginaAtual - 1),
                        setPaginaAnterior(Math.max(0, paginaAnterior - 1))
                    ) : null
                }
            >
                <FiChevronLeft style={{marginBottom: "-0.35rem"}} size="20"/>Anterior
            </span>
            {pages.map((page, index) => (
                <span
                    style={page === paginaAtual ? {backgroundColor: "var(--cor-azul-escuro)"} : null}
                    key={index}
                    className="btn-paginacaoModal"
                    onClick={() => {
                        onPageChange(page);
                        setPaginaAtual(page);
                        setPaginaAnterior(Math.max(0, paginaAtual - 1));
                        setPaginaSeguinte(Math.min(pages.length - 1, paginaAtual + 1));
                    }}
                >
                    {page + 1}
                </span>
            ))}
            <span className="btn-arrow"
                key={paginaSeguinte}
                onClick={() => 
                    paginaAtual < pages.length - 1 ? (
                        onPageChange(paginaAtual + 1),
                        setPaginaAtual(paginaAtual + 1),
                        setPaginaSeguinte(Math.min(pages.length - 1, paginaSeguinte + 1))
                    ) : null
                }
            >
                Próximo<FiChevronRight style={{marginBottom: "-0.35rem"}} size="20"/>
            </span>
        </ul>
    );
}