/*
Data de criação: 05/08/2020;
Autor: Elizieb Luiz;
Descrição: Página de cadastro de um médico, tem o intuito de receber as informações do médico e cadastra-lo
no sistema;
*/

import React from "react";
import "./estilos.css";
import imagem from "../../Arquivos/img-constru.svg";
import Menu from "../../Componentes/GlobalMenu/indice";

export default function Cadastro() {
  return (
    <div className="container-menu">
      <Menu />
      <div id="container-conteudo-total">
        <div className="container-const">
          <div className="construcao-container">
            <h1>Página em construção</h1>
            <p>
              Nosso novo site está em fase de melhorias. Em breve você poderá
              ter acesso a nossa plataforma, por enquanto essa pagina está em
              construção. Estamos trabalhando para melhor atendê-lo.
            </p>
          </div>
          <img src={imagem} alt="imagem em construção" />
        </div>
      </div>
    </div>
  );
}
