import React, { useState, useEffect } from 'react';
import {
  FiSlash,
  FiCheck,
  FiChevronsRight,
  FiEdit,
  FiTrash2,
  FiUserPlus,
} from 'react-icons/fi';

import { removeNonNumeric } from '../../../Servicos/helpers/removeNonNumeric';

import foto from '../../../Arquivos/avatarPadrao.svg';
import Menu from '../../../Componentes/GlobalMenu/indice';
import Paginacao from '../../../Componentes/Paginacao/indice';
import StatusUsuario from '../../../Componentes/Clinica/StatusUsuario/indice';
import ModalExcluirUsuario from '../../../Componentes/Clinica/ModalExcluirUsuario/indice';
import Ajuda from '../../../Componentes/Geral/Ajuda/Ajuda';

import ModalNovoVinculo from '../../../Componentes/Clinica/Modal-Novo-Vinculo/indice';

import './estilos.css';
import api from '../../../Servicos/api';
import { cpfMascara } from '../../../Servicos/Mascaras/cpfMascara';

function UsuariosClinica() {
  const token = localStorage.getItem('token');
  const fantasyName = localStorage.getItem('fantasy_name');
  const userName = localStorage.getItem('name_first');

  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [listaUsuariosTabela, setListaUsuarioTabela] = useState([]);

  const [nextReq, setNextReq] = useState();
  const limitPage = 7;
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();

  const [tipoUsuario, setTipoUsuario] = useState('');

  const [abrirModalNovoVinculo, setAbrirModalNovoVinculo] = useState(false);

  //abrir modal
  const [abrirModal, setAbrirModal] = useState(false);

  const [abrirModalExcluir, setAbrirModalExcluir] = useState(false);

  const [status, setStatus] = useState(1);

  useEffect(() => {
    api
      .get(`/allUsers?page=${page}&limit=${limitPage}&status=${status}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListaUsuarios(response.data.data);
        setTotal(response.data.total);
      });
  }, [
    token,
    abrirModal,
    abrirModalExcluir,
    abrirModalNovoVinculo,
    nextReq,
    page,
    status,
  ]);

  //Buscar Pacientes pelo Nome
  const [pesquisaNome, setPesquisaNome] = useState('');
  useEffect(() => {
    const resultados = listaUsuarios.filter((usuario) =>
      usuario.name.toLowerCase().includes(pesquisaNome.toLowerCase())
    );
    setListaUsuarioTabela(resultados);
  }, [pesquisaNome, listaUsuarios]);

  //Buscar Pacientes pelo cpf
  const [pesquisaCpf, setPesquisaCpf] = useState('');
  useEffect(() => {
    const resultados = listaUsuarios.filter((usuario) =>
      usuario.cpf.includes(pesquisaCpf)
    );
    setListaUsuarioTabela(resultados);
  }, [pesquisaCpf, listaUsuarios]);

  //Listar consultas
  const [mostrarAtivos, setMostrarAtivos] = useState(true);
  const [mostrarInativos, setMostrarInativos] = useState(false);

  //dados para o modal

  const [idUsuario, setIdUsuario] = useState();

  const titulos = [
    { nome: 'Tipo de Usuários' },
    { nome: 'Nome' },
    { nome: 'CPF' },
    { nome: 'Telefone' },
    { nome: 'Opções' },
  ];

  function exibirAtivos() {
    setMostrarAtivos(true);
    setMostrarInativos(false);
    setStatus(1);
    setPage(0);
  }

  function exibirInativos() {
    setMostrarAtivos(false);
    setMostrarInativos(true);
    setStatus(0);
    setPage(0);
  }

  return (
    <div className="ContainerClinicaUsuarios">
      <StatusUsuario
        abrirModal={abrirModal}
        setAbrirModal={setAbrirModal}
        idUsuario={idUsuario}
        statusAtual={status}
      />
      <ModalExcluirUsuario
        abrirModal={abrirModalExcluir}
        setAbrirModal={setAbrirModalExcluir}
        tipoUser={tipoUsuario}
      />
      <ModalNovoVinculo
        abrirDados={abrirModalNovoVinculo}
        setAbrirDados={setAbrirModalNovoVinculo}
      />
      <Menu />
      <div className="container-usuarios">
        <div className="header-usuario">
          <h1>Seus Usuários, {fantasyName ? fantasyName : userName}.</h1>
          <div className="Perfil">
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de  Perfil" />
            </div>
          </div>
        </div>
        <nav>
          <div className="navegacaoUsuarios">
            <h2>{'Usuários'}</h2>
            <h5>
              <FiChevronsRight id="Icone" /> {'Busca & Visão geral *'}
            </h5>
            <Ajuda
              mensagem={
                'Nesta tela é possivel visualizar todos os usuários do sistema e bloquear seu acesso.'
              }
              width={'40rem'}
              backColor={'var(--cor-branco)'}
              fontColor={'var(--cor-azul)'}
              margin={'0 0 0 1.5rem'}
              border={'0.5rem'}
              size={'2.5rem'}
            />
          </div>
        </nav>

        <section>
          <div className="Pesquisa">
            <div className="pesquisaNome">
              <input
                value={pesquisaNome}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por nome do usuário"
                onChange={(e) => setPesquisaNome(e.target.value)}
              />
              <label className="labelPesquisa">Nome do Usuários</label>
            </div>
            <div className="pesquisaCpf">
              <input
                value={pesquisaCpf}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por CPF do usuário"
                onChange={(e) => setPesquisaCpf(cpfMascara(e.target.value))}
              />
              <label className="labelPesquisa">CPF</label>
            </div>
          </div>

          <div className="navTabela">
            <div className="botoesFiltro">
              <button
                onClick={exibirAtivos}
                className={`botaoAtivo ${mostrarAtivos ? '' : 'inativo'}`}
              >
                <span>Ativos</span> <FiCheck />
              </button>
              <button
                onClick={exibirInativos}
                className={`botaoInativo ${mostrarInativos ? '' : 'inativo'}`}
              >
                <span>Inativos</span> <FiSlash />
              </button>
              <button
                onClick={() => {
                  setAbrirModalNovoVinculo(true);
                }}
                className={'botaoInativo'}
                id="NovoVinculo"
              >
                <span>Novo Vinculo</span> <FiUserPlus />
              </button>
            </div>
            <div className="tabelaUsuarios">
              {mostrarAtivos ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        {titulos.map((titulo) => (
                          <th key={titulo.nome}>{titulo.nome}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {listaUsuariosTabela.length === 0 ? (
                        <tr>
                          <td>Não há usuários ativos.</td>
                        </tr>
                      ) : (
                        listaUsuariosTabela.map((info) => (
                          <tr key={info.user_id}>
                            <td>{info.occupation}</td>
                            <td>{info.name}</td>
                            <td>
                              {removeNonNumeric(info.cpf).replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                '$1.$2.$3-$4'
                              )}
                            </td>
                            <td>
                              {removeNonNumeric(info.phone).length < 10
                                ? 'Não informado'
                                : removeNonNumeric(info.phone).replace(
                                    /(\d{2})(\d{4})(\d{4})/,
                                    '($1) $2-$3'
                                  ) ||
                                  removeNonNumeric(info.phone).replace(
                                    /(\d{2})(\d{5})(\d{4})/,
                                    '($1) $2-$3'
                                  )}
                            </td>
                            <td className="opcaoEditExcluir">
                              <button
                                onClick={() => {
                                  setAbrirModal(true);
                                  setIdUsuario(info.user_id);
                                  setStatus(info.status);
                                }}
                                className="alterar-status"
                              >
                                <FiEdit color="#1070a1" />
                              </button>
                              <button
                                onClick={() => {
                                  setAbrirModalExcluir(true);
                                  localStorage.setItem('idExcluir', info.id);
                                  setTipoUsuario(info.occupation);
                                }}
                                className="alterar-status"
                              >
                                <FiTrash2 color="#1070a1" size="20" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  <Paginacao
                    consultasPorPagina={limitPage}
                    totalConsulta={total}
                    setNextReq={setNextReq}
                    setPage={setPage}
                  />
                </>
              ) : (
                ''
              )}
              {mostrarInativos ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        {titulos.map((titulo) => (
                          <th key={titulo.nome}>{titulo.nome}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {listaUsuariosTabela.length === 0 ? (
                        <tr>
                          <td>Não há usuários inativos.</td>
                        </tr>
                      ) : (
                        listaUsuariosTabela.map((info) => (
                          <tr key={info.user_id}>
                            <td>{info.occupation}</td>
                            <td>{info.name}</td>
                            <td>{info.cpf}</td>
                            <td>{info.phone}</td>
                            <td id="opcaoEditExcluir">
                              <button
                                onClick={() => {
                                  setAbrirModal(true);
                                  setIdUsuario(info.user_id);
                                  setStatus(info.status);
                                }}
                                className="alterar-status"
                              >
                                <FiEdit color="#1070a1" />
                              </button>
                              <p>/</p>
                              <button
                                onClick={() => {
                                  setAbrirModalExcluir(true);
                                  setIdUsuario(info.user_id);
                                  setTipoUsuario(info.occupation);
                                }}
                                className="alterar-status"
                              >
                                <FiTrash2 color="#1070a1" size="20"></FiTrash2>
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  <Paginacao
                    consultasPorPagina={limitPage}
                    totalConsulta={total}
                    setNextReq={setNextReq}
                    setPage={setPage}
                  />
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default UsuariosClinica;
