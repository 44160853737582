/*
Data de criação: 02/09/2020;
Autor: Caio Rafael;
Descrição: Página de alteração de senha da aplicação, responsável por possibilitar ao admim
alterar a senha da clinica;
*/

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";

import Logo from "../../../Arquivos/higia.svg";
import FundoClinica from "../../../Arquivos/fundoClinica.jpg";
import swal from "sweetalert";
import api from "../../../Servicos/api";

import "./estilos.css";

export default function AlterarSenhaClinica() {
  const [password, setPassword] = useState();
  const [confirmationPassword, setConfirmationPassword] = useState();

  const history = useHistory();

  const email = localStorage.getItem("email_senha");
  const key = localStorage.getItem("token_recSenha");

  async function EnviarRecuperacao(e) {
    e.preventDefault();

    const data = {
      email,
      key,
      password,
      confirmationPassword,
    };
    try {
      const response = await api.post("resetPassword", data);
      swal(response.data.message, "", "success");
      history.push("/");
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  return (
    <div className="ContainerAlterar">
      <div className="ContainerAlterarSenha">
        <Link to="/clinica/login" className="VoltarPagina">
          <FiArrowLeft size={25} className="IconeVoltar" /> Voltar
        </Link>
        <img src={Logo} alt="Logo MobileHealth" className="logo" />
        <h1>Alterar senha</h1>
        <p>Preencha os dados abaixo para efeturar a troca de senha.</p>
        <form onSubmit={EnviarRecuperacao}>
          <input
            type="password"
            className="input-alterar-senha"
            placeholder="Nova senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            className="input-alterar-senha"
            placeholder="Confirmar nova senha"
            value={confirmationPassword}
            onChange={(e) => setConfirmationPassword(e.target.value)}
            required
          />
          <button type="submit" className="botao-grande">
            Enviar
          </button>
        </form>
      </div>

      <div className="ContainerImagemFundo">
        <img src={FundoClinica} alt="" />
      </div>
    </div>
  );
}
