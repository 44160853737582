import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../Servicos/api";

import { FiCalendar, FiArrowRight } from "react-icons/fi";

import "./estilos.css";

export default function CartaoConsulta({
  nome,
  sobrenome,
  especializacao,
  data,
  doctorId,
}) {
  function dataBR(date) {
    return date.slice(0, 10).split("-").reverse().join("/");
  }

  const token = localStorage.getItem("token");
  const [doctorPhoto, setDoctorPhoto] = useState("");

  useEffect(() => {
    api
      .get(`/patient/viewConsultation/doctor/${doctorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDoctorPhoto(response.data.person.user[0].photo);
      });
  }, [doctorId, token]);

  return (
    <div className="ConsultaCartao">
      <div className="ConsultaCartaoIdentificacao">
        <div className="Foto">
          <img src={doctorPhoto} alt="Foto do Paciente" />
        </div>
        <div className="Identificacao">
          <h3>
            {nome} {sobrenome}
          </h3>
          <span>{especializacao}</span>
        </div>
      </div>
      <Link className="ConsultaCartaoDescricao" to="/paciente/consultas">
        <FiCalendar className="Icone" />
        <p>
          {dataBR(data)} às {data.slice(11, 16)}h
        </p>
        <FiArrowRight className="Icone" />
      </Link>
    </div>
  );
}
