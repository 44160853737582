import React, { useState, useEffect } from 'react';
import useDebounce from '../../../Servicos/useDebounce';
import { Link } from 'react-router-dom';
import { FiBell } from 'react-icons/fi';
import AnimacaoCarregando from '../../../Componentes/AnimacaoCarregando/indice';
import Menu from '../../../Componentes/GlobalMenu/indice';
import Table from '../../../Componentes/Tables/TabelaAtendente';
import Paginacao from '../../../Componentes/Paginacao/indice';
import api from '../../../Servicos/api';
import foto from '../../../Arquivos/avatarPadrao.svg';
import Form from '../../../Componentes/Atendente/Modal-Cadastro-Empregado/indice';
import Ajuda from '../../../Componentes/Geral/Ajuda/Ajuda';

import './estilos.css';

export default function AtendentesClinica() {
  //Dados Clínica
  const nomeFantasia = localStorage.getItem('fantasy_name');
  const nomeUsuario = localStorage.getItem('name_first');
  const token = localStorage.getItem('token');

  //Listagem de Atendentes
  const [atendentesLista, setAtendentesLista] = useState([]);
  const [atendentesListaTabela, setAtendentesListaTabela] = useState([]);
  const [AbrirDados, setAbrirDados] = useState(false);

  const limitPage = 7;
  const [page, setPage] = useState(0);
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    api
      .get(`/searchEmployee/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data ? response.data : null;
        //console.log(data[0].privilege);
        //Para pegar o id da tebela employee
        //console.log(data[0].person.employee[0].id);
        if(data != null){
          const resultados = data.filter(
            atendente => atendente.privilege === 'MHAT'
          );
          console.log(resultados.length);
          setAtendentesLista(resultados);
          setAtendentesListaTabela(resultados);
          setDataLength(resultados.length);
        }
      });
  }, [token, AbrirDados, page]);

  //Buscar Atendente pelo nome
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      api
        .get(`/searchAndFilterEmployee?search=${debouncedSearchTerm}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const filteredEmployees = response.data.filter(
            (employee) => employee.privilege === 'MHAT'
          );
          //console.log(filteredEmployees);
          setAtendentesListaTabela(filteredEmployees);
          setDataLength(filteredEmployees.length);
          setLoading(false);
        });
    }
  }, [debouncedSearchTerm, token]);

  useEffect(() => {
    if (searchValue === '') {
      setAtendentesListaTabela(atendentesLista);
    }
  }, [searchValue, atendentesLista]);

  //Título Tabela
  const titulos = [
    { nome: 'Nome' },
    { nome: 'CPF' },
    { nome: 'Telefone' },
    { nome: 'Idade' },
    { nome: 'Opções' },
  ];

  return (
    <div className="ContainerClinicaAtendente">
      <Menu />
      <div className="Container-conteudo-atendente">
        <header>
          <h1>Bem-vindo, {nomeFantasia ? nomeFantasia : nomeUsuario}.</h1>
          <div className="Perfil">
            <Link to="">
              <FiBell className="Notificacao" />
            </Link>
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de Perfil" />
            </div>
          </div>
        </header>
        <nav>
          <div className="navegacao">
            <h2>{'Atendente  '}</h2>
            <h5>
              {'>>'} {'Busca & Visão geral *'}
            </h5>
            <Ajuda
              mensagem={
                'Nesta tela é possivel gerenciar os atendentes da sua clínica, possibilitando cadastrar, atualizar, visualizar e remover.'
              }
              width={'40rem'}
              backColor={'var(--cor-branco)'}
              fontColor={'var(--cor-azul)'}
              margin={'0 0 0 1.5rem'}
              border={'0.5rem'}
              size={'2.5rem'}
            />
          </div>
        </nav>
        <section>
          <div className="pesquisa">
            <div className="box-pesquisa">
              <input
                value={searchValue}
                type="text"
                className="nome-input"
                placeholder="Busca por nome do atendente"
                onChange={(e) =>
                  setSearchValue(e.target.value.replace(/[^a-zA-Z ]/g, ''))
                }
                onKeyPressCapture={(e) =>
                  setSearchValue(e.target.value.replace(/[^a-zA-Z ]/g, ''))
                }
              />
              <label className="label-input">Pesquisa</label>
            </div>
          </div>
          <Link
            to="#"
            className="botao-grande cadastro"
            onClick={() => setAbrirDados(true)}
          >
            Cadastrar Atendente
          </Link>

          <Form abrirDados={AbrirDados} setAbrirDados={setAbrirDados} />
          {loading ? (
            <AnimacaoCarregando color="#1070a1" />
          ) : (
            <>
              <Table
                titles={titulos}
                tableItems={atendentesListaTabela}
                route="/clinica/empregado/perfil"
              />
              {atendentesListaTabela && (
                <Paginacao
                  limitPage={limitPage}
                  dataLength={dataLength}
                  setPage={setPage}
                />
              )}
            </>
          )}
        </section>
      </div>
    </div>
  );
}
