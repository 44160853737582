import React, { useState, useEffect } from "react";
import api from "../../../Servicos/api";

import styles from "./HistoryModal.module.css";

function PlanHistoryModal({ abrirModal, setAbrirModal, planId }) {
  const token = localStorage.getItem("token");
  const [fullHistory, setFullHistory] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);

  useEffect(() => {
    if (planId) {
      api
        .get(`/insurancePaymentHistory/${planId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then((response) => {
          const data = response.data ? response.data : null;
          if (data !== null) {
            setFullHistory(data.allInsurancePaymentHistory);
            setInsuranceData(data.healthInsurance);
          }
        });
    }
  }, [planId, token]);

  const titulos = [
    { nome: "Nome" },
    { nome: "Data de pagamento" },
    { nome: "Valor" }
  ];


  let monthIndex = [];
  const months = [
    { name: "Jan" }, { name: "Fev" }, { name: "Mar" }, { name: "Abr" }, { name: "Mai" }, { name: "Jun" }, { name: "Jul" }, { name: "Ago" }, { name: "Set" }, { name: "Out" }, { name: "Nov" }, { name: "Dez" }
  ];

  fullHistory.forEach((e) => {
    monthIndex.push(e.payday.slice(5, 7));
  });

  if (abrirModal === true) {
    return (
      <div className={styles.SobreposicaoHistorico}>
        <section className={styles.ModalHistorico}>
          <header>
            <div className={styles.topHeader}>
              <h1 className={styles.screenTitle}>Histórico de pagamentos</h1>
              <button
                onClick={() => {
                  setAbrirModal(false);
                }}
              >
                X
              </button>
            </div>
            <div className={styles.planAndProvider}>
              <h2>{insuranceData.identification_name}</h2>
              <h3>{insuranceData.provider}</h3>
            </div>
          </header>
          <main className={styles.historyContainer}>
            <table className={styles.historyContainer}>
              <thead>
                <tr>
                  {titulos.map((titulo) => (
                    <th key={titulo.nome}>{titulo.nome}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {fullHistory.length === 0 ? (
                  <tr>
                    <td>Não há histórico a ser exibido.</td>
                  </tr>
                ) : (
                  fullHistory.map((info, index) => (
                    <tr key={info.id}>
                      <td> {`${insuranceData.identification_name} - ${info.payday.slice(8, 10)} ${months[parseInt(monthIndex[index]) - 1].name} ${info.payday.slice(0, 4)}`}</td>
                      <td>
                        {`${info.payday.slice(8, 10)} ${months[parseInt(monthIndex[index]) - 1].name} ${info.payday.slice(0, 4)}`}
                      </td>
                      <td>{`R$ ${info.payment.toFixed(2)}`}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </main>
        </section>
      </div>
    );
  }
  return null;
}

export default PlanHistoryModal;