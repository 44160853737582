import React, { useState, useEffect } from "react";
import { Link ,useHistory } from "react-router-dom";
import axios from "axios";

//import photo from "../../../Arquivos/avatarPadrao.svg";
import api from "../../../Servicos/api";
import swal from "sweetalert";
import { cpfMascara } from "../../../Servicos/Mascaras/cpfMascara";
import { telefoneMascara } from "../../../Servicos/Mascaras/telefoneMascara";

import "./estilos.css";
import { cepMask } from "../../../Servicos/Mascaras/cepMask";

var erroForm = false;

export default function SectionForm({ setAbrirDados }) {
  const token = localStorage.getItem("token");

  //const id_clinica = localStorage.getItem("id_clinica");
  const history = useHistory();

  //Informações Gerais
  const [cpf, setCpf] = useState("");
  //const rg = 0;
  const [name_first, setName_first] = useState("");
  const [name_last, setName_last] = useState("");
  const [gender, setGender] = useState(null);
  const [data_birth, setData_birth] = useState(null);
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [valueCep, setCEP] = useState("");
  const [street, setStreet] = useState("");
  const [housign_type, setHousign_type] = useState("");
  const [valueNumber, setNumber] = useState();

  //Autenticacao
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  //API IBGE
  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);

  //Constantes de Controle
  const [desativa, setDesativa] = useState("");
  const [modoAvancar, setAvancar] = useState("");
  const [modoAvancar2, setAvancar2] = useState("");

  //Diretor Geral e Atendente
  const [occupation, setOccupation] = useState("");
  const [workload, setWorkload] = useState("");

  //Específicas Paciente
  const [race, setRace] = useState("");

  const [privilegios, setPrivilegios] = useState([]);

  // function getPrivilegios() {
  //   if (id_clinica === "1")
  //     setPrivilegios([
  //       { privilegio: "MHAT", nome: "Atendente" },
  //       { privilegio: "MHDG", nome: "Diretor Geral" },
  //       { privilegio: "MHME", nome: "Médico" },
  //       { privilegio: "MHPA", nome: "Paciente" },
  //     ]);
  //   else
  //     setPrivilegios([
  //       { privilegio: "MHAT", nome: "Atendente" },
  //       { privilegio: "MHME", nome: "Equipe Médica" },
  //       { privilegio: "MHME", nome: "Terceirizado" },
  //     ]);
  // }

  function getPrivilegios() {
      setPrivilegios([
        { privilegio: "MHAT", nome: "Atendente" },
        { privilegio: "MHDG", nome: "Diretor Geral" },
        { privilegio: "MHME", nome: "Médico" },
        { privilegio: "MHPA", nome: "Paciente" },
      ]);
  }

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!state || state === "0") {
      return setListaCidades([]);
    }

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
      )
      .then((response) => {
        const cidadeNome = response.data.map((cidade) => cidade.nome);

        setListaCidades(cidadeNome);
      })
      .catch((error) => console.log(error));
  }, [state]);

  function verificAvancar() {
    if (
      cpf !== "" &&
      name_first !== "" &&
      name_last !== "" &&
      phone !== "" &&
      data_birth !== "" &&
      gender !== null &&
      race !== null &&
      valueCep !== "" &&
      state !== null &&
      city !== null &&
      state !== null &&
      valueNumber !== "" &&
      street !== "" &&
      erroForm !== true
    ) {
      setAvancar2(true);
      setAvancar(false);
      setDesativa("desativa");
      getPrivilegios();
    } else {
      swal("Preencha os campos corretamente", "Para prosseguir", "error");
    }
    return false;
  }

  function verificandoEnvio() {
    if (
      occupation !== null &&
      workload !== "" &&
      race !== null &&
      email !== "" &&
      username !== "" &&
      password !== "" &&
      confirmationPassword !== ""
    ) {
      RegistrarAtendente();
    } else {
      swal("Preencha todos os campos", "Para prosseguir", "error");
    }
    return false;
  }

  useEffect(() => {
    if (valueCep.length === 9) {
      searchCep(valueCep);
    }
  }, [valueCep]);

  async function searchCep(cepPesquisa) {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${cepPesquisa}/json/`
      );
      setCity(response.data.localidade);
      setState(response.data.uf);
      setCEP(cepPesquisa);
    } catch (erro) {
      swal(erro, "Digite novamente", "error");
    }
  }


  async function RegistrarAtendente() {
    let cep = parseInt(valueCep);
    let number = parseInt(valueNumber);
    const data = {
      workload,
      username,
      email,
      password,
      confirmationPassword,
      race,
      data_birth,
      name_first,
      name_last,
      cpf,
      phone,
      occupation,
      gender,
      address: {
        state,
        city,
        cep,
        number,
        street,
        housign_type,
      },
    };

    try {
      await api.post("employee", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Cadastrado com Sucesso", "", "success");
      history.push("/clinica/inicio");
    } catch (err) {
      swal(err, "Digite novamente", "error");
    }
  }

  return (
    <div id="form" className="form-registro-atendente">
      <div
        className={`grupo-button-form ${desativa}`}
        style={{ display: modoAvancar ? "none" : "block" }}
      >
        <div className="numIndic">
          <p> Página 1/2</p>
        </div>
        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="000.000.000-00"
            value={cpfMascara(cpf)}
            type="text"
            setValue={setCpf}
            maxlength={14}
            id="cpf"
            label="CPF"
            name="cpf"
          />
        </div>

        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="Ex. João"
            value={name_first}
            type="text"
            setValue={setName_first}
            id="Name"
            label="Name"
            name="Name"
          />

          <Input
            className="input-nome"
            placeholder="Ex. Silva"
            value={name_last}
            type="text"
            setValue={setName_last}
            id="Sobrenome"
            label="Sobrenome"
            name="Sobrenome"
          />
        </div>
        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="(99)9.9999-9999"
            value={telefoneMascara(phone)}
            type="text"
            setValue={setPhone}
            id="Telefone"
            label="Telefone"
            name="Telefone"
            maxlength={15}
          />
          <input
            type="date"
            className="input-nome"
            value={data_birth}
            onChange={(e) => setData_birth(e.target.value)}
            required
          />

          <select
            placeholder="Etnia"
            value={gender}
            onChange={(e) => setGender(parseInt(e.target.value))}
            required
          >
            <option value="">-- Sexo --</option>
            <option value="1">Masculino</option>
            <option value="2">Feminino</option>
            <option value="3">Outro</option>
          </select>
        </div>

        <div className="grupo-cepUFCidade">
          <Input
            type="text"
            className="input-nome"
            placeholder="55555-555"
            value={cepMask(valueCep)}
            maxlength={9}
            setValue={setCEP}
            id="CEP"
            label="CEP"
            name="CEP"
          />

          <select
            placeholder="UF"
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
          >
            <option value="">-- Selecione o estado --</option>
            {listaEstados.map((uf) => (
              <option key={uf} value={uf}>
                {uf}
              </option>
            ))}
          </select>
          <select
            placeholder="Cidade"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          >
            <option value="">-- Cidade --</option>
            {listaCidades.map((listaCidade) => (
              <option key={listaCidade} value={listaCidade}>
                {listaCidade}
              </option>
            ))}
          </select>
        </div>
        <div className="grupo-escolaridade-genero">
          <Input
            type="text"
            className="input-nome"
            placeholder="Rua Tabelião Aoem Menescal"
            value={street}
            setValue={setStreet}
            id="Rua"
            label="Rua"
            name="Rua"
          />
        </div>
        <div className="grupo-bairro-numero">
          <Input
            className="input-nome"
            type="text"
            placeholder="Complemento"
            value={housign_type ? housign_type : ""}
            setValue={setHousign_type}
            id="Complemento"
            label="Complemento"
            name="Complemento"
          />

          <Input
            type="number"
            className="input-nome"
            placeholder="Ex. 30"
            value={valueNumber}
            setValue={setNumber}
            id="Número"
            label="Número"
            name="Número"
          />
        </div>

        <Link
          className="botao-grande"
          to="#"
          style={{
            display: modoAvancar ? "none" : "flex",
          }}
          onClick={() => {
            verificAvancar();
          }}
        >
          Avançar
        </Link>
      </div>

      <div
        className="grupo-cadastrar"
        style={{ display: modoAvancar2 ? "block" : "none" }}
      >
        <div className="numIndic">
          <p>Página 2/2</p>
        </div>

        <div className="grupo-nome">
          <select
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
            className="select-grupo-nome"
            required
          >
            <option value="">-- Selecione --</option>
            {privilegios.map((privilegio) => (
              <option key={privilegio.privilegio} value={privilegio.nome}>
                {privilegio.nome}
              </option>
            ))}
          </select>
          <Input
            type="text"
            className="input-nome"
            placeholder="Carga horária"
            value={workload}
            setValue={setWorkload}
            id="workload"
            label="Carga horária"
            name="workload"
          />
        </div>

        <select
          placeholder="Etnia"
          value={race}
          onChange={(e) => setRace(parseInt(e.target.value))}
          required
        >
          <option value="">-- Etnia --</option>
          <option value="1">Amarela</option>
          <option value="2">Branca</option>
          <option value="3">Negra</option>
          <option value="4">Pardo</option>
        </select>

        <div className="grupo-nome">
          <Input
            type="text"
            className="input-nome"
            placeholder="nome@gmail.com"
            value={email}
            setValue={setEmail}
            id="Email"
            label="Email"
            name="Email"
          />

          <Input
            type="text"
            className="input-nome"
            placeholder="usuario123"
            value={username}
            setValue={setUsername}
            id="Username"
            label="Username"
            name="Username"
          />
        </div>

        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="*********"
            value={password}
            type="password"
            setValue={setPassword}
            id="Password"
            label="Senha"
            name="Password"
          />

          <Input
            className="input-nome"
            placeholder="*********"
            value={confirmationPassword}
            type="password"
            setValue={setConfirmationPassword}
            id="Confirmar Senha"
            label="Confirmar Senha"
            name="Confirmar Senha"
          />
        </div>

        <div className="botoes-form">
          <Link
            to="#"
            className="botao-grande"
            style={{ background: "var(--cor-cinza)" }}
            onClick={() => {
              setDesativa("");
              setAvancar2(false);
            }}
          >
            Voltar
          </Link>
          <button
            type="button"
            className="botao-grande cadastrar"
            onClick={() => verificandoEnvio()}
          >
            Cadastrar
          </button>
        </div>
      </div>
    </div>
  );
}

function Input({ 
  id, 
  label, 
  value, 
  setValue, 
  type, 
  placeholder, 
  maxlength }) {

  const className = document.getElementById(`box-${id}`);
  var verifyInt = /\d+/g;
  var verifyEmail = /\S+@\S+\.+com/;

  function ModificaValor(e) {
    switch (e.target.id) {
      case 'cpf':
        if(e.target.value === '000.000.000-00'){
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        else{
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }
        break;

      case 'rg':
        if(e.target.value === '0000000000'){
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        else{
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }
        break;

      case 'Name':
        if(e.target.value.match(verifyInt) !== null){
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        else{
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }
        break;

      case 'Sobrenome':
        if(e.target.value.match(verifyInt) !== null){
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        else{
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }
        break;

      case 'Email':
        if(verifyEmail.test(e.target.value)){
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }else {
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        break;

      default:
        setValue(e.target.value);
        break;
    }
  }

  return (
    <div className="conteudo">
      <div id={`box-${id}`} className="meu-box">
        <input
          className="input-nome"
          type={type}
          id={id}
          name={id}
          value={value}
          maxLength={maxlength}
          placeholder={placeholder}
          onChange={(e) => ModificaValor(e)}
          required
        />
        
        {erroForm ?
          <label htmlFor={id} className="label-nome error">
            <span>*{label} inválido</span>
          </label>
        :
          <label htmlFor={id} className="label-nome">
            <span>{label}</span>
          </label>
        }

      </div>
    </div>
  );
}
