import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";

import api from "../../../Servicos/api";
import { cpfMascara } from "../../../Servicos/Mascaras/cpfMascara";
import { telefoneMascara } from "../../../Servicos/Mascaras/telefoneMascara";
import { cepMask } from "../../../Servicos/Mascaras/cepMask";
import axios from "axios";

import "./estilos.css";

var erroForm = false;

export default function Form() {
  const token = localStorage.getItem("token");
  const history = useHistory();

  //Informações Gerais
  const [cpf, setCpf] = useState("");
  const [name_first, setName_first] = useState("");
  const [name_last, setName_last] = useState("");
  const [gender, setGender] = useState("");
  const [data_birth, setData_birth] = useState(null);
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [valueCep, setCEP] = useState("");
  const [street, setStreet] = useState("");
  const [housign_type, setHousign_type] = useState("");
  const [number, setNumber] = useState();

  //Autenticacao
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  //API IBGE
  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);

  const [listaEstadosCRM, setListaEstadosCRM] = useState([]);

  //Constantes de Controle
  const [desativa, setDesativa] = useState("");
  const [modoAvancar, setAvancar] = useState("");
  const [modoAvancar2, setAvancar2] = useState("");

  //Específicas Médico
  const [area_specialization, setArea_specialization] = useState("");
  const [rqe, setRqe] = useState("");
  const [crm, setCrm] = useState("");
  const [ufCRM, setUfCRM] = useState("");

  //especialização
  const [specialization_id, setSpecialization_id] = useState(0);
  const [specialization_name, setSpecialization_name] = useState("");
  const [listaEspecialidade, setListaEspecialidade] = useState([]);
  const [listaEspecialidadeFilter, setListaEspecialidadeFilter] = useState([]);

  var GrupoEspecialidade = [];

  function verificAvancar() {
    if (name_first !== "" && 
        name_last !== "" && 
        cpf !== "" && 
        phone !== "" && 
        state !=="" && 
        gender !== "" &&
        data_birth !== "" &&
        city !== "" &&
        valueCep !== "" &&
        street !== "" &&
        erroForm !== true) {
      setAvancar2(true);
      setAvancar(false);
      setDesativa("desativa");
    } else {
      swal("Preencha os campos corretamente", "Para prosseguir", "error");
    }
    return false;
  }

  useEffect(() => {
    api.get("https://api.memed.com.br/v1/especialidades/").then((response) => {
      const dados = response.data.data.map((especialidade) => especialidade);

      function compare(a, b) {
        if (a.attributes.grupo < b.attributes.grupo) {
          return -1;
        }
        if (a.attributes.grupo > b.attributes.grupo) {
          return 1;
        }
        return 0;
      }

      setListaEspecialidade(dados.sort(compare));
      setListaEspecialidadeFilter(dados.sort(compare));

      //setSpecialization_name(dados.filter((especialidade) => especialidade.attributes.nome.toLowerCase() === area_specialization.toLowerCase())[0].attributes.grupo);
    });
  }, []);

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (valueCep.length === 9) {
      searchCep(valueCep);
    }
  }, [valueCep]);

  async function searchCep(cepPesquisa) {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${cepPesquisa}/json/`
      );
      setCity(response.data.localidade);
      setState(response.data.uf);
      setCEP(cepPesquisa);
    } catch (erro) {
      swal(erro, "Digite novamente", "error");
    }
  }

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstadosCRM(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!state || state === "0") {
      return setListaCidades([]);
    }

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
      )
      .then((response) => {
        const cidadeNome = response.data.map((cidade) => cidade.nome);

        setListaCidades(cidadeNome);
      })
      .catch((error) => console.log(error));
  }, [state]);

  async function RegistrarMedico() {
    let uf = ufCRM;
    let cep = parseInt(valueCep);

    const data = {
      email,
      username,
      password,
      confirmationPassword,
      name_first,
      name_last,
      data_birth,
      uf,
      gender,
      crm,
      rqe,
      phone,
      area_specialization,
      cpf,
      specialization_id,
      address: {
        state,
        city,
        cep,
        number,
        street,
        housign_type,
      },
    };

    //console.table(data);
    try {
      await api.post("doctor", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Cadastrado com Sucesso", "", "success");
      history.push("/clinica/medicos");
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  function setValueGrupoInput() {
    var inputValue = document.getElementById("grupoEspecializacaoId10").value;
    var options = document.getElementById("dataListGrupo10").childNodes;

    for (let i = 0; i < GrupoEspecialidade.length; i++) {
      if (options[i].value === inputValue) {
        const listaFiltrada = [];
        listaEspecialidade.map((especialidade) =>
          especialidade.attributes.grupo.toLowerCase() ===
            GrupoEspecialidade[i].toLowerCase()
            ? listaFiltrada.push(especialidade)
            : GrupoEspecialidade[i]
        );

        setSpecialization_name(inputValue);
        setListaEspecialidadeFilter(listaFiltrada);
        break;
      }
    }
  }

  function setValueEspecializacaoInput() {
    var inputValue = document.getElementById("especializacaoId10").value;
    var options = document.getElementById("dataListEspecializacao10")
      .childNodes;

    for (var i = 0; i < listaEspecialidadeFilter.length; i++) {
      if (options[i].value === inputValue) {
        setSpecialization_id(parseInt(options[i].getAttribute("data-value")));
        setArea_specialization(inputValue);
        setSpecialization_name(
          listaEspecialidade.filter(
            (especialidade) =>
              especialidade.attributes.nome.toLowerCase() ===
              inputValue.toLowerCase()
          )[0].attributes.grupo
        );
        break;
      }
    }
  }

 

  return (
    <div id="form">
      <div
        className={`grupo-button-form-medico ${desativa}`}
        style={{ display: modoAvancar ? "none" : "block" }}
      >
        <div className="numIndic-medico">
          <p> Página 1/2</p>
        </div>
        <Input
          className="input-nome"
          placeholder="000.000.000-00"
          value={cpfMascara(cpf)}
          type="text"
          setValue={setCpf}
          maxlength={14}
          id="cpf"
          label="CPF"
          name="cpf"
          required={true}
        />

        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="Ex. João"
            value={name_first}
            type="text"
            setValue={setName_first}
            id="Nome"
            label="Nome"
            name="Nome"
            required={true}
          />

          <Input
            className="input-nome"
            placeholder="Ex. Silva"
            value={name_last}
            type="text"
            setValue={setName_last}
            id="Sobrenome"
            label="Sobrenome"
            name="Sobrenome"
            required={true}
          />
        </div>
        <div className="grupo-nome">
          <Input
            className="input-nome"
            placeholder="(99)9.9999-9999"
            value={telefoneMascara(phone)}
            type="text"
            setValue={setPhone}
            id="Telefone"
            label="Telefone"
            name="Telefone"
            maxlength={15}
            required={true}
          />

          <input
            type="date"
            className="input-nome"
            value={data_birth}
            onChange={(e) => setData_birth(e.target.value)}
            required
          />

          <select
            placeholder="Etnia"
            value={parseInt(gender)}
            onChange={(e) => setGender(parseInt(e.target.value))}
            required
          >
            <option value="0">-- Sexo --</option>
            <option value="1">Masculino</option>
            <option value="2">Feminino</option>
            <option value="3">Outro</option>
          </select>
        </div>

        <div className="grupo-nome">
          <Input
            type="text"
            className="input-nome"
            placeholder="55555-555"
            value={cepMask(valueCep)}
            maxlength={9}
            setValue={setCEP}
            id="CEP"
            label="CEP"
            name="CEP"
            required={true}
          />

          <select
            placeholder="UF"
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
          >
            <option value="">-- Selecione o estado --</option>
            {listaEstados.map((uf) => (
              <option key={uf} value={uf}>
                {uf}
              </option>
            ))}
          </select>
          <select
            placeholder="Cidade"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          >
            <option value="">-- Cidade --</option>
            {listaCidades.map((listaCidade) => (
              <option key={listaCidade} value={listaCidade}>
                {listaCidade}
              </option>
            ))}
          </select>
        </div>
        <div className="grupo-escolaridade-genero">
          <Input
            type="text"
            className="input-nome"
            placeholder="Rua Tabelião Aoem Menescal"
            value={street}
            setValue={setStreet}
            id="Rua"
            label="Rua"
            name="Rua"
            required={true}
          />
        </div>
        <div className="grupo-bairro-numero">
          <Input
            className="input-nome"
            type="text"
            placeholder="Complemento"
            value={housign_type}
            setValue={setHousign_type}
            id="Complemento"
            label="Complemento"
            name="Complemento"
            required={false}
          />

          <Input
            type="number"
            className="input-nome"
            placeholder="Ex. 30"
            value={number}
            setValue={setNumber}
            id="Número"
            label="Número"
            name="Número"
            valueint={true}
            required={true}
          />
        </div>
        <Link
          className="botao-grande"
          to="#"
          style={{
            display: modoAvancar ? "none" : "flex",
          }}
          onClick={() => {
            verificAvancar();
          }}
        >
          Avançar
        </Link>
      </div>

      <div
        className="grupo-cadastrar"
        style={{ display: modoAvancar2 ? "block" : "none" }}
      >
        <div className="numIndic-medico">
          <p>Página 2/2</p>
        </div>
        <div className="grupo-especializacao">
          <input
            id="grupoEspecializacaoId10"
            list="dataListGrupo10"
            value={specialization_name}
            placeholder="-- Selecione um Grupo de Especialização --"
            autoComplete="off"
            onClick={(e) => {
              e.target.value = "";
              document.getElementById("especializacaoId10").value = "";
              setListaEspecialidadeFilter(listaEspecialidade);
              setArea_specialization("");
            }}
            onChange={(e) => {
              setValueGrupoInput();
            }}
          />

          <datalist id="dataListGrupo10">
            {listaEspecialidade
              .filter((especialidade) => {
                if (
                  GrupoEspecialidade.indexOf(
                    especialidade.attributes.grupo.toLowerCase()
                  ) === -1
                ) {
                  GrupoEspecialidade.push(
                    especialidade.attributes.grupo.toLowerCase()
                  );
                  return especialidade.attributes.grupo;
                } else {
                  return null;
                }
              })
              .map((grupo) => (
                <option
                  key={grupo.attributes.grupo}
                  data-value={grupo.id}
                  value={grupo.attributes.grupo}
                >
                  {grupo.attributes.grupo === "Null"
                    ? "Outros"
                    : grupo.attributes.grupo}
                </option>
              ))}
          </datalist>

          <input
            id="especializacaoId10"
            value={area_specialization}
            list="dataListEspecializacao10"
            placeholder="-- Selecione uma Especialização --"
            autoComplete="off"
            onClick={(e) => {
              e.target.value = "";
            }}
            onChange={(e) => {
              setValueEspecializacaoInput();
            }}
            required
          />
          <datalist id="dataListEspecializacao10">
            {listaEspecialidadeFilter.map((especialidade) => (
              <option
                key={especialidade.id}
                data-value={especialidade.id}
                value={especialidade.attributes.nome}
              >
                {especialidade.attributes.nome}
              </option>
            ))}
          </datalist>

          <Input
            className="input-nome"
            placeholder="RQE"
            value={rqe}
            type="number"
            setValue={setRqe}
            id="RQE"
            label="RQE"
            name="RQE"
            required={true}
          />
        </div>
        <div className="CRM_RQE">
          <Input
            className="input-nome"
            placeholder="CRM, CRP ou CRO"
            value={crm}
            type="number"
            setValue={setCrm}
            id="CRM"
            label="CRM"
            name="CRM"
            valueint={true}
            required={true}
          />
          <div className="conteudo">
            <div className="meu-box">
              <select
                placeholder="UF"
                onChange={(e) => setUfCRM(e.target.value)}
                select={ufCRM}
                required
              >
                <option>-- Selecione o estado --</option>
                {listaEstadosCRM.map((listaEstadoCRM) => (
                  <option key={listaEstadoCRM} value={listaEstadoCRM}>
                    {listaEstadoCRM}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="grupo-flex-registro">
          <Input
            type="text"
            className="input-nome"
            placeholder="nome@gmail.com"
            value={email}
            setValue={setEmail}
            id="Email"
            label="Email"
            name="Email"
            required={true}
          />

          <Input
            type="text"
            className="input-nome"
            placeholder="usuario123"
            value={username}
            setValue={setUsername}
            id="Username"
            label="Username"
            name="Username"
            required={true}
          />
        </div>
        <div className="grupo-flex-registro">
          <Input
            className="input-nome"
            placeholder="*********"
            value={password}
            type="password"
            setValue={setPassword}
            id="Password"
            label="Senha"
            name="Password"
            required={true}
          />

          <Input
            className="input-nome"
            placeholder="*********"
            value={confirmationPassword}
            type="password"
            setValue={setConfirmationPassword}
            id="Confirmar Senha"
            label="Confirmar Senha"
            name="Confirmar Senha"
            required={true}
          />
        </div>
        <div className="botoes-form">
          <Link
            to="#"
            className="botao-grande voltar"
            onClick={() => {
              setDesativa("");
              setAvancar2(false);
            }}
          >
            Voltar
          </Link>
          <button
            type="button"
            className="botao-grande cadastrar"
            onClick={() => RegistrarMedico()}
          >
            Cadastrar
          </button>
        </div>
      </div>
    </div>
  );
}

function Input({
  id,
  label,
  value,
  setValue,
  type,
  placeholder,
  estado,
  occupation,
  maxlength,
  valueint,
  required,
}) {

  const className = document.getElementById(`box-${id}`);
  var verifyInt = /\d+/g;
  var verifyEmail = /\S+@\S+\.+com/;

  function ModificaValor(e) {
    switch (e.target.id) {
      case 'cpf':
        if(e.target.value === '000.000.000-00' || (e.target.value.length < 14)){
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        else{
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }
        break;

      case 'Nome':
        if(e.target.value.match(verifyInt) !== null){
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        else{
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }
        break;

      case 'Sobrenome':
        if(e.target.value.match(verifyInt) !== null){
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        else{
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }
        break;

      case 'Email':
        if(verifyEmail.test(e.target.value)){
          className.className = 'meu-box';
          setValue(e.target.value);
          erroForm = false;
        }else {
          setValue(e.target.value);
          className.className += ' erro';
          erroForm = true;
        }
        break;

      default:
        setValue(e.target.value);
        break;
    }
  }

  function ModificaValorInt(e) {
    setValue(parseInt(e.target.value));
  }

  const isRequired = required ? "required" : "";

  return (
    <div className={`conteudo ${occupation}`}>
      <div id={`box-${id}`} className='meu-box'>
        <input
          className="input-nome"
          type={type}
          id={id}
          name={id}
          value={value}
          placeholder={placeholder}
          onChange={valueint ? ModificaValorInt : ModificaValor}
          disabled={estado}
          maxLength={maxlength}
          required={isRequired}
        />

        {erroForm ?
          <label htmlFor={id} className="label-nome error">
            <span>*{label} inválido</span>
          </label>
        :
          <label htmlFor={id} className="label-nome">
            <span>{label}</span>
          </label>
        }
      
      </div>
    </div>
  );
}
