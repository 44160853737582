import React from "react";
import { Link } from "react-router-dom";

import { FiArrowRight } from "react-icons/fi";

import "./estilos.css";

export default function ConsultasRecentes({ consultas }) {

  return (
    <div className="ConsultasListas">
      <ul>
        {consultas.map((consulta) => (
          <li key={consulta.id}>
            <div className="Foto">
              <img src={consulta.doctor.person.user[0].photo} alt="Foto do Médico" />
            </div>
            <div className="Identificacao">
              <h3>
                {consulta.doctor.person.name_first}{" "}
                {consulta.doctor.person.name_last}
              </h3>
              <span>{consulta.doctor.area_specialization}</span>
            </div>
          </li>
        ))}
      </ul>
      <Link to="/paciente" className="VerMaisConsultas">
        <span>Visualizar mais consultas</span>
        <FiArrowRight className="Icone" />
      </Link>
    </div>
  );
}
