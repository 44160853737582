import React from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import api from "../../../Servicos/api";
import { FiAlertTriangle } from "react-icons/fi";

import "./estilos.css";

function ModalExcluirAnotacao({
    abrirModalExcluir,
    setAbrirModalExcluir,
    idAnotacao,
}) {
    const token = localStorage.getItem("token");
    const history = useHistory();

    async function excluirAnotacao() {
        try {
            await api.delete(`/annotation/${idAnotacao}`, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
            });
            swal("Removido com Sucesso", "", "success");
            setAbrirModalExcluir(false);
            history.push("/atendente");
            history.push("/atendente/inicio");
    
        } catch (err) {
            swal(err.response.data.error, "Tente novamente", "error");
            setAbrirModalExcluir(false);
        }
    }

    if (abrirModalExcluir === true) {
        return (
            <div className="SobreposicaoExcluirAnotacao">
                <section className="ModalExcluirAnotacao">
                    <header>
                        <h2>Deseja realmente remover a anotação?</h2>
                    </header>
                    <FiAlertTriangle
                        style={{ marginTop: "30px" }}
                        size="100"
                        color="#ec1b21"
                    />
                    <main>
                        <Link
                            to="#"
                            className="botao-grande"
                            style={{ backgroundColor: "#ec1b21" }}
                            onClick={() => excluirAnotacao()}
                        >
                            Sim
                        </Link>
                        <Link
                            to="#"
                            className="botao-grande"
                            style={{ backgroundColor: "var(--cor-cinza)" }}
                            onClick={() => setAbrirModalExcluir(false)}
                        >
                            Não
                        </Link>
                    </main>
                </section>
            </div>
        );
    }
    return null;
}

export default ModalExcluirAnotacao;
