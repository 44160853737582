import React from "react";

import "./estilos.css";

import { AiOutlineWhatsApp } from "react-icons/ai";

export default function Tabela({ titulos, lista}) {
  return (
    <div className="TabelaComponente">
      <table>
        <thead>
          <tr>
            {titulos.map((titulo) => (
              <th key={titulo.nome}>{titulo.nome}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {lista.map((dado) => (
            <tr key={dado.id}>
              <td>{dado.patient.person.name_first} {dado.patient.person.name_last}</td>
              <td>{dado.doctor.person.name_first} {dado.doctor.person.name_last}</td>
              <td>
                {dado.type === 1
                  ? "Consulta - 1 vez"
                  : dado.type === 2
                  ? "Consulta - Rotina"
                  : dado.type === 3
                  ? "Consulta - Retorno"
                  : "Exames"}
              </td>
              <td>{dado.date}</td>
              <td>{dado.start_time}</td>
              <td><AiOutlineWhatsApp/></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
