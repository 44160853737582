import React, { useState } from "react";
import swal from "sweetalert";
import api from "../../../Servicos/api";

import "./estilos.css";

function StatusUsuario({ abrirModal, setAbrirModal, idUsuario, statusAtual }) {
  const token = localStorage.getItem("token");

  const [status, setStatus] = useState();
  async function alterar() {
    const user_id = idUsuario;
    const newStatus = status;

    const data = {
      user_id,
      newStatus,
    };

    try {
      await api.put("/changeStatus", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Alterado com Sucesso", "", "success");
      setAbrirModal(false);
      //window.location.reload();
    } catch (err) {
      swal(err.response.data.error, "Tente novamente", "error");
    }
  }

  if (abrirModal === true) {
    return (
      <div className="SobreposicaoDadosCadastrais">
        <section className="ModalDadosCadastrais">
          <header>
            <h2>Alterar Perfil</h2>
          </header>
          <main>
            {statusAtual === 1 ? (
              <select
                name="status"
                className="status"
                onChange={(e) => setStatus(parseInt(e.target.value))}
              >
                <option value="1" selected>
                  Ativo
                </option>
                <option value="0">Inativo</option>
              </select>
            ) : (
              <select
                name="status"
                className="status"
                onChange={(e) => setStatus(parseInt(e.target.value))}
              >
                <option value="1">Ativo</option>
                <option value="0" selected>
                  Inativo
                </option>
              </select>
            )}
          </main>
          <footer>
            <button
              onClick={() => {
                setAbrirModal(false);
              }}
            >
              Fechar
            </button>
            <button className="botao-salvar" onClick={alterar}>
              Salvar
            </button>
          </footer>
        </section>
      </div>
    );
  }
  return null;
}

export default StatusUsuario;
