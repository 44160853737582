/*-------------- External --------------*/
import React, { useState, useEffect } from 'react';

/*-------------- Services --------------*/
import api from '../../../Servicos/api';
import useDebounce from '../../../Servicos/useDebounce';

/*-------------- Assets --------------*/
import foto from '../../../Arquivos/avatarPadrao.svg';
import { FiChevronsRight } from 'react-icons/fi';

/*-------------- Components --------------*/
import Menu from '../../../Componentes/GlobalMenu/indice';
import Ajuda from '../../../Componentes/Geral/Ajuda/Ajuda';
import Paginacao from '../../../Componentes/Paginacao/indice';
import Table from '../../../Componentes/Tables/TabelaEmpregado';
import AnimacaoCarregando from '../../../Componentes/AnimacaoCarregando/indice';
import Form from '../../../Componentes/Atendente/Modal-Cadastro-Empregado/indice';

/*-------------- Styles --------------*/
import './estilos.css';

export default function UsuariosClinica() {
  /*-------------- States --------------*/
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [items, setItems] = useState([]);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const [tableItems, setTableItems] = useState([]);
  const limitPage = 7;

  /*-------------- Storage --------------*/
  const token = localStorage.getItem('token');
  const fantasyName = localStorage.getItem('fantasy_name');
  const userName = localStorage.getItem('name_first');

  /*-------------- Constants --------------*/
  const titles = [
    { nome: 'Nome' },
    { nome: 'CPF' },
    { nome: 'Telefone' },
    { nome: 'Opções' },
  ];

  /*-------------- Effects --------------*/
  useEffect(() => {
    api
      .get(`/searchEmployee/?page=${page}&limit=7`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTotal(response.data.total);
        setItems(response.data.data);
        setTableItems(response.data.data);
      });
  }, [token, modal, page]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      api
        .get(`/searchAndFilterEmployee?search=${debouncedSearchTerm}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setTableItems(response.data);
          setLoading(false);
        });
    }
  }, [debouncedSearchTerm, token]);

  useEffect(() => {
    if (searchValue === '') {
      setTableItems(items);
    }
  }, [searchValue, items]);

  return (
    <div className="ContainerListaUsuarios">
      <Menu />
      <div className="container-usuarios">
        <div className="header-usuario">
          <h1>Bem-vindo, {fantasyName ? fantasyName : userName}.</h1>
          <div className="Perfil">
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de  Perfil" />
            </div>
          </div>
        </div>
        <nav>
          <div className="navegacaoUsuarios">
            <h2>{'Funcionários'}</h2>
            <h5>
              <FiChevronsRight id="Icone" /> {'Busca & Visão geral *'}
            </h5>
            <Ajuda
              mensagem={
                'Nesta tela é possivel gerenciar os funcionários da sua clínica, possibilitando cadastrar, atualizar, visualizar e remover.'
              }
              width={'40rem'}
              backColor={'var(--cor-branco)'}
              fontColor={'var(--cor-azul)'}
              margin={'0 0 0 1.5rem'}
              border={'0.5rem'}
              size={'2.5rem'}
            />
          </div>
        </nav>

        <section>
          <div className="Pesquisa">
            <div className="pesquisaNome">
              <input
                value={searchValue}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por nome do funcionário"
                onChange={(e) =>
                  setSearchValue(e.target.value.replace(/[^a-zA-Z ]/g, ''))
                }
                onKeyPressCapture={(e) =>
                  setSearchValue(e.target.value.replace(/[^a-zA-Z ]/g, ''))
                }
              />
              <label className="labelPesquisa">Pesquisa</label>
            </div>
          </div>
          <button
            className="botao-grande cadastro"
            id="botao-cadastrar-empregado"
            onClick={() => {
              setModal(true);
            }}
          >
            Cadastrar Funcionário
          </button>

          <Form abrirDados={modal} setAbrirDados={setModal} />
          {loading ? (
            <AnimacaoCarregando color="#1070a1" />
          ) : (
            <>
              <Table
                titles={titles}
                tableItems={tableItems}
                route="/clinica/empregado/perfil"
              />
              {tableItems && (
                <Paginacao
                  limitPage={limitPage}
                  dataLength={total}
                  setPage={setPage}
                />
              )}
            </>
          )}
        </section>
      </div>
    </div>
  );
}
