import React from "react";
import "./estilos.css";
export default function AnimacaoCarregando({ color }) {
  return (
    <div id="animacao">
      <span style={{ backgroundColor: color }}></span>
      <span style={{ backgroundColor: color }}></span>
      <span style={{ backgroundColor: color }}></span>
    </div>
  );
}
