import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import api from "../../../Servicos/api";
import { cpfMascara } from "../../../Servicos/Mascaras/cpfMascara";
//import { telefoneMascara } from "../../../Servicos/Mascaras/telefoneMascara";
import axios from "axios";

import "./estilos.css";

export default function Form({ setAbrirDados }) {
  const token = localStorage.getItem("token");

  //Informações Gerais
  const [cpf, setCpf] = useState("");
  const [name_first, setName_first] = useState("");
  const [name_last, setName_last] = useState("");
  const [gender, setGender] = useState("");
  const [data_birth, setData_birth] = useState(null);
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [cep, setCEP] = useState("");
  const [street, setStreet] = useState("");
  const [housign_type, setHousign_type] = useState("");
  const [number, setNumber] = useState();

  //Autenticacao
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [occupationCss, setOccupationCss] = useState("");
  //API IBGE
  // const [listaEstados, setListaEstados] = useState([]);
  // const [listaCidades, setListaCidades] = useState([]);

  const [listaEstadosCRM, setListaEstadosCRM] = useState([]);

  //Constantes de Controle
  const [desativa, setDesativa] = useState("");
  const [modoAvancar, setAvancar] = useState("");
  const [modoAvancar2, setAvancar2] = useState("");
  //const [modalVinculo, setModalVinculo] = useState(false);
  const [contador, setContador] = useState(0);
  const [estadoInput, setEstadoInput] = useState(false);

  //Específicas Administrador
  const [cnes, setCnes] = useState(null);
  const [cnpj, setCnpj] = useState(null);
  const [fantasy_name, setFantasy_name] = useState(null);
  const [social_reason, setSocial_reason] = useState(null);

  //Diretor Geral e Atendente
  const [occupation, setOccupation] = useState("");
  const [workload, setWorkload] = useState("");

  //Específicas Médico
  const [area_specialization, setArea_specialization] = useState("");
  const [rqe, setRqe] = useState("");
  const [crm, setCrm] = useState("");
  const [ufCRM, setUfCRM] = useState("");

  //Específicas Paciente
  const [fathers_name, setFathers_name] = useState("");
  const [mothers_name, setMothers_name] = useState("");
  const [responsible_name, setResponsible_name] = useState("");
  const [marital_status, setMarital_status] = useState("");
  const [profession, setProfession] = useState("");
  const [rg, setRg] = useState("");
  const [nationality, setNacionality] = useState("");
  const [birth_town, setBirth_town] = useState("");

  //Buscar Por CPF
  const [person_id, setPerson_id] = useState(0);
  const [user_id, setUser_id] = useState(null);
  const [, /*privileges*/ setPrivileges] = useState(null);
  const [opcaoPrivilegio, setOpcaoPrivilegio] = useState(null);

  const privilegios = [
    { privilegio: "MHAT", nome: "Atendente" },
    { privilegio: "MHDG", nome: "Diretor Geral" },
    { privilegio: "MHME", nome: "Médico" },
    { privilegio: "MHPA", nome: "Paciente" },
  ];

  function getOccupation(opcaoPrivilegio) {
    switch (opcaoPrivilegio) {
      case "MHAT":
        setOccupation("Atendente");
        break;
      case "MHDG":
        setOccupation("Diretor Geral");
        break;
      case "MHME":
        setOccupation("Médico");
        break;
      case "MHPA":
        setOccupation("Paciente");
        break;
      default:
        break;
    }
  }

  //especialização
  const [specialization_id, setSpecialization_id] = useState(0);
  const [specialization_name, setSpecialization_name] = useState("");
  const [listaEspecialidade, setListaEspecialidade] = useState([]);
  const [listaEspecialidadeFilter, setListaEspecialidadeFilter] = useState([]);

  var GrupoEspecialidade = [];

  function buscaCPF(cpf) {
    const cpflength = parseInt(cpf.length);
    if (contador === 0) {
      if (cpflength === 14) {
        api
          .get(`/searchPersonByCpf`, {
            headers: {
              Authorization: `Bearer ${token}`,
              cpf: cpf,
            },
          })
          .then((response) => {
            //setModalVinculo(true);
            setPerson_id(response.data.person.id);
            setUser_id(response.data.user_id);
            setPrivileges(response.data.privileges);
            setName_first(response.data.person.name_first);
            setName_last(response.data.person.name_last);
            setPhone(response.data.person.phone);
            setGender(response.data.gender);
            setData_birth(response.data.data_birth);
            setCEP(response.data.person.address.cep);
            setState(response.data.person.address.state);

            setCity(response.data.person.address.city);
            setStreet(response.data.person.address.street);
            setHousign_type(response.data.person.address.housign_type);
            setNumber(response.data.person.address.number);
            setEstadoInput(true);
          });
      }
      setContador(1);
    }
  }

  function verificAvancar() {
    if (
      name_first !== "" &&
      name_last !== "" &&
      cpf !== "" &&
      phone !== "" &&
      gender !== "" &&
      data_birth !== "" &&
      city !== "" &&
      street !== "" &&
      housign_type !== "" &&
      number !== ""
    ) {
      setAvancar2(true);
      setAvancar(false);
      setDesativa("desativa");
    } else {
      swal("Informe um CPF de funcionário que esteja cadastrado", "Para prosseguir", "error");
      setCpf("");
    }
    return false;
  }

  useEffect(() => {
    api.get("https://api.memed.com.br/v1/especialidades/").then((response) => {
      const dados = response.data.data.map((especialidade) => especialidade);

      function compare(a, b) {
        if (a.attributes.grupo < b.attributes.grupo) {
          return -1;
        }
        if (a.attributes.grupo > b.attributes.grupo) {
          return 1;
        }
        return 0;
      }

      setListaEspecialidade(dados.sort(compare));
      setListaEspecialidadeFilter(dados.sort(compare));

      //setSpecialization_name(dados.filter((especialidade) => especialidade.attributes.nome.toLowerCase() === area_specialization.toLowerCase())[0].attributes.grupo);
    });
  }, []);

  async function BuscarCep(cepPesquisa) {
    const ceplength = parseInt(cep.length);
    if (ceplength === 9) {
      try {
        const response = await axios.get(
          `https://viacep.com.br/ws/${cepPesquisa}/json/`
        );
        setCity(response.data.localidade);
        setState(response.data.uf);
      } catch (err) {
        swal(err.response.data.error, "Digite novamente", "error");
      }
    }
  }

  BuscarCep(cep);

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstadosCRM(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  function setValueGrupoInput() {
    var inputValue = document.getElementById("grupoEspecializacaoId10").value;
    var options = document.getElementById("dataListGrupo10").childNodes;

    for (let i = 0; i < GrupoEspecialidade.length; i++) {
      if (options[i].value === inputValue) {
        const listaFiltrada = [];
        listaEspecialidade.map((especialidade) =>
          especialidade.attributes.grupo.toLowerCase() ===
            GrupoEspecialidade[i].toLowerCase()
            ? listaFiltrada.push(especialidade)
            : GrupoEspecialidade[i]
        );

        setSpecialization_name(inputValue);
        setListaEspecialidadeFilter(listaFiltrada);
        break;
      }
    }
  }
  function setValueEspecializacaoInput() {
    var inputValue = document.getElementById("especializacaoId10").value;
    var options = document.getElementById("dataListEspecializacao10")
      .childNodes;

    for (var i = 0; i < listaEspecialidadeFilter.length; i++) {
      if (options[i].value === inputValue) {
        setSpecialization_id(parseInt(options[i].getAttribute("data-value")));
        setArea_specialization(inputValue);
        setSpecialization_name(
          listaEspecialidade.filter(
            (especialidade) =>
              especialidade.attributes.nome.toLowerCase() ===
              inputValue.toLowerCase()
          )[0].attributes.grupo
        );
        break;
      }
    }
  }

  async function AdicionarNovoVinculo(opcaoPrivilegio) {
    const privilege = opcaoPrivilegio;
    let employee;
    switch (opcaoPrivilegio) {
      case "MHMA":
        const manager = {
          cnes,
          cnpj,
          fantasy_name,
          social_reason,
        };

        const dataManager = {
          privilege,
          person_id,
          user_id,
          manager,
        };
        try {
          await api.post("addNewRelathionship", dataManager, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          swal("Cadastrado com Sucesso", "", "success");
          setAbrirDados(false);
          //history.push("/clinica/medicos");
        } catch (err) {
          swal(err.response.data.erro, "Digite novamente", "error");
        }
        break;

      case "MHDG":
        employee = {
          occupation,
          workload,
        };

        const dataDiretor = {
          privilege,
          person_id,
          user_id,
          employee,
        };
        try {
          await api.post("addNewRelathionship", dataDiretor, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          swal("Cadastrado com Sucesso", "", "success");
          setAbrirDados(false);
          //history.push("/clinica/medicos");
        } catch (err) {
          swal(err.response.data.erro, "Digite novamente", "error");
        }
        break;

      case "MHAT":
        employee = {
          occupation,
          workload,
        };

        const dataAtendente = {
          privilege,
          person_id,
          user_id,
          employee,
        };
        try {
          await api.post("addNewRelathionship", dataAtendente, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          swal("Cadastrado com Sucesso", "", "success");
          setAbrirDados(false);
          //history.push("/clinica/medicos");
        } catch (err) {
          swal(err.response.data.erro, "Digite novamente", "error");
        }
        break;

      case "MHPA":
        const patient = {
          nationality,
          birth_town,
          mothers_name,
          fathers_name,
          responsible_name,
          marital_status,
          profession,
          occupation,
          rg,
        };

        const dataPaciente = {
          privilege,
          person_id,
          user_id,
          patient,
        };
        try {
          await api.post("addNewRelathionship", dataPaciente, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          swal("Cadastrado com Sucesso", "", "success");
          setAbrirDados(false);
          //history.push("/clinica/medicos");
        } catch (err) {
          swal(err.response.data.erro, "Digite novamente", "error");
        }
        break;

      case "MHME":
        const doctor = {
          crm,
          area_specialization,
          rqe,
          state,
          specialization_id,
        };

        const dataMedico = {
          privilege,
          person_id,
          user_id,
          doctor,
        };
        try {
          await api.post("addNewRelathionship", dataMedico, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          swal("Cadastrado com Sucesso", "", "success");
          //history.push("/clinica/medicos");
          setAbrirDados(false);
        } catch (err) {
          swal(err.response.data.erro, "Digite novamente", "error");
        }

        break;

      default:
        break;
    }
  }

  function informacoesEspecificas(privilegio) {
    switch (privilegio) {
      case "MHMA":
        return (
          <>
            <Input
              className="input-nome"
              placeholder="CNES"
              value={cnes}
              type="text"
              setValue={setCnes}
              id="cnes"
              label="CNES"
              name="cnes"
            />

            <Input
              className="input-nome"
              placeholder="CNPJ"
              value={cnpj}
              type="text"
              setValue={setCnpj}
              id="cnpj"
              label="Cnpj"
              name="cnpj"
            />
            <Input
              className="input-nome"
              placeholder="Nome fantasia"
              value={fantasy_name}
              type="text"
              setValue={setFantasy_name}
              id="fantasy_name"
              label="Nome Fantasia"
              name="fantasy_name"
            />
            <Input
              className="input-nome"
              placeholder="Razão social"
              value={social_reason}
              type="text"
              setValue={setSocial_reason}
              id="social_reason"
              label="Razão social"
              name="social_reason"
            />
          </>
        );

      case "MHDG":
      case "MHAT":
        return (
          <>
            <select value={opcaoPrivilegio} disabled={true}>
              {privilegios.map((privilegio) => (
                <option
                  key={privilegio.privilegio}
                  value={privilegio.privilegio}
                >
                  {privilegio.nome}
                </option>
              ))}
            </select>
            <Input
              type="text"
              className="input-nome"
              placeholder="Carga horária"
              value={workload}
              setValue={setWorkload}
              id="workload"
              label="Carga horária"
              name="workload"
              occupation={occupationCss}
            />
          </>
        );

      case "MHME":
        return (
          <>
            <div className="grupo-especializacao">
              <input
                id="grupoEspecializacaoId10"
                list="dataListGrupo10"
                value={specialization_name}
                placeholder="-- Selecione um Grupo de Especialização --"
                autoComplete="off"
                onClick={(e) => {
                  e.target.value = "";
                  document.getElementById("especializacaoId10").value = "";
                  setListaEspecialidadeFilter(listaEspecialidade);
                  setArea_specialization("");
                }}
                onChange={(e) => {
                  setValueGrupoInput();
                }}
              />

              <datalist id="dataListGrupo10">
                {listaEspecialidade
                  .filter((especialidade) => {
                    if (
                      GrupoEspecialidade.indexOf(
                        especialidade.attributes.grupo.toLowerCase()
                      ) === -1
                    ) {
                      GrupoEspecialidade.push(
                        especialidade.attributes.grupo.toLowerCase()
                      );
                      return especialidade.attributes.grupo;
                    } else {
                      return null;
                    }
                  })
                  .map((grupo) => (
                    <option
                      key={grupo.attributes.grupo}
                      data-value={grupo.id}
                      value={grupo.attributes.grupo}
                    >
                      {grupo.attributes.grupo === "Null"
                        ? "Outros"
                        : grupo.attributes.grupo}
                    </option>
                  ))}
              </datalist>

              <input
                id="especializacaoId10"
                value={area_specialization}
                list="dataListEspecializacao10"
                placeholder="-- Selecione uma Especialização --"
                autoComplete="off"
                onClick={(e) => {
                  e.target.value = "";
                }}
                onChange={(e) => {
                  setValueEspecializacaoInput();
                }}
              />
              <datalist id="dataListEspecializacao10">
                {listaEspecialidadeFilter.map((especialidade) => (
                  <option
                    key={especialidade.id}
                    data-value={especialidade.id}
                    value={especialidade.attributes.nome}
                  >
                    {especialidade.attributes.nome}
                  </option>
                ))}
              </datalist>

              <Input
                className="input-nome"
                placeholder="RQE"
                value={rqe}
                type="number"
                setValue={setRqe}
                id="RQE"
                label="RQE"
                name="RQE"
              />
            </div>

            <div className="CRM_RQE">
              <Input
                className="input-nome"
                placeholder="CRM, CRP ou CRO"
                value={crm}
                type="number"
                setValue={setCrm}
                id="CRM"
                label="CRM"
                name="CRM"
              />
              <div className="conteudo">
                <div className="meu-box">
                  <select
                    placeholder="UF"
                    onChange={(e) => setUfCRM(e.target.value)}
                    select={ufCRM}
                    required
                  >
                    <option>-- Selecione o estado --</option>
                    {listaEstadosCRM.map((listaEstadoCRM) => (
                      <option key={listaEstadoCRM} value={listaEstadoCRM}>
                        {listaEstadoCRM}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </>
        );

      case "MHPA":
        return (
          <>
            <div className="father_mother">
              <Input
                className="input-nome"
                placeholder="Nome do pai"
                value={fathers_name}
                type="text"
                setValue={setFathers_name}
                id="fathers_name"
                label="Nome do Pai"
                name="fathers_name"
              />
              <Input
                className="input-nome"
                placeholder="Nome da mãe"
                value={mothers_name}
                type="text"
                setValue={setMothers_name}
                id="mothers_name"
                label="Nome da Mãe"
                name="mothers_name"
              />
            </div>
            <Input
              className="input-nome"
              placeholder="Nome do responsável"
              value={responsible_name}
              type="text"
              setValue={setResponsible_name}
              id="responsible_name"
              label="Nome do responsável"
              name="responsible_name"
            />
            <div className="estadoCivil_profisao">
              <select
                value={marital_status}
                onChange={(e) => setMarital_status(e.target.value)}
                required
              >
                <option value={0}>-- Estado Civil --</option>
                <option value={1}>Solteiro</option>
                <option value={2}>Casado</option>
                <option value={3}>Viuvo</option>
              </select>
              <Input
                className="input-nome"
                placeholder="Profissão"
                value={profession}
                type="text"
                setValue={setProfession}
                id="profession"
                label="Profissão"
                name="profession"
              />
            </div>
            <div className="RG_CidadeNatal">
              <Input
                className="input-nome"
                placeholder="RG"
                value={rg}
                type="text"
                setValue={setRg}
                id="rg"
                label="RG"
                name="rg"
              />
              <select
                placeholder="Nacionalidade"
                value={nationality}
                onChange={(e) => setNacionality(parseInt(e.target.value))}
                required
              >
                <option value={0}>-- Nacionalidade --</option>
                <option value={1}>Brasileiro</option>
                <option value={2}>Estrangeiro</option>
                <option value={3}>Naturalizado</option>
              </select>
            </div>
            <Input
              className="input-nome"
              placeholder="Cidade de Nascimento"
              value={birth_town}
              type="text"
              setValue={setBirth_town}
              id="birth_town"
              label="Cidade de Nascimento"
              name="birth_town"
            />
          </>
        );

      default:
        break;
    }
  }

  return (
    <div id="form">
      <div
        className={`grupo-button-form-medico ${desativa}`}
        style={{ display: modoAvancar ? "none" : "block" }}
      >
        <div className="numIndic-medico">
          <p> Página 1/2</p>
        </div>
        <InputCPF
          className="input-nome"
          placeholder="000.000.000-00"
          value={cpf}
          type="text"
          setValue={setCpf}
          id="cpf"
          label="CPF"
          name="cpf"
          buscarCPF={buscaCPF(cpf)}
          setContador={setContador}
          estado={estadoInput}
        />

        <Link
          className="botao-grande"
          to="#"
          style={{
            display: modoAvancar ? "none" : "flex",
          }}
          onClick={() => {
            verificAvancar();
          }}
        >
          Avançar
        </Link>
      </div>

      <div
        className="grupo-cadastrar"
        style={{ display: modoAvancar2 ? "block" : "none" }}
      >
        <div className="numIndic-medico">
          <p>Página 2/2</p>
        </div>

        {estadoInput ? (
          <div className="conteudo">
            <div className="meu-box">
              <select
                placeholder="Escolher Vínculo Novo"
                onChange={(e) => {
                  setOpcaoPrivilegio(e.target.value);
                  setOccupationCss(e.target.value);
                  getOccupation(e.target.value);
                }}
                select={opcaoPrivilegio}
                required
              >
                <option value="">-- Escolher Novo Vínculo --</option>
                {/* <option value="MHMA">Administrador</option> */}
                {privilegios.map((privilegio) => (
                  <option
                    key={privilegio.privilegio}
                    value={privilegio.privilegio}
                  >
                    {privilegio.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          ""
        )}

        {informacoesEspecificas(opcaoPrivilegio)}

        {estadoInput ? (
          ""
        ) : (
          <>
            <div className="grupo-especializacao">
              <input
                id="grupoEspecializacaoId10"
                list="dataListGrupo10"
                value={specialization_name}
                placeholder="-- Selecione um Grupo de Especialização --"
                autoComplete="off"
                onClick={(e) => {
                  e.target.value = "";
                  document.getElementById("especializacaoId10").value = "";
                  setListaEspecialidadeFilter(listaEspecialidade);
                  setArea_specialization("");
                }}
                onChange={(e) => {
                  setValueGrupoInput();
                }}
              />

              <datalist id="dataListGrupo10">
                {listaEspecialidade
                  .filter((especialidade) => {
                    if (
                      GrupoEspecialidade.indexOf(
                        especialidade.attributes.grupo.toLowerCase()
                      ) === -1
                    ) {
                      GrupoEspecialidade.push(
                        especialidade.attributes.grupo.toLowerCase()
                      );
                      return especialidade.attributes.grupo;
                    } else {
                      return null;
                    }
                  })
                  .map((grupo) => (
                    <option
                      key={grupo.attributes.grupo}
                      data-value={grupo.id}
                      value={grupo.attributes.grupo}
                    >
                      {grupo.attributes.grupo === "Null"
                        ? "Outros"
                        : grupo.attributes.grupo}
                    </option>
                  ))}
              </datalist>

              <input
                id="especializacaoId10"
                value={area_specialization}
                list="dataListEspecializacao10"
                placeholder="-- Selecione uma Especialização --"
                autoComplete="off"
                onClick={(e) => {
                  e.target.value = "";
                }}
                onChange={(e) => {
                  setValueEspecializacaoInput();
                }}
              />
              <datalist id="dataListEspecializacao10">
                {listaEspecialidadeFilter.map((especialidade) => (
                  <option
                    key={especialidade.id}
                    data-value={especialidade.id}
                    value={especialidade.attributes.nome}
                  >
                    {especialidade.attributes.nome}
                  </option>
                ))}
              </datalist>

              <Input
                className="input-nome"
                placeholder="RQE"
                value={rqe}
                type="number"
                setValue={setRqe}
                id="RQE"
                label="RQE"
                name="RQE"
              />
            </div>

            <div className="CRM_RQE">
              <Input
                className="input-nome"
                placeholder="CRM, CRP ou CRO"
                value={crm}
                type="number"
                setValue={setCrm}
                id="CRM"
                label="CRM"
                name="CRM"
              />
              <div className="conteudo">
                <div className="meu-box">
                  <select
                    placeholder="UF"
                    onChange={(e) => setUfCRM(e.target.value)}
                    select={ufCRM}
                    required
                  >
                    <option>-- Selecione o estado --</option>
                    {listaEstadosCRM.map((listaEstadoCRM) => (
                      <option key={listaEstadoCRM} value={listaEstadoCRM}>
                        {listaEstadoCRM}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="grupo-flex-registro">
              <Input
                type="text"
                className="input-nome"
                placeholder="nome@gmail.com"
                value={email}
                setValue={setEmail}
                id="Email"
                label="Email"
                name="Email"
                estado={estadoInput}
              />

              <Input
                type="text"
                className="input-nome"
                placeholder="usuario123"
                value={username}
                setValue={setUsername}
                id="Username"
                label="Username"
                name="Username"
                estado={estadoInput}
              />
            </div>

            <div className="grupo-flex-registro">
              <Input
                className="input-nome"
                placeholder="*********"
                value={password}
                type="password"
                setValue={setPassword}
                id="Password"
                label="Senha"
                name="Password"
                estado={estadoInput}
              />

              <Input
                className="input-nome"
                placeholder="*********"
                value={confirmationPassword}
                type="password"
                setValue={setConfirmationPassword}
                id="Confirmar Senha"
                label="Confirmar Senha"
                name="Confirmar Senha"
                estado={estadoInput}
              />
            </div>
          </>
        )}
        <div className="botoes-form">
          <button
            to="#"
            className="botao-grande voltar"
            onClick={() => {
              setDesativa("");
              setAvancar2(false);
            }}
          >
            Voltar
          </button>
          <button
            onClick={() => AdicionarNovoVinculo(opcaoPrivilegio)}
            className="botao-grande"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
}

function Input({
  id,
  label,
  value,
  setValue,
  type,
  placeholder,
  estado,
  occupation,
  maxlength,
}) {
  function ModificaValor(e) {
    setValue(e.target.value);
  }

  return (
    <div className={`conteudo ${occupation}`}>
      <div className="meu-box">
        <input
          className="input-nome"
          type={type}
          id={id}
          name={id}
          value={value}
          placeholder={placeholder}
          onChange={ModificaValor}
          disabled={estado}
          maxLength={maxlength}
          required
        />
        <label htmlFor={id} className="label-nome">
          {" "}
          <span>{label}</span>
        </label>
      </div>
    </div>
  );
}

function InputCPF({
  id,
  label,
  value,
  setValue,
  type,
  placeholder,
  buscarCPF,
  setContador,
  estado,
}) {
  function ModificaValor(e) {
    setValue(e.target.value);
    setContador(0);
  }

  function ModificaContador() {
    return null;
  }
  return (
    <div className="conteudo">
      <div className="meu-box">
        <input
          className="input-nome"
          type={type}
          id={id}
          name={id}
          value={cpfMascara(value)}
          placeholder={placeholder}
          onChange={estado === true ? ModificaContador : ModificaValor}
          onKeyPressCapture={buscarCPF}
          disabled={estado}
          required
        />
        <label htmlFor={id} className="label-nome">
          {" "}
          <span>{label}</span>
        </label>
      </div>
    </div>
  );
}
