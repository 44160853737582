import React from "react";
import "./estilos.css";

export default function Paginacao({
  limitPage,
  dataLength,
  setPage,
  width,
}) {
  const pages = [];

  for (let i = 1; i <= Math.ceil(dataLength / limitPage); i++) {
    pages.push(i);
  }

  return (
    <nav id="paginacao" style={{ width: `${width}` }}>
      {pages.map((number) => (
        <button
          key={number}
          className="btn-paginacao"
          type="button"
          onClick={() => {
            setPage(number - 1);
          }}
        >
          {number}
        </button>
      ))}
    </nav>
  );
}
