/*
  Data de Criação: 24/09/2020;
  Autor: Caio Rafael da Rocha Gomes;
  Descrição: Página para listagem das consultas do médico
*/
import React, { useState, useEffect, useContext } from "react";
import {
  FiBell,
  FiClock,
  FiCheck,
  FiPlay,
  FiEye,
  FiXCircle,
} from "react-icons/fi";
import VisualizarConsulta from "../../../Componentes/Medico/VisualizarConsulta/indice";
import { Link } from "react-router-dom";
import Menu from "../../../Componentes/GlobalMenu/indice";
import api from "../../../Servicos/api";
import { cpfMascara } from "../../../Servicos/Mascaras/cpfMascara";
import "./estilos.css";
import Paginacao from "../../../Componentes/Paginacao/indice";
import { ChallengesContext } from "../../../Servicos/Context/ChallengesContext";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

function ListaConsultas() {
  const [abrirDados, setAbrirDados] = useState(false);
  const token = localStorage.getItem("token");
  const idMedico = localStorage.getItem("idMedico");
  const name_first = localStorage.getItem("name_first");
  const [listaConsultas, setListaConsultas] = useState([]);
  const [listaConsultasTabela, setListaConsultasTabela] = useState([]);

  const [option, setOption] = useState(1);

  const [/*nextReq,*/ setNextReq] = useState();
  const limitPage = 7;
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();

  const foto = localStorage.getItem("photo");

  const { setDataConsulta } = useContext(ChallengesContext);

  useEffect(() => {
    const getData = async () => {
      const response = await api.get(
        `/doctor/consultation/${idMedico}/?page=${page}&limit=${limitPage}&option=${option}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setListaConsultas(response.data.data);
      setTotal(response.data.total);
    };
    getData();
  }, [idMedico, token, option, page]);

  //pesquisar por nome
  const [pesquisaNome, setPesquisaNome] = useState("");

  useEffect(() => {
    const resultados = listaConsultas.filter((paciente) =>
      paciente.patient.person.name_first
        .toLowerCase()
        .includes(pesquisaNome.toLowerCase())
    );
    setListaConsultasTabela(resultados);
  }, [pesquisaNome, listaConsultas]);

  //pesquisar por cpf
  const [pesquisaCpf, setPesquisaCpf] = useState("");
  useEffect(() => {
    const resultados = listaConsultas.filter((paciente) =>
      paciente.patient.person.cpf.includes(pesquisaCpf)
    );
    setListaConsultasTabela(resultados);
  }, [pesquisaCpf, listaConsultas]);

  //Pegar consultas atuais

  //Listar consultas
  const [mostrarCanceladas, setMostrarCanceladas] = useState(false);
  const [mostrarAgendadas, setMostrarAgendadas] = useState(true);
  const [mostrarFinalizadas, setMostrarFinalizadas] = useState(false);

  function dateToEN(date) {
    return date.slice(0, 10).split("-").reverse().join("/");
  }

  const titulos = [
    { nome: "Paciente" },
    { nome: "CPF" },
    { nome: "Tipo da consulta" },
    { nome: "Data" },
    { nome: "Horário" },
    { nome: "Opções" },
  ];

  function exibirAgendados() {
    setMostrarAgendadas(true);
    setMostrarFinalizadas(false);
    setMostrarCanceladas(false);
    setPage(0);
    setOption(1);
  }

  function exibirFinalizadas() {
    setMostrarAgendadas(false);
    setMostrarFinalizadas(true);
    setMostrarCanceladas(false);
    setPage(0);
    setOption(2);
  }

  function exibirCanceladas() {
    setMostrarAgendadas(false);
    setMostrarFinalizadas(false);
    setMostrarCanceladas(true);
    setPage(0);
    setOption(3);
  }

  return (
    <div className="ContainerConsultasMedico">
      <Menu />
      <VisualizarConsulta
        abrirDados={abrirDados}
        setAbrirDados={setAbrirDados}
      />

      <div className="ContainerConteudo">
        <header>
          <div id="div-ajuda">
            <h1>Suas consultas, Dr.(a) {name_first}.</h1>
            <Ajuda
              mensagem={
                "Nesta tela é possivel gerenciar suas consultas, possibilitando iniciar uma consulta."
              }
              width={"30rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>
          <div className="Perfil">
            <Link to="">
              <FiBell className="Notificacao" />
            </Link>
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de  Perfil" />
            </div>
          </div>
        </header>
        <section>
          <div className="Pesquisa">
            <div className="pesquisaNome">
              <input
                value={pesquisaNome}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por nome do Paciente"
                onChange={(e) => setPesquisaNome(e.target.value)}
                onKeyPressCapture={(e) => setPesquisaNome(e.target.value)}
              />
              <label className="labelPesquisa">Paciente</label>
            </div>
            <div className="pesquisaCpf">
              <input
                value={pesquisaCpf}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por CPF do Paciente"
                onChange={(e) => setPesquisaCpf(cpfMascara(e.target.value))}
                onKeyPressCapture={(e) =>
                  setPesquisaCpf(cpfMascara(e.target.value))
                }
              />
              <label className="labelPesquisa">CPF</label>
            </div>
            {/* <div className="pesquisaData">
              <input
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por data"
              />
              <label className="labelPesquisa">Data</label>
            </div> */}
          </div>
          <div className="Tabela">
            <div className="botoesTipos">
              <button onClick={exibirAgendados} className="botaoAgendados">
                <span>Agendadas</span> <FiClock />
              </button>
              <button onClick={exibirFinalizadas} className="botaoFinalizados">
                <span>Finalizadas</span> <FiCheck />
              </button>
              <button onClick={exibirCanceladas} className="botaoCancelados">
                <span>Canceladas</span> <FiXCircle />
              </button>
            </div>
            <div className="tabelaConsultas">
              <table
                style={
                  mostrarAgendadas
                    ? { display: "inline-table" }
                    : { display: "none" }
                }
              >
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaConsultasTabela.length === 0 ? (
                    <tr>
                      <td colSpan={6}>Não há consultas agendadas.</td>
                    </tr>
                  ) : (
                    listaConsultasTabela.reverse().map((info) => (
                      <tr key={info.id}>
                        <td>{info.patient.person.name_first}</td>
                        <td>{info.patient.person.cpf}</td>
                        <td>
                          {info.type === 1
                            ? "Consulta - 1 vez"
                            : info.type === 2
                              ? "Consulta - Rotina"
                              : info.type === 3
                                ? "Consulta - Retorno"
                                : "Exames"}
                        </td>
                        <td>{dateToEN(info.start_time)}</td>
                        <td>{info.start_time.slice(11, 16)}</td>
                        <td>
                          <Link
                            to="/medico/consulta"
                            onClick={() => {
                              localStorage.setItem("idConsulta", info.id);
                              localStorage.setItem("rota", "listaconsultas");
                              localStorage.setItem(
                                "paciente_id",
                                info.patient_id
                              );
                              setDataConsulta(dateToEN(info.start_time));
                            }}
                          >
                            <FiPlay color="#1070a1" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <table
                style={
                  mostrarFinalizadas
                    ? { display: "inline-table" }
                    : { display: "none" }
                }
              >
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaConsultasTabela.length === 0 ? (
                    <tr>
                      <td>Não há consultas finalizadas.</td>
                    </tr>
                  ) : (
                    listaConsultasTabela.reverse().map((info) => (
                      <tr key={info.id}>
                        <td>{info.patient.person.name_first}</td>
                        <td>{info.patient.person.cpf}</td>
                        <td>
                          {info.type === 1
                            ? "Consulta - 1 vez"
                            : info.type === 2
                              ? "Consulta - Rotina"
                              : info.type === 3
                                ? "Consulta - Retorno"
                                : "Exames"}
                        </td>
                        <td>{dateToEN(info.start_time)}</td>
                        <td>{info.start_time}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => {
                              setAbrirDados(true);
                              localStorage.setItem("idConsulta", info.id);
                            }}
                          >
                            <FiEye color="#1070a1" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <table
                style={
                  mostrarCanceladas
                    ? { display: "inline-table" }
                    : { display: "none" }
                }
              >
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaConsultasTabela.length === 0 ? (
                    <tr>
                      <td>Não há consultas canceladas.</td>
                    </tr>
                  ) : (
                    listaConsultasTabela.map((info) => (
                      <tr key={info.id}>
                        <td>{info.patient.person.name_first}</td>
                        <td>{info.patient.person.cpf}</td>
                        <td>
                          {info.type === 1
                            ? "Consulta - 1 vez"
                            : info.type === 2
                              ? "Consulta - Rotina"
                              : info.type === 3
                                ? "Consulta - Retorno"
                                : "Exames"}
                        </td>
                        <td>{dateToEN(info.start_time)}</td>
                        <td>{info.start_time}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => {
                              setAbrirDados(true);
                              localStorage.setItem("idConsulta", info.id);
                            }}
                          >
                            <FiEye color="#1070a1" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {mostrarAgendadas ? (
                <Paginacao
                  consultasPorPagina={limitPage}
                  totalConsulta={total}
                  setNextReq={setNextReq}
                  setPage={setPage}
                />
              ) : mostrarFinalizadas ? (
                <Paginacao
                  consultasPorPagina={limitPage}
                  totalConsulta={total}
                  setNextReq={setNextReq}
                  setPage={setPage}
                />
              ) : setMostrarCanceladas ? (
                <Paginacao
                  consultasPorPagina={limitPage}
                  totalConsulta={total}
                  setNextReq={setNextReq}
                  setPage={setPage}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ListaConsultas;
