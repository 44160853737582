import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import swal from 'sweetalert';
import NovaConsultaClinica from '../NovaConsulta/indice';
import Ajuda from '../../Geral/Ajuda/Ajuda';

import './estilos.css';
import api from '../../../Servicos/api';

export default function Calendario({
  listaConsulta,
  medico,
  medicoId,
  medicoSpecialization,
}) {
  const [ativo, setAtivo] = useState('');

  let events = [];
  const token = localStorage.getItem('token');
  const [listaPaciente, setListaPaciente] = useState([]);
  const [consulta, setConsulta] = useState({});
  const [data, setData] = useState('');

  useEffect(() => {
    api
      .get(`searchPatients/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListaPaciente(response.data);
      });
  }, [token]);

  function openModal(estado) {
    setAtivo(estado);
  }

  function getNamePatient(id) {
    var nomePaciente = '';
    listaPaciente.map((paciente) =>
      paciente.person.patient.id === id
        ? (nomePaciente = paciente.person.name_first)
        : ''
    );
    return nomePaciente;
  }

  function setEvents() {
    listaConsulta.map((consulta) => {
      if (consulta) {
        events.push({
          id: consulta.id,
          title: `Dr(a) ${consulta.doctor.person.name_first} - Paciente: ${getNamePatient(consulta.patient_id)}`,
          start: `${consulta.start_time}:00`,
          end: `${consulta.final_time}:00`
        });

        //console.table(events);
      }
      return null;
    });
  }

  function limparConsulta() {
    setConsulta({});
  }

  return (
    <div className="calendario">
      {setEvents()}
      <div className={`sobreposicaoConsulta ${ativo}`}>
        <NovaConsultaClinica
          ativo={ativo}
          executar={openModal}
          medico={medico}
          medicoId={medicoId}
          medicoSpecialization={medicoSpecialization}
          listaPaciente={listaPaciente}
          consulta={consulta}
          limparConsulta={limparConsulta}
          rota={'agenda'}
          data={data}
          events={events}
        />
      </div>
      <Ajuda
        mensagem={
          'Para marcar uma consulta com mais de 15 minutos é só clicar sobre o horário que quiser e arrastar'
        }
        width={'45rem'}
        backColor={'var(--cor-branco)'}
        fontColor={'var(--cor-azul)'}
        margin={'0 0 0 1.5rem'}
        border={'0.5rem'}
        size={'2.5rem'}
      />
      <FullCalendar
        locale="pt-br"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right: 'timeGridDay,timeGridWeek,dayGridMonth',
        }}
        initialView="timeGridDay"
        nowIndicator={true}
        now={moment().format('YYYY-MM-DDTHH:mm:ss[Z]')}
        allDaySlot={false}
        slotDuration={'00:15'}
        slotLabelFormat={[
          {
            minute: '2-digit',
            hour: '2-digit',
            omitZeroMinute: false,
          },
        ]}
        eventColor={'#1070a1'}
        slotMinTime="06:00"
        slotMaxTime="18:00"
        contentHeight={'auto'}
        eventTimeFormat={{
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
        }}
        firstHour={7}
        buttonText={{
          today: 'Hoje',
          month: 'Mensal',
          week: 'Semanal',
          day: 'Diario',
        }}
        views={[
          {
            timeGridDay: {
              // name of view
              titleFormat: {
                year: '4-digit',
                month: '4-digit',
                day: '4-digit',
              },
              // other view-specific options here
            },
          },
        ]}
        timeZone="UTC"
        events={events}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        eventClick={(info) => {
          setConsulta(
            listaConsulta.filter(
              (item) => item.id === parseInt(info.event.id)
            )[0]
          );
          openModal('ativo');
        }}
        dateClick={(info) => {
          if (info.overlap === true) {
            if (medico !== 0) {
              if(info.dateStr.substring(0, 16) < moment().format('YYYY-MM-DD')) {
                swal('Essa data é inválida', 'Escolha a data atual ou uma depois do dia de hoje', 'error');
              } else {
                setData(info.dateStr.substring(0, 16));
                openModal('ativo');
              }
              //console.log(data);
            } else {
              swal('Médico não selecionado', 'Escolha um médico', 'error');
            }
          } else {
            return null;
          }
        }}
        select={(info) => {
          if (medico !== '') {
            if(info.startStr.substring(0, 16) + info.endStr.substring(11, 16) < moment().format('YYYY-MM-DD')) {
              swal('Essa data é inválida', 'Escolha a data atual ou uma depois do dia de hoje', 'error');
            } else {
              setData(
                info.startStr.substring(0, 16) + info.endStr.substring(11, 16)
              );
              openModal('ativo');
            }
              //console.log(info.startStr.substring(0, 16) + info.endStr.substring(11, 16));
              //console.log(moment().format('YYYY-MM-DD'));
            } else {
            swal('Médico não selecionado', 'Escolha um médico', 'error');
          }
        }}
        selectOverlap={(event) => {
          if (event.ranges && event.ranges.length > 0) {
            return (
              event.ranges.filter((range) => {
                return (
                  event.start.isBefore(range.end) &&
                  event.end.isAfter(range.start)
                );
              }).length > 0
            );
          } else {
            return !!event && event.overlap;
          }
        }}
        eventOverlap={(event) => {
          if (event.ranges && event.ranges.length > 0) {
            return (
              event.ranges.filter((range) => {
                return (
                  event.start.isBefore(range.end) &&
                  event.end.isAfter(range.start)
                );
              }).length > 0
            );
          } else {
            return !!event && event.overlap;
          }
        }}
      />
    </div>
  );
}
