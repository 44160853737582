import React, { useEffect, useState } from "react";
import "./estilos.css";
import { FiAlertCircle, FiCheck, FiClock } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";

import Menu from "../../../Componentes/GlobalMenu/indice";
import Tabela from "../../../Componentes/Atendente/Tabela/indice";
import { Link } from "react-router-dom";
import api from "../../../Servicos/api";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

const titulos = [
  { nome: "Paciente" },
  { nome: "Médico" },
  { nome: "Tipo de consulta" },
  { nome: "Data" },
  { nome: "Hora" },
  { nome: "Enviar notificação" },
];

export default function SalaEspera() {
  const token = localStorage.getItem("token");
  const [listaConsultas, setListaConsultas] = useState([]);

  //Pegar data
  let dataObj = new Date();
  let dia =
    dataObj.getDate() < 10 ? `0${dataObj.getDate()}` : dataObj.getDate();
  let mes =
    dataObj.getMonth() + 1 < 10
      ? `0${dataObj.getMonth() + 1}`
      : dataObj.getMonth() + 1;
  let ano = dataObj.getFullYear();
  let dataAtual = `${ano}-${mes}-${dia}`;

  useEffect(() => {
    api
      .get(`/dailyConsultations`, {
        headers: {
          Authorization: `Bearer ${token}`,
          consult_date: dataAtual,
        },
      })
      .then((response) => {
        setListaConsultas(response.data.data);
      });
  }, [token, dataAtual]);
  let situacao = 3;

  return (
    <div id="container-sala-espera">
      <Menu />
      <div className="conteudo-sala">
        <header>
          <div id="div-ajuda">
            <h1>Sala de espera</h1>
            <Ajuda
              mensagem={
                "Nesta tela é possível gerenciar as consultas agendadas, possibilitando vizualizar as consultas e status e agendar uma nova consulta."
              }
              width={"33rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>

          <Link to="agenda">
            <div className="icone">
              <AiOutlinePlus size="30" color="#fff" />
            </div>
            <span> Agendar Consulta </span>
          </Link>
        </header>

        <section className="consulta">
          <div className="paciente">
            <strong>Paciente:</strong>
            <span>
              {
                listaConsultas.map((dado) => {
                  return (
                    dado.patient.person.name_first +
                    " " +
                    dado.patient.person.name_last
                  );
                })[0]
              }
            </span>
            <strong>Tipo de consulta:</strong>
            <span>
              {listaConsultas.map((dado) => {
                return dado.type;
              })[0] === 1
                ? "Consulta - 1 vez"
                : listaConsultas.map((dado) => {
                    return dado.type;
                  })[0] === 2
                ? "Consulta - Rotina"
                : "Consulta - Retorno"}
            </span>
          </div>
          <div className="horario">
            <strong>Médico:</strong>
            <br />
            <span>
              {
                listaConsultas.map((dado) => {
                  return (
                    dado.doctor.person.name_first +
                    " " +
                    dado.doctor.person.name_last
                  );
                })[0]
              }
            </span>
          </div>
          <div className="horario">
            <strong>Inicio:</strong>
            <br />
            <span>
              {
                listaConsultas.map((dado) => {
                  return dado.start_time;
                })[0]
              }
            </span>
          </div>
          <div className="horario">
            <strong>Fim:</strong>
            <br />
            <span>
              {
                listaConsultas.map((dado) => {
                  return dado.final_time;
                })[0]
              }
            </span>
          </div>

          {situacao === 1 ? (
            <div className="situacao emergencia">
              <FiAlertCircle size="35" />
              <span>EMERGÊNCIA!</span>
            </div>
          ) : situacao === 2 ? (
            <div className="situacao finalizada">
              <FiCheck size="35" />
              <span>FINALIZADA</span>
            </div>
          ) : situacao === 3 ? (
            <div className="situacao andamento">
              <FiClock size="35" />
              <span>EM ANDAMENTO</span>
            </div>
          ) : (
            <h1>SITUAÇÃO NÃO IDENTIFICADA</h1>
          )}
        </section>

        <main>
          <Tabela titulos={titulos} lista={listaConsultas} />
        </main>
      </div>
    </div>
  );
}
