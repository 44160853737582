import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
import api from "../../../Servicos/api";
import Menu from "../../../Componentes/GlobalMenu/indice";
import EditorTexto from "../../../Componentes/Medico/EditorTexto/indice";
import "./estilos.css";

import {
  FiUser,
  FiClock,
  FiChevronDown,
  FiPlus,
  FiTrash,
} from "react-icons/fi";
import { BsArrowLeft } from "react-icons/bs";
import DadosCadastrais from "../../../Componentes/Medico/DadosCadastrais/indice";
import CadastrarMedicamento from "../../../Componentes/Medico/CadastrarMedicamento/indice";
import FinalizarConsulta from "../../../Componentes/Medico/FinalizarConsulta/indice";
import { ChallengesContext } from "../../../Servicos/Context/ChallengesContext";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

function Consulta() {
  const history2 = useHistory();
  //Abrir/Fechar seções
  const [abrirProntuario, setAbrirProntuario] = useState("");
  const [abrirConsulta, setAbrirConsulta] = useState("");
  const [abrirReceita, setAbrirReceita] = useState("");
  const [abrirAtestado, setAbrirAtestado] = useState("");
  const [abrirExames, setAbrirExames] = useState("");

  //Abrir/Fechar modais
  const [abrirDados, setAbrirDados] = useState(false);
  const [cadastrarMedicamento, setCadastrarMedicamento] = useState(false);
  const [modalFinalizar, setModalFinalizar] = useState(false);

  //Pegar data
  let dataObj = new Date();
  let dia = dataObj.getDate();
  let mes =
    dataObj.getMonth() < 10 ? `0${dataObj.getMonth()}` : dataObj.getMonth();
  let ano = dataObj.getFullYear();
  let dataAtual = `${dia}/${mes}/${ano}`;

  //Cronômetro

  const [time, setTime] = useState({ ms: 0, s: 0, m: 0, h: 0 });
  const [interv, setInterv] = useState();
  const [status, setStatus] = useState(0);

  let duration = `${time.h > 9 ? time.h : "0" + time.h}:${
    time.m > 9 ? time.m : "0" + time.m
  }:${time.s > 9 ? time.s : "0" + time.s}`;

  const start = () => {
    run();
    setStatus(1);
    setInterv(setInterval(run, 10));
  };

  var updatedMs = time.ms,
    updatedS = time.s,
    updatedM = time.m,
    updatedH = time.h;

  const run = () => {
    if (updatedM === 60) {
      updatedH++;
      updatedM = 0;
    }
    if (updatedS === 60) {
      updatedM++;
      updatedS = 0;
    }
    if (updatedMs === 100) {
      updatedS++;
      updatedMs = 0;
    }

    updatedMs++;
    return setTime({ ms: updatedMs, s: updatedS, m: updatedM, h: updatedH });
  };

  const stop = () => {
    clearInterval(interv);
    setModalFinalizar(true);
  };

  // const reset = () => {
  //   clearInterval(interv);
  //   setStatus(0);
  //   setTime({ ms: 0, s: 0, m: 0, h: 0 });
  // };

  const resume = () => {
    setModalFinalizar(false);
    start();
  };

  //Valores
  //var medications = [];
  const [medications, setMedications] = useState([]);

  const [dadosPaciente1, setDadosPacientes1] = useState({});
  const [dadosPaciente2, setDadosPacientes2] = useState({});
  const [dataNascimento, setDataNascimento] = useState("");

  const token = localStorage.getItem("token");
  const consultation_id = localStorage.getItem("idConsulta");
  const paciente_id = localStorage.getItem("paciente_id");
  const [complaint, setComplaint] = useState("Queixa inicial");
  const rota = localStorage.getItem("rota");

  const [has, setHas] = useState(false);
  const [diabetes, setDiabetes] = useState(false);
  const [heart_diseases, setHeart_diseases] = useState(false);
  const [coagulation, setCoagulation] = useState(false);
  const [hyperthyroidism, setHyperthyroidism] = useState(false);
  const [hypothyroidism, setHypothyroidism] = useState(false);
  const [depression, setDepression] = useState(false);
  const [smoking, setSmoking] = useState(false);
  const [other_complaint, setOther_complaint] = useState(false);
  const [history, setHistory] = useState("");

  const [family_history, setFamily_history] = useState("");

  const [antihypertensive, setAntihypertensive] = useState(false);
  const [antidepressant, setAntidepressant] = useState(false);
  const [multivitamins, setMultivitamins] = useState(false);
  const [ginkobiloba, setGinkobiloba] = useState(false);
  const [ass, setAss] = useState(false);
  const [aco, setAco] = useState(false);
  const [other_medicines, setOther_medicines] = useState(false);
  const [desc_medicines, setDesc_medicines] = useState("");

  const [no_surgery, setNo_surgery] = useState(false);
  const [aesthetic, setAesthetic] = useState(false);
  const [other_surgery, setOther_surgery] = useState(false);
  const [desc_surgery, setDesc_surgery] = useState("");

  const [physical_examination, setPhysical_examination] = useState("");
  const [hypothesis, setHypothesis] = useState("");
  const [conduct, setConduct] = useState("");

  const [tituloExame, setTituloExame] = useState("");
  const [descricaoExame, setDescricaoExame] = useState("");

  // const [medicamentos] = useState([
  //   { nome: "Medicamento 1" },
  //   { nome: "Medicamento 2" },
  // ]);
  // const [obsReceita, setObsReceita] = useState("Observações Receita");

  // const [atestado, setAtestado] = useState([
  //   { nome: "Modelo 1" },
  //   { nome: "Modelo 2" },
  // ]);
  const [obsAtestado, setObsAtestado] = useState("Observações Atestado");

  const { DataConsulta } = useContext(ChallengesContext);

  // const [tipoExame, setTipoExame] = useState("");
  // const [descExame, setDescExame] = useState("Descrição Exame");

  async function cadastraMedicamento() {
    const consultation_id = localStorage.getItem("idConsulta");
    const data = {
      consultation_id,
      medications,
    };
    try {
      await api.post("doctor/consultation/medications", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setCadastrarMedicamento(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function AtualizarConsulta() {
    //e.preventDefault();

    const data = {
      situation: 3,
      duration,
      date: DataConsulta,
    };
    const token = localStorage.getItem("token");
    const consultation_id = localStorage.getItem("idConsulta");
    console.log(data);
    try {
      await api.put(`doctor/finishConsultation/${consultation_id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Consulta finalizada com sucesso", "", "success");
      setModalFinalizar(false);
    } catch (err) {
      swal(err.response.data.error, "Tente novamente", "error");
    }
  }

  async function SalvaFichaConsulta(e) {
    e.preventDefault();
    const data = {
      consultation_id,
      complaint,
      has,
      diabetes,
      heart_diseases,
      coagulation,
      hyperthyroidism,
      hypothyroidism,
      depression,
      smoking,
      other_complaint,
      history,
      family_history,
      antihypertensive,
      antidepressant,
      multivitamins,
      ginkobiloba,
      ass,
      aco,
      other_medicines,
      desc_medicines,
      no_surgery,
      aesthetic,
      other_surgery,
      desc_surgery,
      physical_examination,
      hypothesis,
      conduct,
    };
    try {
      SalvarExame();
      AtualizarConsulta();
      cadastraMedicamento();
      await api.post("doctor/consultation/formConsultation", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      swal("Erro ao finalizar consulta!", "error");
    }
    swal("Consulta realizada com sucesso!", "", "success");
    history2.push("/medico/listaconsultas");
  }

  useEffect(() => {
    api
      .get(`/doctor/viewConsultation/patient/${paciente_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDadosPacientes1(response.data);
        setDadosPacientes2(response.data.person);
        setDataNascimento(response.data.person.data_birth);
      })
      .catch((error) => console.log(error));
  }, [token, paciente_id]);

  const data1 = dataNascimento === null ? "" : dataNascimento.substring(0, 10);

  function dataBR(date) {
    return date.split("-").reverse().join("/");
  }

  async function SalvarExame() {
    //e.preventDefault();
    const nome = tituloExame;
    const obs = descricaoExame;

    const exams = [
      {
        nome,
        obs,
      },
    ];
    const data = {
      consultation_id,
      exams,
    };

    try {
      await api.post(`doctor/consultation/exams`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      swal("Erro ao finalizar consulta!", "error");
    }
    swal("Consulta realizada com sucesso!", "", "success");
    history2.push("/medico/listaconsultas");
  }

  return (
    <div id="ConsultaContainer">
      <Menu />
      <FiChevronDown
        className={`AbreProntuario ${abrirProntuario}`}
        onClick={() =>
          abrirProntuario === "ativo"
            ? setAbrirProntuario("")
            : setAbrirProntuario("ativo")
        }
      />
      <DadosCadastrais
        dadosPaciente1={dadosPaciente1}
        dadosPaciente2={dadosPaciente2}
        dataNascimento={dataNascimento}
        abrirDados={abrirDados}
        setAbrirDados={setAbrirDados}
      />
      <CadastrarMedicamento
        cadastrarMedicamento={cadastrarMedicamento}
        setCadastrarMedicamento={setCadastrarMedicamento}
        medications={medications}
        setMedications={setMedications}
      />
      {status === 0 ? <div className="IniciarConsulta"></div> : ""}

      <div id="ConsultasConteudo">
        <header className={`${abrirProntuario}`}>
          <span id="Fundo">
            <Link to={`/medico/${rota}`}>
              <BsArrowLeft id="Icone" />
            </Link>
          </span>
          <section>
            <div id="InfoConsulta">
              <span>
                {dadosPaciente2.name_first} ({dadosPaciente1.profession}) -{" "}
                {dadosPaciente1.medical_record}
              </span>

              <span>
                {dadosPaciente2.age} anos - ({dataBR(data1)})
              </span>

              <span>1º consulta</span>
              <button onClick={() => setAbrirDados(true)}>
                <FiUser id="Icone" /> Dados Cadastrais
              </button>
            </div>

            <div id="TempoConsulta">
              <div>
                <FiClock id="Icone" />
                <div id="Tempo">
                  <span>({dataAtual})</span>
                  <span>
                    {`${time.h > 9 ? time.h : "0" + time.h}`}:
                    {`${time.m > 9 ? time.m : "0" + time.m}`}:
                    {`${time.s > 9 ? time.s : "0" + time.s}`}
                  </span>
                </div>
              </div>
              {status === 0 ? (
                <button
                  onClick={(() => setStatus(1), start)}
                  style={{ background: "var(--cor-azul-claro" }}
                >
                  Iniciar Consulta
                </button>
              ) : (
                <button onClick={stop}>Encerrar Consulta</button>
              )}
            </div>
          </section>
        </header>

        <main>
          <form onSubmit={SalvaFichaConsulta}>
            <section className={`SecaoConsulta ${abrirConsulta}`} id="Consulta">
              <div
                className="Titulo"
                onClick={() =>
                  abrirConsulta === "ativo"
                    ? setAbrirConsulta("")
                    : setAbrirConsulta("ativo")
                }
              >
                <h2>Consulta</h2>
                <Ajuda
                  mensagem={
                    "Aqui está disposto todas as opções para anotações durante a consulta(ex. Queixa principal, Anamnese)."
                  }
                  width={"45rem"}
                  backColor={"var(--cor-cinza-claro)"}
                  fontColor={"var(--cor-azul)"}
                  margin={"0 0 0 1.5rem"}
                  border={"0.5rem"}
                  size={"1.2rem"}
                />
                <FiChevronDown className="Icone" />
              </div>
              <div className="Conteudo">
                <h3>Queixa Principal*</h3>
                <EditorTexto
                  titulo="Descrição da queixa:"
                  valor={complaint}
                  setValor={setComplaint}
                />

                <h3>Anamnese</h3>
                <h4>Antecedentes clínicos</h4>
                <div className="ContainerCheckboxs">
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="has"
                      name="has"
                      value="HAS"
                      onClick={() => setHas(!has)}
                    />
                    <label htmlFor="has">HAS</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="diabetes"
                      name="diabetes"
                      value="Diabetes"
                      onClick={() => setDiabetes(!diabetes)}
                    />
                    <label htmlFor="diabetes">Diabetes</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="cardiopatias"
                      name="cardiopatias"
                      value="Cardiopatias"
                      onClick={() => setHeart_diseases(!heart_diseases)}
                    />
                    <label htmlFor="cardiopatias">Cardiopatias</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="disturbios"
                      name="disturbios"
                      value="Distúrbios de coagulação"
                      onClick={() => setCoagulation(!coagulation)}
                    />
                    <label htmlFor="disturbios">Distúrbios de coagulação</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="hipertireodismo"
                      name="hipertireodismo"
                      value="Hipertireodismo"
                      onClick={() => setHyperthyroidism(!hyperthyroidism)}
                    />
                    <label htmlFor="hipertireodismo">Hipertireodismo</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="hipotireodismo"
                      name="hipotireodismo"
                      value="Hipotireodismo"
                      onClick={() => setHypothyroidism(!hypothyroidism)}
                    />
                    <label htmlFor="hipotireodismo">Hipotireodismo</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="depressao"
                      name="depressao"
                      value="Depressão"
                      onClick={() => setDepression(!depression)}
                    />
                    <label htmlFor="depressao">Depressão</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="tabagismo"
                      name="tabagismo"
                      value="Tabagismo"
                      onClick={() => setSmoking(!smoking)}
                    />
                    <label htmlFor="tabagismo">Tabagismo</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      name="outros"
                      id="outros"
                      value="Outros"
                      onClick={() => setOther_complaint(!other_complaint)}
                    />
                    <label htmlFor="outros">Outros</label>
                  </div>
                </div>

                <EditorTexto
                  titulo="Descrição de antecedentes:"
                  valor={history}
                  setValor={setHistory}
                />

                <h4>Antecedentes familiares</h4>
                <EditorTexto
                  valor={family_history}
                  setValor={setFamily_history}
                />

                <h4>Uso de medicamentos</h4>
                <div className="ContainerCheckboxs">
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="antihipertensivo"
                      name="anthipertensivo"
                      value="Anti hipertensivo"
                      onClick={() => setAntihypertensive(!antihypertensive)}
                    />
                    <label htmlFor="antipertensivo">Anti hipertensivo</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="antidepressivos"
                      name="antidepressivos"
                      value="Antidepressivos"
                      onClick={() => setAntidepressant(!antidepressant)}
                    />
                    <label htmlFor="antidepressivo">Antidepressivos</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="polivitaminicos"
                      name="polivitaminicos"
                      value="Polivitamínicos"
                      onClick={() => setMultivitamins(!multivitamins)}
                    />
                    <label htmlFor="polivitaminicos">Polivitamínicos</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="ginkobiloba"
                      name="ginkobiloba"
                      value="Ginkobiloba"
                      onClick={() => setGinkobiloba(!ginkobiloba)}
                    />
                    <label htmlFor="ginkobiloba">Ginkobiloba</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="aas"
                      name="aas"
                      value="AAS"
                      onClick={() => setAss(!ass)}
                    />
                    <label htmlFor="aas">AAS</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="aco"
                      name="aco"
                      value="ACO"
                      onClick={() => setAco(!aco)}
                    />
                    <label htmlFor="aco">ACO</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      name="outros"
                      id="outros"
                      value="Outros"
                      onClick={() => setOther_medicines(!other_medicines)}
                    />
                    <label htmlFor="outros">Outros</label>
                  </div>
                </div>
                <EditorTexto
                  titulo="Descrição de medicamentos:"
                  valor={desc_medicines}
                  setValor={setDesc_medicines}
                />

                <h4>Cirurgias prévias</h4>
                <div className="ContainerCheckboxs">
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="nenhuma"
                      name="nenhuma"
                      value="Nenhuma"
                      onClick={() => setNo_surgery(!no_surgery)}
                    />
                    <label htmlFor="nenhuma">Nenhuma</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      id="estetica"
                      name="estetica"
                      value="Estetica"
                      onClick={() => setAesthetic(!aesthetic)}
                    />
                    <label htmlFor="estetica">Estética</label>
                  </div>
                  <div className="ContainerCheckbox">
                    <input
                      type="checkbox"
                      name="outras"
                      id="outras"
                      value="Outras"
                      onClick={() => setOther_surgery(!other_surgery)}
                    />
                    <label htmlFor="outras">Outras</label>
                  </div>
                </div>
                <EditorTexto
                  titulo="Descrição de cirurgias:"
                  valor={desc_surgery}
                  setValor={setDesc_surgery}
                />

                <h4>Exame Físico</h4>
                <EditorTexto
                  valor={physical_examination}
                  setValor={setPhysical_examination}
                />

                <h4>Hipótese Diagnóstica</h4>
                <EditorTexto valor={hypothesis} setValor={setHypothesis} />

                <h4>Conduta</h4>
                <EditorTexto valor={conduct} setValor={setConduct} />
              </div>
            </section>

            <section className={`SecaoConsulta ${abrirReceita} flex`}>
              <div
                className="Titulo"
                onClick={() =>
                  abrirReceita === "ativo"
                    ? setAbrirReceita("")
                    : setAbrirReceita("ativo")
                }
              >
                <h2>Receita</h2>
                <Ajuda
                  mensagem={"Aqui está disposto o receituario da consulta."}
                  width={"22rem"}
                  backColor={"var(--cor-cinza-claro)"}
                  fontColor={"var(--cor-azul)"}
                  margin={"0 0 0 1.5rem"}
                  border={"0.5rem"}
                  size={"1.2rem"}
                />
                <FiChevronDown className="Icone" />
              </div>
              <div className="Conteudo">
                <div className="ConteudoFlex">
                  <div className="ConteudoDireito" id="medicamentos">
                    <span>
                      Medicamentos{" "}
                      <button
                        type="button"
                        onClick={() => setCadastrarMedicamento(true)}
                      >
                        <FiPlus className="Icone" />
                      </button>
                    </span>

                    <select name="medicamentos" id="medicamentos">
                      <option value="---">---</option>
                      {medications.map((medicamento) => (
                        <option value={medicamento.name}>
                          {medicamento.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </section>
            <section className={`SecaoConsulta ${abrirAtestado}`} id="Atestado">
              <div
                className="Titulo"
                onClick={() =>
                  abrirAtestado === "ativo"
                    ? setAbrirAtestado("")
                    : setAbrirAtestado("ativo")
                }
              >
                <h2>Atestado</h2>
                <Ajuda
                  mensagem={
                    "Aqui está disposto o atestado da consulta, onde você poderá fazer anotações e considerações."
                  }
                  width={"45rem"}
                  backColor={"var(--cor-cinza-claro)"}
                  fontColor={"var(--cor-azul)"}
                  margin={"0 0 0 1.5rem"}
                  border={"0.5rem"}
                  size={"1.2rem"}
                />
                <FiChevronDown className="Icone" />
              </div>
              <div className="Conteudo">
                <div className="ConteudoFlex">
                  <EditorTexto
                    titulo="Observações:"
                    valor={obsAtestado}
                    setValor={setObsAtestado}
                  />
                  <div className="ConteudoDireito">
                    <span>
                      Modelo <FiPlus className="Icone" />
                    </span>

                    <select name="medicamentos" id="medicamentos">
                      <option value="---">---</option>
                    </select>
                  </div>
                </div>

                <button
                  type="button"
                  className="BotaoEditor"
                  onClick={() => setObsAtestado("")}
                >
                  <FiTrash className="Icone" /> Limpa Atestado
                </button>
              </div>
            </section>

            <section className={`SecaoConsulta ${abrirExames}`} id="Exames">
              <div
                className="Titulo"
                onClick={() =>
                  abrirExames === "ativo"
                    ? setAbrirExames("")
                    : setAbrirExames("ativo")
                }
              >
                <h2>Exames</h2>
                <Ajuda
                  mensagem={"Aqui está disposto todas as opções de exames."}
                  width={"23rem"}
                  backColor={"var(--cor-cinza-claro)"}
                  fontColor={"var(--cor-azul)"}
                  margin={"0 0 0 1.5rem"}
                  border={"0.5rem"}
                  size={"1.2rem"}
                />
                <FiChevronDown className="Icone" />
              </div>
              <div className="Conteudo">
                <div className="ConteudoDireito">
                  <label htmlFor="tipoexame">Tipo de Exame:</label>
                  <input
                    type="text"
                    name="tipoexame"
                    id="tipoexame"
                    value={tituloExame}
                    onChange={(e) => setTituloExame(e.target.value)}
                    placeholder="Tipo de Exame"
                  />
                </div>
                <EditorTexto
                  titulo="Descrição:"
                  valor={descricaoExame}
                  setValor={setDescricaoExame}
                />
              </div>
            </section>
            <FinalizarConsulta
              modalFinalizar={modalFinalizar}
              setModalFinalizar={setModalFinalizar}
              resume={resume}
              duration={duration}
            />
          </form>
        </main>
      </div>
    </div>
  );
}

export default Consulta;
