import React, { useEffect, useState } from "react";

import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

import api from "../../../Servicos/api";

import "./estilos.css";

function ConsultasDiarias() {
  //Gráfico Consultas Diárias

  const [appointmentsList, setAppointmentsList] = useState([]);
  const [appointmentsByWeekdayList, setAppointmentsByWeekdayList] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    api
      .get(`listConsultations/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAppointmentsList(response.data);
      });
  }, [token]);

  useEffect(() => {
    if (appointmentsList && appointmentsList.length > 0) {
      const appointmentsDates = appointmentsList.map(appointment => appointment.start_time);

      const appointmentsByWeekday = appointmentsDates.reduce((accumulator, appointmentDate) => {
        const date = new Date(appointmentDate);
        const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
        
        if (!accumulator[weekday]) {
          accumulator[weekday] = 1;
        } else {
          accumulator[weekday]++;
        }

        return accumulator;
      }, {});

      const data = Object.entries(appointmentsByWeekday)
        .map(([weekday, appointmentCount]) => ({
          weekday,
          appointmentCount
      }));

      setAppointmentsByWeekdayList(data);
    }
  }, [appointmentsList]);

  const dataConsultas = [
    {
      name: "S",
      quantidade: appointmentsByWeekdayList.find(item => item.weekday === "Monday")?.appointmentCount || 0,
    },
    {
      name: "T",
      quantidade: appointmentsByWeekdayList.find(item => item.weekday === "Tuesday")?.appointmentCount || 0,
    },
    {
      name: "Q",
      quantidade: appointmentsByWeekdayList.find(item => item.weekday === "Wednesday")?.appointmentCount || 0,
    },
    {
      name: "Q",
      quantidade: appointmentsByWeekdayList.find(item => item.weekday === "Thursday")?.appointmentCount || 0,
    },
    {
      name: "S",
      quantidade: appointmentsByWeekdayList.find(item => item.weekday === "Friday")?.appointmentCount || 0,
    },
    {
      name: "S",
      quantidade: appointmentsByWeekdayList.find(item => item.weekday === "Saturday")?.appointmentCount || 0,
    },
    {
      name: "D",
      quantidade: appointmentsByWeekdayList.find(item => item.weekday === "Sunday")?.appointmentCount || 0,
    },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="legendaCustomizada">
          <p className="label">{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div id="TotalConsultasDiarias">
      <h2>Consultas Diárias Realizadas</h2>
      <ResponsiveContainer>
        <AreaChart
          data={dataConsultas}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey="quantidade"
            stroke="var(--cor-azul)"
            fill="#1070a1"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ConsultasDiarias;
