import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { ChallengesContext } from "../Servicos/Context/ChallengesContext";

function ContainerRota({ component: Component, ...rest }) {
  const { verificLogin } = useContext(ChallengesContext);
  const verifica = localStorage.getItem("verifica");

  return (
    <Route
      {...rest}
      render={(props) =>
        verificLogin || verifica ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

export default ContainerRota;
