/*
Data de criação: 18/08/2020;
Autor: Jeciane Amorim;
Descrição: Página de visualização do perfil, responsável por disponibilizar
ao usuário os dados do perfil e o gerenciamento dos dados do perfil e exclusão da sua conta.
*/

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiEdit3 } from "react-icons/fi";
import "./estilos.css";
import api from "../../../Servicos/api";
import axios from "axios";
import Menu from "../../../Componentes/GlobalMenu/indice";
import swal from "sweetalert";
import photo from "../../../Arquivos/avatarPadrao.svg";
export default function PerfilEmpregadoClinica() {
  const history = useHistory();

  const [edit, setEdit] = useState("");

  const token = localStorage.getItem("token");
  const idUserGet = localStorage.getItem("idUserGet");
  // const idUserPut = localStorage.getItem("idUserPut");

  const [name_first, setName_first] = useState("");
  const [name_last, setName_last] = useState();

  // const [escolaridade, setEscolaridade] = useState();
  const [cpf, setCpf] = useState("");
  const [data_birth, setData_birth] = useState();
  const [race, setRace] = useState();
  const [gender, setGender] = useState("");
  const [workload, setWorkload] = useState("");

  const [phone, setPhone] = useState();
  const [age, setAge] = useState();
  //const photo = "";

  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [number, setNumber] = useState();
  const [street, setStreet] = useState();
  const [housign_type, setHousign_type] = useState();
  const [cep, setCep] = useState();

  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);

  useEffect(() => {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((response) => {
        const estadoInitials = response.data.map((estado) => estado.sigla);

        setListaEstados(estadoInitials);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!state || state === "0") {
      return setListaCidades([]);
    }

    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
      )
      .then((response) => {
        const cidadeNome = response.data.map((cidade) => cidade.nome);

        setListaCidades(cidadeNome);
      })
      .catch((error) => console.log(error));
  }, [state]);

  useEffect(() => {
    api
      .get(`employee/${idUserGet}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const dados = response.data.person;
        setWorkload(response.data.workload);
        setName_first(dados.name_first);
        setName_last(dados.name_last);
        setCpf(dados.cpf);
        if (dados.data_birth !== null) {
          setData_birth(dados.data_birth.substring(0, 10));
        }
        setAge(dados.age);
        setRace(dados.race);
        setPhone(dados.phone);
        setGender(dados.gender);
        setState(dados.address.state);
        setCity(dados.address.city);
        setStreet(dados.address.street);
        setNumber(dados.address.number);
        setHousign_type(dados.address.housign_type);
        setCep(dados.address.cep);
      });
  }, [idUserGet, token]);

  async function Cancelar() {
    await api
      .get(`employee/${idUserGet}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const dados = response.data.person;
        setWorkload(response.data.workload);
        setName_first(dados.name_first);
        setName_last(dados.name_last);
        // setEscolaridade(dados.user.escolaridade);
        setCpf(dados.cpf);
        setData_birth(dados.data_birth.substring(0, 10));
        setAge(dados.age);
        setRace(dados.race);
        setPhone(dados.phone);
        setGender(dados.gender);
        setState(dados.address.state);
        setCity(dados.address.city);
        setStreet(dados.address.street);
        setNumber(dados.address.number);
        setHousign_type(dados.address.housign_type);
        setCep(dados.address.cep);
      });
  }
  async function AlterarPerfil(e) {
    e.preventDefault();
    try {
      const data = {
        race,
        data_birth,
        name_first,
        name_last,
        cpf,
        phone,
        gender,
        workload,
        address: {
          state,
          city,
          cep,
          number,
          street,
          housign_type,
        }
      };
      await api.put(`employee/${idUserGet}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      swal("Dados alterados com Sucesso", "", "success");
      history.goBack();
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  return (
    <div className="perfil-container-paciente">
      <Menu />

      <section className="conteudo-perfil">
        <div className="container-formulario">
          <div className="container-perfil">
            <img src={photo} alt="Foto de Perfil" />

            <div className="informacao">
              <h1 className="informacao-nome">{name_first}</h1>
              <h4 className="informacao-idade">{age} anos</h4>
              <div className="informacao-acao">
                <Link
                  to="#"
                  className={`informacao-acao-editar ${edit}`}
                  onClick={() => setEdit("editar")}
                >
                  <FiEdit3 className="icon" />
                  Alterar Perfil
                </Link>
              </div>
            </div>
          </div>
          <form onSubmit={AlterarPerfil}>
            <h2 className="titulo-formulario">Meus dados</h2>
            <div className="container-input">
              <div>
                <label htmlFor="nome">Nome</label>
                <input
                  id="nome"
                  value={name_first || ""}
                  disabled={edit === "editar" ? false : true}
                  onChange={(e) => setName_first(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="sobrenome">Sobrenome</label>
                <input
                  id="sobrenome"
                  value={name_last || ""}
                  disabled={edit === "editar" ? false : true}
                  onChange={(e) => setName_last(e.target.value)}
                />
              </div>
            </div>

            <div className="container-input">
              <div>
                <label htmlFor="cpf">CPF</label>
                <input
                  disabled={edit === "editar" ? false : true}
                  id="cpf"
                  value={
                    edit === "editar"
                      ? cpf
                      : `${cpf[0] + cpf[1] + cpf[2]}.***.***-**` || ""
                  }
                  onChange={(e) => setCpf(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="data_birth">Data de nascimento</label>
                <input
                  disabled={edit === "editar" ? false : true}
                  id="data_birth"
                  format="yyyy-MM-dd"
                  value={data_birth || ""}
                  onChange={(e) => setData_birth(e.target.value)}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="race">Cor/Raça</label>
                <select
                  placeholder="Etnia"
                  value={race || 0}
                  onChange={(e) => setRace(parseInt(e.target.value))}
                  disabled={edit === "editar" ? false : true}
                  required
                >
                  <option value="">-- Etnia --</option>
                  <option value="1">Amarela</option>
                  <option value="2">Branca</option>
                  <option value="3">Negra</option>
                  <option value="4">Pardo</option>
                </select>
              </div>
              <div>
                <label htmlFor="telefone">Telefone</label>
                <input
                  disabled={edit === "editar" ? false : true}
                  id="telefone"
                  value={phone || ""}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="container-input">
              <div>
                <label htmlFor="gender">Gênero</label>
                <select
                  placeholder="Gênero"
                  disabled={edit === "editar" ? false : true}
                  value={gender}
                  onChange={(e) => setGender(parseInt(e.target.value))}
                  required
                >
                  <option value="">-- Sexo --</option>
                  <option value="1">Masculino</option>
                  <option value="2">Feminino</option>
                  <option value="3">Outro</option>
                </select>
              </div>
              <div>
                <label htmlFor="telefone">Carga Horária de Trabalho</label>
                <input
                  disabled={edit === "editar" ? false : true}
                  id="workload"
                  value={workload || ""}
                  onChange={(e) => setWorkload(e.target.value)}
                  required
                />
              </div>
            </div>

            <h2 className="titulo-formulario">Endereço</h2>
            <div id="input-estado" className="container-input">
              <div>
                <label htmlFor="state">Estado</label>
                <select
                  id="state"
                  value={state || ""}
                  onChange={(e) => setState(e.target.value)}
                  disabled={edit === "editar" ? false : true}
                >
                  <option value={state || ""} style={{ textColor: "#6e757d" }}>
                    {state}
                  </option>
                  {listaEstados.map((uf) => (
                    <option key={uf} value={uf}>
                      {uf}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="city">Cidade</label>
                <select
                  id="city"
                  value={city || ""}
                  onChange={(e) => setCity(e.target.value)}
                  disabled={edit === "editar" ? false : true}
                >
                  <option value={city} style={{ textColor: "#6e757d" }}>
                    {city}
                  </option>
                  {listaCidades.map((listaCidade) => (
                    <option key={listaCidade} value={listaCidade}>
                      {listaCidade}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div id="input-numero" className="container-input">
              <div>
                <label htmlFor="street">Rua</label>
                <input
                  id="street"
                  value={street || ""}
                  disabled={edit === "editar" ? false : true}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="number">Número</label>
                <input
                  id="number"
                  value={number || 0}
                  disabled={edit === "editar" ? false : true}
                  onChange={(e) => setNumber(parseInt(e.target.value))}
                />
              </div>
            </div>
            <div className="container-input">
              <div>
                <label htmlFor="housign_type">Tipo da Residência</label>
                <select
                  value={housign_type || ""}
                  onChange={(e) => setHousign_type(e.target.value)}
                  disabled={edit === "editar" ? false : true}
                >
                  <option value="">-- Complemento --</option>
                  <option value="Casa">Casa</option>
                </select>
              </div>

              <div>
                <label htmlFor="cep">CEP</label>
                <input
                  id="cep"
                  value={cep || 0}
                  disabled={edit === "editar" ? false : true}
                  onChange={(e) => setCep(parseInt(e.target.value))}
                />
              </div>
            </div>
            <div className={`container-botao ${edit}`}>
              <button
                className="botao-grande"
                onClick={() => {
                  setEdit("");
                  Cancelar();
                }}
                type="reset"
                formNoValidate
              >
                Cancelar
              </button>
              <button className="botao-grande" type="submit">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
