/*
Data de criação: 12/11/2020;
Autor: Jeciane Amorim;
Descrição: Agenda de Medico;
*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiBell, FiChevronsRight } from "react-icons/fi";
import "react-calendar/dist/Calendar.css";

//Componentes
import Menu from "../../../Componentes/GlobalMenu/indice";
import CalendarioMedico from "../../../Componentes/Medico/Calendario/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";

import api from "../../../Servicos/api";

import "./estilos.css";

function AgendaMedico() {
  const name_first = localStorage.getItem("name_first");

  const id = localStorage.getItem("idMedico");
  const token = localStorage.getItem("token");
  const [listaConsulta, setListaConsulta] = useState([]);

  const foto = localStorage.getItem("photo");

  //Puxar consulta de medico
  useEffect(() => {
    api
      .get(`/doctor/consultation/${id}/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListaConsulta(response.data);
      });
  }, [token, id]);

  return (
    <div className="PainelAgenda">
      <Menu />

      <div className="ConteudoAgenda">
        <header>
          <h1 id="tituloClinica">Bem-vindo, {name_first}.</h1>
          <div id="Perfil">
            <Link to="/medico">
              <FiBell id="Notificacao" />
            </Link>
            <div id="FotoPerfil">
              <img src={foto} alt="Foto da Clínica" />
            </div>
          </div>
        </header>

        <main>
          <div id="TituloAgenda">
            <span>Agenda</span>
            <FiChevronsRight id="Icone" />
            <span>Agenda Médica</span>
            <Ajuda
              mensagem={
                "Nesta tela é possivel ver sua agenda com suas consultas."
              }
              width={"35rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>

          <section className="CalendarContainer">
            <CalendarioMedico
              listaConsulta={listaConsulta}
            />
          </section>
        </main>
      </div>
    </div>
  );
}

export default AgendaMedico;
