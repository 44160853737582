import React, { useState, useRef, useEffect } from "react";
import moment from "moment";

import styles from "./Chat.module.css";
import { BiSend } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { BsFilePlus } from "react-icons/bs";

import api from "../../../Servicos/api";
import { io } from "socket.io-client";
/*const socket = io("http://195.110.59.116:5055", {
    "force new connection": true,
    autoconnect: true,
    reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket"],
});*/
const socket = io("https://thehigia.com/api/clinica");
//const socket = io("https://thehigia.com/");
//const socket = io("https://api.thehigia.com/");
//const socket = io("http://localhost:3333/");

export default function Chat({ chatModal, setChatModal, receiverInformation }) {
    
    const token = localStorage.getItem("token");
    const userId = Number(localStorage.getItem("id"));
    const userName = localStorage.getItem("name_first");
    const access = localStorage.getItem("profile");

    const [openSettings, setOpenSettings] = useState(false);
    const timestamp = new Date().getTime();
    const friendlyTimestamp = moment(timestamp).format("LLLL");

    const { receiverId, receiverName, receiverPhoto } = receiverInformation;

    const [roomId, setRoomId] = useState(null);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    
    const inputFile = useRef(null);
    const fileUploadInputOpen = () => {
        inputFile.current.click();
    };
    
    const [data,] = useState ({
        chat_id: "",
        user_id: "",
        text: "",
        file_url: "",
        file_id: "",
    })

    useEffect(() => {
        if (userId && receiverId) {
            api
                .get(`/chat/${userId}/${receiverId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    setRoomId(response.data.room);
                });
        }

        if (roomId !== null) {
            socket.emit("select_room", {
                room: roomId,
            });
        }

    }, [data, userId, receiverId, token, roomId]);

    useEffect(() => {
        if (chatModal && roomId) {
            socket.emit("call_message", { room: roomId }, (messageList) => {
                setMessages(messageList);
            });

            api
                .get(`/chat/${roomId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    setMessages(response.data.message);
                });
        }
    }, [chatModal, roomId, token]);

    useEffect(() => {
        //Escutando a mensagem do socket e adicionando ela à lista de mensagens
        socket.on("message", (newMessage) => {
            setMessages(messagesHistory => {
                return [...messagesHistory, newMessage];
            });
        });
    }, []);

    //Send Messages
    async function sendMessage() {
        if (message) {

            data.chat_id = roomId;
            data.user_id = userId;
            data.text = message;
            data.file_url = null;
            data.file_id = null;

            socket.emit("message", data); //Emitindo uma mensagem a ser recebida pelo socket no back

            //const updatedMessages = messages.concat(data);
            //setMessages(updatedMessages);
        }
        setMessage("");
    }


    const messagesEndRef = useRef(null);
    useEffect(() => {
        if (messagesEndRef.current !== null) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);


    if (chatModal === true) {
        return (
            <div className={styles.overlay}>
                <div className={styles.contentWrapper}>
                    <div className={styles.chatWrapper}>
                        <header>
                            <div className={styles.profileWrapper}>
                                <div className={styles.profile}>
                                    <img src={receiverPhoto} alt="" />
                                    <div className={styles.personName}>
                                        <p>{receiverName}</p>
                                        {access === "MHME" ? (<p>Paciente</p>) : (<p>Médico</p>)}
                                    </div>
                                </div>
                                <button
                                    onClick={() => {
                                        setChatModal(false);
                                        setMessages([]);
                                        setMessage("");
                                        setRoomId(null);
                                    }}
                                >x</button>
                            </div>
                        </header>
                        <div className={styles.chatContainer}>
                            {access === "MHME" ? (
                                <>
                                    <p>Bem vindo(a) ao atendimento Dr(a). {userName}</p>
                                    <p>
                                        Guie o seu paciente durante o atendimento ou solicite o envio
                                        de arquivos de acordo com a necessidade.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p>Bem vindo(a) ao atendimento. Como podemos te ajudar hoje?</p>
                                    <p>
                                        Descreva a sua situação abaixo ou envie arquivos e siga de
                                        acordo com as instruções do Médico.
                                    </p>
                                </>
                            )}
                            <span>
                                <div className={styles.timestamp}>{friendlyTimestamp}</div>
                            </span>
                            <div className={styles.messages}>
                                {messages ? (
                                    messages.map(message => (
                                        <p key={message.id} 
                                        className={message.user_id !== userId ? styles.sender : styles.receiver}>
                                            {message.text}
                                        </p>
                                    ))
                                ) : ''}
                                <div ref={messagesEndRef}></div>
                            </div>
                        </div>
                    </div>
                    <footer>
                        <div className={styles.leftButtons}>
                            {access === "MHME" ? (
                                <button onClick={() => setOpenSettings(true)}>
                                    <FiSettings className={styles.footerIcon} />
                                </button>
                            ) : ('')}
                            <input type="file" name="file" id="file" ref={inputFile} />
                            <button onClick={fileUploadInputOpen}>
                                <BsFilePlus className={styles.footerIcon} />
                            </button>
                        </div>
                        <input type="text"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' ? sendMessage(e) : null}
                        />
                        <button className={styles.sendBtn}
                            onClick={() => sendMessage(message)}
                        >
                            <BiSend className={styles.footerIcon} />
                        </button>
                        {openSettings ? (
                            <div className={styles.settingsOverlay}>
                                <div className={styles.settingsWrapper}>
                                    <header>
                                        <h3>Configurações do chat</h3>
                                        <button onClick={() => setOpenSettings(false)}>x</button>
                                    </header>
                                    <div className={styles.inputLabelWrapper}>
                                        <label htmlFor="permitFiles">Upload de arquivos</label>
                                        <label className={styles.switch}>
                                            <input type="checkbox" name="files" id="permitFiles" />
                                            <span
                                                className={`${styles.slider} ${styles.round}`}
                                            ></span>
                                        </label>
                                    </div>
                                    <div className={styles.inputLabelWrapper}>
                                        <label htmlFor="messagesLimit">Limite de mensagens</label>
                                        <input
                                            type="number"
                                            name="messages"
                                            id="messagesLimit"
                                            max="40"
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </footer>
                </div>
            </div>
        );
    } else return null;
}
