/*
Data de criação: 02/09/2020;
Autor: Caio Rafael;
Descrição: Página para redefinir as senhas da clinica, responsável por 
enciar um email para que o admin possa redefinir a senha da clinica;
*/

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import api from "../../../Servicos/api";
import Logo from "../../../Arquivos/higia.svg";
import swal from "sweetalert";

import "./estilos.css";

export default function RedefinirSenhaClinica() {
  const [email, setEmail] = useState("");
  const [key, setKey] = useState("");
  const history = useHistory();
  const [control, setControl] = useState(true);

  async function EnviarEmail(e) {
    e.preventDefault();

    const data = {
      email,
    };
    try {
      const response = await api.post("forgotPassword", data);
      swal(response.data.message, "", "success");
      setControl(false);
      //history.push("/clinica/alterarsenha");
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  async function EnviarToken(e) {
    e.preventDefault();

    const data = {
      email,
      key,
    };
    try {
      const response = await api.post("validatePasswordToken", data);

      localStorage.setItem("email_senha", email);
      localStorage.setItem("token_recSenha", key);

      swal(response.data.message, "", "success");
      history.push("/alterarsenha");
    } catch (err) {
      swal(err.response.data.error, "Digite novamente", "error");
    }
  }

  return (
    <div className="ContainerRedefinir">
      <div className="ContainerRedefinirSenha">
        <Link to="/" className="VoltarPagina">
          <FiArrowLeft size={25} className="IconeVoltar" /> Voltar
        </Link>
        <img src={Logo} alt="Logo MobileHealth" className="logo" />
        <h1>Redefinição de senha</h1>

        {control ? (
          <form onSubmit={EnviarEmail}>
            <p>
              Insira o <span>e-mail</span> que você usou no cadastro.<br></br>{" "}
              Nós mandaremos um e-mail com um link para você redefinir sua
              senha.
            </p>
            <input
              type="text"
              placeholder="exemplo@gmail.com"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="botao-grande">
              Enviar
            </button>
          </form>
        ) : (
          <form onSubmit={EnviarToken}>
            <p>
              Insira o <span>código</span> que você recebeu no e-mail.<br></br>{" "}
            </p>
            <input
              type="text"
              placeholder="Código"
              value={key}
              required
              onChange={(e) => setKey(e.target.value)}
            />
            <button type="submit" className="botao-grande">
              Enviar
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
