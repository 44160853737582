import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiClock, FiCheck, FiXCircle, FiEdit } from "react-icons/fi";

import Menu from "../../../Componentes/GlobalMenu/indice";
import Paginacao from "../../../Componentes/Paginacao/indice";
import Ajuda from "../../../Componentes/Geral/Ajuda/Ajuda";
import api from "../../../Servicos/api";

import "./estilos.css";

function Consultas() {
  const token = localStorage.getItem("token");
  const [, setConsulta] = useState({});
  const [listaConsultas, setListaConsultas] = useState([]);
  const [listaConsultasFiltradas, setListaConsultasFiltradas] = useState([]);
  const [pacientesLista, setPacientesLista] = useState([]);
  const [medicoLista, setMedicoLista] = useState([]);
  const [, setAtivo] = useState("");

  const [mostrarCanceladas, setMostrarCanceladas] = useState(false);
  const [mostrarAgendadas, setMostrarAgendadas] = useState(true);
  const [mostrarFinalizadas, setMostrarFinalizadas] = useState(false);
  const foto = localStorage.getItem("photo");

  const [pesquisaMedicoNome, setPesquisaMedicoNome] = useState("");
  const [pesquisaPacienteNome, setPesquisaPacienteNome] = useState("");

  const [option, setOption] = useState(1);
  const [/*nextReq,*/ setNextReq] = useState();
  const limitPage = 7;
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();

  useEffect(() => {
    api
      .get(
        `listConsultations/?page=${page}&limit=${limitPage}&option=${option}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setListaConsultas(response.data.data);
        setListaConsultasFiltradas(response.data.data);
        setTotal(response.data.total);
      });
  }, [token, option, page]);

  useEffect(() => {
    api
    .get(`/searchDoctors/?pagination=false`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      setMedicoLista(response.data);
    });
  }, [token]);
  
  useEffect(() => {
    const resultados = listaConsultas.filter((consulta) =>
    consulta.doctor.person.name_first
    .toLowerCase()
    .includes(pesquisaMedicoNome.toLowerCase())
    );
    setListaConsultasFiltradas(resultados);
  }, [pesquisaMedicoNome, listaConsultas]);
  
  useEffect(() => {
    api
      .get(`/searchPatients/?pagination=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPacientesLista(response.data);
      });
  }, [token]);

  useEffect(() => {
    const resultados = listaConsultas.filter((consulta) =>
      consulta.patient.person.name_first
        .toLowerCase()
        .includes(pesquisaPacienteNome.toLowerCase())
    );
    setListaConsultasFiltradas(resultados);
  }, [pesquisaPacienteNome, listaConsultas]);

  const titulos = [
    { nome: "Médico" },
    { nome: "Paciente" },
    { nome: "Tipo da consulta" },
    { nome: "Data" },
    { nome: "Horário" },
    { nome: "Opções" },
  ];

  function getNameDoctor(id) {
    var nomeMedico = "";
    medicoLista.map((medico) =>
      medico.person.doctor.id === id
        ? (nomeMedico = medico.person.name_first)
        : ""
    );
    return nomeMedico;
  }

  /*function limparConsulta() {
    setConsulta({});
  }*/

  function openModal(estado) {
    setAtivo(estado);
  }

  function DataBR(date) {
    return date.slice(0, 10).split("-").reverse().join("/");
  }

  function getNamePatient(id) {
    var nomePaciente = "";
    pacientesLista.map((paciente) =>
      paciente.person.patient.id === id
        ? (nomePaciente = paciente.person.name_first)
        : ""
    );
    return nomePaciente;
  }

  function exibirAgendados() {
    setMostrarAgendadas(true);
    setMostrarFinalizadas(false);
    setMostrarCanceladas(false);
    setPage(0);
    setOption(1);
  }

  function exibirFinalizadas() {
    setMostrarFinalizadas(true);
    setMostrarAgendadas(false);
    setMostrarCanceladas(false);
    setPage(0);
    setOption(2);
  }

  function exibirCanceladas() {
    setMostrarCanceladas(true);
    setMostrarAgendadas(false);
    setMostrarFinalizadas(false);
    setPage(0);
    setOption(3);
  }

  return (
    <div className="ContainerConsultas">
      <Menu />
      <div className="ContainerConteudo">
        <header>
          <div id="div-ajuda">
            <h1>Consultas Gerais</h1>
            <Ajuda
              mensagem={
                "Nesta tela é possível visualizar todas as suas consultas agendadas, canceladas e finalizadas, permitindo buscar por nome do médico, nome do paciente ou data."
              }
              width={"40rem"}
              backColor={"var(--cor-branco)"}
              fontColor={"var(--cor-azul)"}
              margin={"0 0 0 1.5rem"}
              border={"0.5rem"}
              size={"2.5rem"}
            />
          </div>

          <div className="Perfil">
            <div className="FotoPerfil">
              <img src={foto} alt="Foto de  Perfil" />
            </div>
          </div>
        </header>

        <section>
          <div className="Pesquisa">
            <div className="pesquisaNome">
              <input
                value={pesquisaMedicoNome}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por nome do Médico"
                onChange={(e) => setPesquisaMedicoNome(e.target.value)}
              />
              <label className="labelPesquisa">Médico</label>
            </div>
            <div className="pesquisaNome">
              <input
                value={pesquisaPacienteNome}
                type="text"
                className="inputPesquisa"
                placeholder="Buscar por nome do Paciente"
                onChange={(e) => setPesquisaPacienteNome(e.target.value)}
              />
              <label className="labelPesquisa">Paciente</label>
            </div>
          </div>

          <div className="Tabela">
            <div>
              <button onClick={exibirAgendados} className="botaoAgendados">
                <span>Agendadas</span> <FiClock />
              </button>
              <button onClick={exibirFinalizadas} className="botaoFinalizados">
                <span>Finalizadas</span> <FiCheck />
              </button>
              <button onClick={exibirCanceladas} className="botaoCancelados">
                <span>Canceladas</span> <FiXCircle />
              </button>
            </div>
            <div className="tabelaConsultas">
              <table
                style={
                  mostrarAgendadas
                    ? { display: "inline-table" }
                    : { display: "none" }
                }
              >
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaConsultasFiltradas.length === 0 ? (
                    <tr>
                      <td>Não há consultas agendadas.</td>
                    </tr>
                  ) : (
                    listaConsultasFiltradas.reverse().map((info) => (
                      <tr key={info.id}>
                        <td>{getNameDoctor(info.doctor_id)}</td>
                        <td>{getNamePatient(info.patient_id)}</td>
                        <td>
                          {info.type === 1
                            ? "Consulta - 1 vez"
                            : info.type === 2
                              ? "Consulta - Rotina"
                              : info.type === 3
                                ? "Consulta - Retorno"
                                : "Exames"}
                        </td>
                        <td>{DataBR(info.start_time)}</td>
                        <td>{info.start_time.slice(11, 16)}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => {
                              openModal("ativo");
                              setConsulta(info);
                            }}
                          >
                            <FiEdit />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <table
                style={
                  mostrarFinalizadas
                    ? { display: "inline-table" }
                    : { display: "none" }
                }
              >
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaConsultasFiltradas.length === 0 ? (
                    <tr>
                      <td>Não há consultas finalizadas.</td>
                    </tr>
                  ) : (
                    listaConsultasFiltradas.reverse().map((info) => (
                      <tr>
                        <td>{getNameDoctor(info.doctor_id)}</td>
                        <td>{getNamePatient(info.patient_id)}</td>
                        <td>
                          {info.type === 1
                            ? "Consulta - 1 vez"
                            : info.type === 2
                              ? "Consulta - Rotina"
                              : info.type === 3
                                ? "Consulta - Retorno"
                                : "Exames"}
                        </td>
                        <td>{DataBR(info.start_time)}</td>
                        <td>{info.start_time}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => {
                              openModal("ativo");
                              setConsulta(info);
                            }}
                          >
                            <FiEdit />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <table
                style={
                  mostrarCanceladas
                    ? { display: "inline-table" }
                    : { display: "none" }
                }
              >
                <thead>
                  <tr>
                    {titulos.map((titulo) => (
                      <th key={titulo.nome}>{titulo.nome}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listaConsultasFiltradas.length === 0 ? (
                    <tr>
                      <td>Não há consultas canceladas.</td>
                    </tr>
                  ) : (
                    listaConsultasFiltradas.reverse().map((info) => (
                      <tr key={info.id}>
                        <td>{getNameDoctor(info.doctor_id)}</td>
                        <td>{getNamePatient(info.patient_id)}</td>
                        <td>
                          {info.type === 1
                            ? "Consulta - 1 vez"
                            : info.type === 2
                              ? "Consulta - Rotina"
                              : info.type === 3
                                ? "Consulta - Retorno"
                                : "Exames"}
                        </td>
                        <td>{DataBR(info.start_time)}</td>
                        <td>{info.start_time}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => {
                              openModal("ativo");
                              setConsulta(info);
                            }}
                          >
                            <FiEdit />
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              {mostrarAgendadas ? (
                <Paginacao
                  consultasPorPagina={limitPage}
                  totalConsulta={total}
                  setNextReq={setNextReq}
                  setPage={setPage}
                />
              ) : mostrarFinalizadas ? (
                <Paginacao
                  consultasPorPagina={limitPage}
                  totalConsulta={total}
                  setNextReq={setNextReq}
                  setPage={setPage}
                />
              ) : setMostrarCanceladas ? (
                <Paginacao
                  consultasPorPagina={limitPage}
                  totalConsulta={total}
                  setNextReq={setNextReq}
                  setPage={setPage}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Consultas;
